/* global G */
import { curry } from 'lib/util'
import { activate } from 'app/_shared/events/cart'

/**
 * Sets the currently viewed cart as the active one by calling `G.CART, G.ACTIVE` with it.
 *
 * @param {Function} callback                 callback to execute
 * @param {Gaia.AppModule.Spec} module        module composition
 * @param {Gaia.Component.Spec} component     action component
 * @param {Gaia.AppModule.EventHandler} event triggered event
 * @returns {Promise<void>}
 * @private
 */
const _activate = async (callback, module, component, event) => {
  const model = module[G.MODEL]
  const newCart = model[G.CACHE]

  const data = { newCart }

  await activate(module, component, data, callback, event)
}

export default curry(_activate)

/* eslint-disable no-unused-vars */
import PersistenceError from 'platform/adapter/persistence/error'

/**
 * @memberOf Gaia.Adapter.Persistence.PersistenceAPI
 * @typedef {PersistenceAPI} DisabledAPI
 * @return {DisabledAPI}
 */
export default (() => Object.create({}, {
  init: {
    /**
     * Does nothing.
     */
    value: () => {},
    iterable: true,
    enumerable: true,
  },
  get: {
    /**
     * Attempts to obtain a persisted {@link Draft} from the database.
     *
     * @param {string} id the identifier of the {@link Draft} to obtain
     * @return {Promise<Draft>} the obtained {@link Draft}
     */
    value: async (id) => {
      throw new PersistenceError('Persistence is disabled')
    },
    iterable: true,
    enumerable: true,
  },
  getAll: {
    /**
     * Attempts to obtain the list of persisted {@link Draft} entities that fulfills `condition`
     * from the database.
     *
     * @param {Object} condition an object defining a property with a value both of which the
     *                           returned items must have (e.g. `{ type: 'person' }`)
     * @return {Promise<Draft>} the obtained {@link Draft}
     */
    value: async (condition) => {
      throw new PersistenceError('Persistence is disabled')
    },
    iterable: true,
    enumerable: true,
  },
  put: {
    /**
     * Attempts to persist `item` in the database, overwriting any records identified by the same
     * key in the process.
     *
     * @param {Draft} item  the item to persist
     * @return {Promise<any>} the identifier of the new persisted item
     */
    value: async (item) => {
      throw new PersistenceError('Persistence is disabled')
    },
    iterable: true,
    enumerable: true,
  },
  delete: {
    /**
     * Attempts to delete a persisted {@link Draft} by its `key`.
     *
     * @param {string} id  the identifier of the {@link Draft} to delete
     * @return {Promise<any>}
     */
    value: async (id) => {
      throw new PersistenceError('Persistence is disabled')
    },
    iterable: true,
    enumerable: true,
  },
  deleteAll: {
    /**
     * Attempts to delete all persisted {@link Draft} objects.
     */
    value: async () => {
      throw new PersistenceError('Persistence is disabled')
    },
    iterable: true,
    enumerable: true,
  },
}))()

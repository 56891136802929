/* eslint-disable no-unused-expressions,object-curly-newline,no-unused-vars,no-nested-ternary */
/* global G */
import { asyncPipeSpread } from 'lib/util'
import { hide, show } from 'lib/sequence/component/state/hidden'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { checked } from 'lib/sequence/component/state/checked'
import { disable, enable } from 'lib/sequence/component/state/disabled'
import validate from 'lib/sequence/model/validate'
import displayDeleteDescription from 'app/admin/action/approve/partials/displayDeleteDescription'
import presetOrganisationName from 'app/admin/action/approve/partials/presetOrganisationName'
import { displayVerifyButtonForAuxiliary } from 'app/admin/action/approve/partials/displayVerifyButton'

/**
 * Displays the appropriate explanation field for the block dialog.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayExplanationField = module => async (children, ...args) => {
  const { approveBlockExplanation } = children

  approveBlockExplanation && show(approveBlockExplanation)

  return [children, ...args]
}

/**
 * Displays the ticket section for the block dialog.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayTicketNoteSection = module => async (children, ...args) => {
  const { roleSection, ticketNoteSection } = children

  roleSection && hide(roleSection)
  ticketNoteSection && show(ticketNoteSection)

  return [children, ...args]
}

/**
 * Displays the ticket note input field if the checkbox is checked.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayTicketNoteField = module => async (children, ...args) => {
  const { note: ticketNote, confirmTicketNote } = children

  checked(confirmTicketNote)
    ? show(ticketNote)
    : hide(ticketNote)

  return [children, ...args]
}

/**
 * Instantly validates the ticket note input field if it's visible.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const validateTicketNote = module => async (children, ...args) => {
  const model = module[G.MODEL]
  const { ticket } = model[G.CHILDREN]
  const { confirmTicketNote, ticketNoteSection } = children

  const { note: noteValidator } = ticket[G.VALIDATOR]

  if (checked(confirmTicketNote)) {
    enable(noteValidator)
    await validate(ticket)(ticketNoteSection)
  } else {
    disable(noteValidator)
    ticket[G.STATE][G.ERROR] = null
  }

  return [children, ...args]
}

/**
 * Organisation Action Check Block
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  displayExplanationField(module),
  displayTicketNoteSection(module),
  displayTicketNoteField(module),
  displayDeleteDescription(module),
  presetOrganisationName(module),
  validateTicketNote(module),
  displayVerifyButtonForAuxiliary(module),
)(sequenceComponentFind(component), ...args)

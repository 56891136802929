import { Box, Menu, MenuItem, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import SvgIcon from 'ui/Element/Icon/Svg'
import { useState } from 'react'
import { capitalizeFirstLetter } from 'lib/util/string'

/**
 * Cell styles
 */
const styles = () => ({
  actions: {
    gap: '0.75rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    alignItems: 'center',
  },
})

/**
 * Helper function to create the entries for the action menu.
 *
 * It will loop through the given {@param labels} for the cell and render
 * a {@link MenuItem} for each label that has a {@code type} that is also
 * present in {@param events}. Like
 *
 * @example
 * {type: update, label: '...'} // needs {@code onUpdate} in {@code events}
 *
 * @param {Object[]} labels labels for the action menu
 * @param {Object} events   events for the action menu
 * @param {string} id       id of the notification
 * @param {boolean} read    whether the message id read
 * @returns {*}
 * @private
 */
const _createActions = (labels, events, id, read = false) => labels.map((action, i) => {
  const eventName = `on${capitalizeFirstLetter(action.type)}`
  const handlerFn = events?.[eventName] || null

  const handler = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    events?.onClose(event)
    await handlerFn?.({ detail: { id } })
  }

  const targetAction = action.type === 'update' && read
    ? labels.find(x => x.type === 'updateReverse')

    : action
  return !handlerFn ? null : (
    <MenuItem key={i} onClick={handler}>{targetAction.label}</MenuItem>
  )
})

/**
 * Action menu for the given alert notification
 *
 * @param {string} id                     id of the notification
 * @param {React.ReactNode|null} anchorEl anchor element
 * @param {Object[]} labels               labels for the action menu
 * @param {Object} events                 events for the action menu
 * @returns {JSX.Element}
 * @constructor
 */
const ActionMenu = ({ id, read, anchorEl, labels, events }) => (
  <Menu
    keepMounted
    open={Boolean(anchorEl)}
    id={'action-menu'}
    anchorEl={anchorEl}
    onClose={events?.onClose}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'center',
      horizontal: 'right',
    }}
  >
    {_createActions(labels, events, id, read)}
  </Menu>
)

/**
 * Displays actions for the given alert notification
 *
 * @param {Object} classes  styles for the component
 * @param {Object} props    props for the component
 * @return {JSX.Element}
 * @constructor
 */
const ListItemCellNotificationActions = (classes, props = {}) => {
  const theme = useTheme()
  const { id, rowHovered, value: { read }, labels, events } = props

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleToggle = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(prevState => (prevState ? null : event.currentTarget))
  }

  return (
    <Box className={classes.actions}>
      {rowHovered && (
        <Box
          onClick={handleToggle}
        >
          <SvgIcon
            iconPadding={'0.375rem'}
            color={theme.palette.black.main}
            background={theme.palette.white.main}
            rounded={true}
            icon={'more'}
            variant={'filled'}
          />
          <ActionMenu
            id={id}
            read={read}
            labels={labels}
            anchorEl={anchorEl}
            events={{
              onClose: handleClose,
              onUpdate: events?.onUpdate,
              onDelete: events?.onDelete,
            }}
          />
        </Box>
      )}
      <Box sx={{ opacity: !read.length ? 1 : 0 }}>
        <SvgIcon
          width={'0.5rem'}
          height={'0.5rem'}
          color={theme.palette.info.main}
          icon={'dot'}
          variant={'filled'}
        />
      </Box>
    </Box>
  )
}

export default CellHOC(ListItemCellNotificationActions, styles)

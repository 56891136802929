/* global G */
/* eslint-disable object-curly-newline,no-unused-vars */
import { curry } from 'lib/util'
import remove from 'app/_shared/events/favorite/toggle'
import { chain } from 'app/_shared/events/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import updateList from 'app/favorite/event/updateList'
import { openModalWith } from 'app/_shared/events/modal/open'
import { closeModalWith } from 'app/_shared/events/modal/close'
import { submitModalWith } from 'app/_shared/events/modal/submit'
import { persistChange, persistScroll } from 'app/_shared/events'
import persistTab from 'app/_shared/events/tabs/persist'
import remember from 'app/_shared/events/remember'
import redirectToDetailOrAttachment from 'app/favorite/event/redirectToDetailOrAttachment'
import { setLabel } from 'app/_shared/events/tabs/setCountedLabel'
import countFavorites from 'app/favorite/event/countFavorites'
import searchAndRecreate from 'app/_shared/events/appbar/searchAndRecreate'
import hasMultipleIdsAndType from 'app/_shared/events/search/query/hasMultipleIdsAndType'
import getFavoritesWithFilter from 'app/favorite/event/getFavoritesWithFilter'

export default {
  onOpen: {
    getFavoritesWithFilter,
  },
  onChange: {
    persistChange,
    persistTabAndRemember: chain(
      persistTab,
      remember,
    ),
  },
  onClick: {
    redirectToDetailOrAttachment,
    favorite: chain(
      updateList,
      submitModalWith('submitFavorite'),
    ),
  },
  onFavoriteClick: {
    removeFavorite: remove,
  },
  onDescriptionClick: {
    editFavorite: chain(
      curry((module, component, event) => {
        const { options: { description: desc = '' } = {} } = event?.detail || event || {}

        const { favoriteModal } = sequenceComponentFindProxy(module[G.STATE][G.ACTION][G.COMPONENT])
        const { description } = sequenceComponentFindProxy(favoriteModal)
        description[G.STATE].value = desc
      }),
      openModalWith('favoriteModal'),
    ),
  },
  onClose: {
    resetFavorite: closeModalWith('favoriteModal'),
  },
  onSearch: {
    searchAndRecreate,
  },
  onScroll: {
    persistScrollAndRemember: chain(
      persistScroll,
      remember,
    ),
  },
  getLabel: {
    setEquipmentLabel: setLabel(countFavorites('equipment'), { ns: 'equipmentInformation', key: 'equipment' }),
    setPartLabel: setLabel(countFavorites('article'), { ns: 'equipmentInformation', key: 'parts' }),
    setDocumentationLabel: setLabel(countFavorites('documentation'), { ns: 'equipmentInformation', key: 'documentation' }),
  },
  query: {
    hasMultipleIdsAndType,
  },
  filter: {
    equipment: curry((module, component, event) => ({ type: 'equipment' })),
    part: curry((module, component, event) => ({ type: 'article' })),
    documentation: curry((module, component, event) => ({ type: 'documentation' })),
  },
}

/* global G */

import sequenceModuleAdapterSessionStateNavigationReset
  from 'lib/sequence/module/adapter/session/navigation'
import sequenceResetAcl from 'lib/sequence/app/acl/reset'
import routeComposition from 'trait/composition/route'
import settings from '@tenant/settings'

/**
 * Logs the user out when the ok button of the unauthorized dialog is pressed.
 *
 * @param {Gaia.Web.Application} obj  the Web platform Application
 * @returns {(function(): Promise<void>)|*}
 */
const logoutHandler = obj => async () => {
  const { moduleAction } = obj[G.ADAPTER][G.ROUTER][G.API]
  const sessionState = obj[G.ADAPTER][G.SESSION][G.STATE]
  const module = obj[G.SESSION][G.STATE][G.MODULE]
  // invalidate cookie
  sessionState[G.META] = null
  // reset nav stack
  sequenceModuleAdapterSessionStateNavigationReset(module, [])
  // reset acl manager
  sequenceResetAcl(module, null)
  // create module.state.route
  const moduleState = module[G.STATE]
  // setting unregistered route
  const { unregistered } = obj[G.CONFIGURATION].defaults
  const route = routeComposition(unregistered.module, unregistered.action)
  // clearing any errors, to make sure we can go to the route set above
  moduleState[G.ERROR] = false
  // set default context
  module[G.ADAPTER][G.ROUTER].defaultContext()
  // set default language
  await module[G.ADAPTER][G.INTL].defaultLanguage()
  // deleting cached auto-login request
  !settings.suppressPersistence && await module[G.ADAPTER][G.PERSISTENCE].requests.delete({
    url: '/api/v1/public/login',
    method: 'GET',
  })
  // remove storage's impersonation entry
  module[G.ADAPTER][G.STORAGE].remove('imp')
  // call plugins' logout
  await module[G.ADAPTER][G.PLUGIN].logout()
  // update application layout
  await module[G.ADAPTER][G.UI].provide(module)
  // Redirecting
  await moduleAction(route)
}

export default logoutHandler

import processScannedCode from 'app/camera/event/processScannedCode'
import toggleFlashlight from 'app/camera/event/toggleFlashlight'

export default {
  onClick: {
    toggleFlashlight,
  },
  onScan: {
    processScannedCode,
  },
}

/* global G */
import { asyncPipeSpread, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'

const setAttachment = module => component => async (components, ...args) => {
  const model = module[G.MODEL][G.CHILDREN]?.equipment || {}
  const { schematic = {} } = model?.[G.CACHE] || {}
  const attachmentId = schematic?.value?.attachmentId || null

  const { file } = components
  const attachment = !attachmentId ? null : {
    key: schematic.value.attachmentId,
    value: schematic.attachment.value,
  }

  setKey(attachment, 'attachment', file[G.STATE])

  return args
}

/**
 * EquipmentInformation Electric Diagram Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  setAttachment(module)(component),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)

/* global G */
import { curry } from 'lib/util'
import previous from 'app/cart/event/previous'

/**
 * Previous Or Back Event Handler
 *
 * Decreases the carts `activeStep` by one by executing {@link previous} only if
 * the `activeStep` is larger than 1. If not, it'll perform the routers' `back`
 * function to jump out of the cart.
 *
 * @param module
 * @param component
 * @param event
 * @returns {Promise<void>}
 */
const previousOrBack = async (module, component, event) => {
  const { activeStep = 0 } = component[G.STATE]

  activeStep > 0
    ? await previous(module, component, event)
    : await module[G.ADAPTER][G.ROUTER].back()
}

export default curry(previousOrBack)

/* global G */
import { curry } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action'
import routeComposition from '@gaia/trait/composition/route'

/**
 * Submit Event Handler
 *
 * In this case, an existing route configuration in `component` is not mandatory.
 *
 * It executes module action submit() by "Fire-N-Forget"
 *
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.AppModule.Action} component action component
 * @param {Event} event                     event
 * @param {String} [action]                 specific action to execute on module
 * @return {Promise<void>} void
 */
const submit = async (module, component, event, action = 'submit') => {
  event.preventDefault()
  event.stopPropagation()
  const moduleState = module[G.STATE]
  const currentRoute = moduleState[G.ROUTE]
  try {
    const { route } = component[G.CONFIGURATION]
    moduleState[G.ROUTE] = route ? routeComposition(route.module, route.action) : null
    await actionWithHooks(module[G.ACTIONS][action])()
  } catch (e) {
    console.error(e)
    moduleState[G.ROUTE] = currentRoute
  }
}

export default curry(submit)

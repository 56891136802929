import {Box} from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import {getFirstItem, getThemeColor} from 'lib/util'
import Text from 'ui/Element/Text/Simple'

const styles = theme => ({
  row: {
    display: 'flex',
  },
  root: {
    display: 'flex',
  },
  container: {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
    borderRadius: '2.5rem',
    width: 'auto',
  },
  text: {
    textTransform: 'uppercase',
  },
})

/**
 * Intended to display the name of the status of a cart.
 *
 * @param {Object} props    element's properties
 * @param {Object} classes  element's style classes
 *
 * @returns {JSX.Element}
 */
const ListItemCellCartStatus = (classes, props = {}) => {
  const { status, events = [] } = props

  const statusLabel = getFirstItem(events?.getStatus?.(status)) || {}

  return <Box
    sx={{
      backgroundColor: theme => getThemeColor(theme, statusLabel.backgroundColor) || getThemeColor(theme, 'gray.960'),
    }}
    className={classes.container}
  >
    <Text
      variant={'14/bold'}
      className={classes.text}
      color={statusLabel?.color || 'textDark'}
    >
      {statusLabel?.value || '-'}
    </Text>
  </Box>
}

export default CellHOC(ListItemCellCartStatus, styles)

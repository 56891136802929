/* global G */
import { curry } from 'lib/util'
import listInvitationStatuses from 'app/_shared/events/collection/listInvitationStatuses'

/**
 * Returns the possible invite statuses.
 *
 * @type Gaia.AppModule.EventHandler
 */
// eslint-disable-next-line no-unused-vars
const getInviteStatus = async (module, component, event) => {
  const model = module[G.MODEL]
  const { invite } = model[G.CHILDREN]
  const inviteCache = invite[G.CACHE] || {}
  const { status: userStatus = null } = inviteCache

  return listInvitationStatuses(module, component, userStatus)
}

export default curry(getInviteStatus)

/* eslint-disable no-unused-vars */
/* global G */
import { hasInternalParent } from 'app/organisation/action/detail'
import { showBlockingDialog } from 'app/_shared/events/dialog'
import { curry } from 'lib/util'

/**
 * SupportedBy Dialog
 *
 * Checks if the current organisations parent or grandparent is `ORGANISATION:RA` and shows a
 * confirmation dialog if so, returning its result. Otherwise, returns `true`.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @returns {Promise<*>}
 */
const supportedByDialog = async (module, component, event) => {
  const model = module[G.MODEL]

  // Org's parent or grandparent is ORGANISATION:RA
  if (!hasInternalParent(model)) {
    return await showBlockingDialog(module, component, {
      title: {
        ns: 'organisation',
        key: 'dialog.supportedByChanged.title',
        defaultValue: 'Confirm support change',
      },
      text: {
        ns: 'organisation',
        key: 'dialog.supportedByChanged.text',
        defaultValue: 'Changing the supporting organization will affect read and update permissions. Do you really want to change the supporting organization?',
      },
    })
  }

  return true
}

export default curry(supportedByDialog)

/* eslint-disable import/extensions */
/* global G */

import AppRenderer from '@ui/AppRenderer'
import UiAdapter from '@platform/adapter/ui'
import RouterAdapter from '@platform/adapter/router'
import HttpAdapter from '@platform/adapter/http'
import AttachmentAdapter from '@platform/adapter/attachment'
import MessageAdapter from '@platform/adapter/message'
import NotificationAdapter from '@platform/adapter/notification'
import CartAdapter from '@platform/adapter/cart'
import NoteAdapter from '@platform/adapter/note'
import StorageAdapter from '@platform/adapter/storage'
import SettingsAdapter from '@platform/adapter/settings'
import PersistenceAdapter from '@platform/adapter/persistence'
import PluginsAdapter from '@platform/adapter/plugin'
import sessionManager from '@gaia/manager/session'
import aclManager from '@gaia/manager/acl'
import intlManager from '@platform/adapter/intl'
import pubsubManager from '@gaia/manager/pubsub'

import appModuleComposition from '@platform'
// grab default/customer specific application settings.
import settings from 'config/app'

/**
 * The bootstrap file serves as initializer of all platform-dependent adapters and values needed by
 * the application.
 */

/** @type {Gaia.Web.Application} */
const app = appModuleComposition(settings)

app[G.SESSION] = sessionManager()

const settingsAdapter = SettingsAdapter(app)

app[G.SESSION][G.STATE][G.SETTINGS] = settingsAdapter[G.DATA]
app[G.SESSION][G.STATE][G.NOTIFICATION][G.GROUP] = settings.defaults?.links || []
app[G.SESSION][G.STATE][G.HTTP] = window.location.origin

/**
 * @memberOf Gaia#
 * @namespace Adapter
 * @type {{}}
 * @property {Gaia.Adapter.Router} G.ROUTER
 * @property {Gaia.Adapter.UI} G.UI
 * @property {Gaia.Adapter.EventBus} G.EVENTS
 * @property {Gaia.Adapter.HTTP} G.HTTP
 */
app[G.ADAPTER] = {
  [G.UI]: UiAdapter(AppRenderer)(app),
  [G.ROUTER]: RouterAdapter(app),
  [G.HTTP]: HttpAdapter(app),
  [G.EVENTS]: app[G.EVENTS],
  [G.SESSION]: app[G.SESSION],
  [G.SETTINGS]: settingsAdapter,
  [G.ATTACHMENT]: AttachmentAdapter(app),
  [G.ACL]: aclManager(app),
  [G.INTL]: intlManager(app),
  [G.MESSAGE]: MessageAdapter(app),
  [G.NOTIFICATION]: NotificationAdapter(app),
  [G.CART]: CartAdapter(app),
  [G.NOTE]: NoteAdapter(app),
  [G.PUBSUB]: pubsubManager(app),
  [G.STORAGE]: StorageAdapter(app),
  [G.PERSISTENCE]: PersistenceAdapter(),
  [G.PLUGIN]: PluginsAdapter(app),
}

/**
 * Bootstrapped Web Platform.
 *
 * Returns the main application composition with all its required platform-dependent adapters and
 * values initialized.
 *
 * @return {Gaia.Web.Application}
 */
export default () => app

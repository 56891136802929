/* eslint-disable no-unused-vars,arrow-body-style */
/* global G */
import find from 'lib/sequence/component/children/find'
import { asyncPipeSpread } from 'lib/util'
import { set } from 'lib/sequence/component/state/value'

/**
 * Sets the value of the model's password or `'-'` as the value of the `passwordInfo` component.
 *
 * @param {Gaia.AppModule.Spec} module  current module
 * @return {function(*, ...[*]): Promise<*[]>}
 */
export const setPasswordInfo = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { password } = model[G.CHILDREN]
  const { passwordInfo } = components
  const passwordValue = password[G.CACHE]

  set(passwordInfo, passwordValue || '-')

  return [components, ...args]
}

/**
 * OptionManagement create action
 *
 * @param {Gaia.AppModule.Spec} module  current module
 * @return {function(*): function(...[*]): Promise<*>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  setPasswordInfo(module),
)(find(component), ...args)

/* eslint-disable object-curly-newline,no-unused-vars */
/* global React, G */
import { useState, useContext, Suspense, useTransition, forwardRef, useMemo } from 'react'
import {
  Box,
  ButtonBase,
  Grid,
  Icon,
  IconButton,
  List,
  Menu,
  Paper,
  Toolbar as MuiToolbar,
  useTheme,
} from '@mui/material'
import { preload, useAclIterator } from '@platform/react/hook'
import BooleanContext from '@platform/react/context/boolean'
import AclMenuItem from 'ui/Component/Acl/Item/Menu'
import useACLIterator from '@platform/react/hook/useAclIterator'
import AppContext from '@platform/react/context/application'
import AclSimpleIcon from 'ui/Component/Acl/Item/Simple/Icon'
import ToggleLink from 'ui/Component/Layout/AppBar/ToggleLink'
import SvgIcon from 'ui/Element/Icon/Svg'
import settings from '@tenant/settings'
import Chip from 'ui/Element/Text/Chip'
import ButtonHOC from '@platform/react/hoc/button'
import NotificationHOC from 'platform/react/hoc/notification'

/**
 * Displays a dialog informing about the limitations of the current beta version.
 *
 * @param eventBus
 * @param intl
 * @returns {(function(): void)|*}
 */
const showBetaDialog = (eventBus, intl) => () => {
  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    width: 'sm',
    title: intl._t(
      'dialog.beta.title',
      {
        ns: 'custom',
        _key: 'dialog.beta.title',
        defaultValue: '',
      },
    ),
    text: intl.markdown(
      'dialog.beta.textMD',
      {
        ns: 'custom',
        _key: 'dialog.beta.textMD',
        defaultValue: '',
        joinArrays: '\n',
        md: true,
      },
    ),
    children: {
      cancel: {
        key: 'ok',
        value: intl._t(
          'button.ok',
          {
            ns: 'common',
            _key: 'button.ok',
            defaultValue: 'Ok',
          },
        ),
      },
    },
  })
}

/**
 * Helper function to only preload a template on initial render
 *
 * @param {string|null} template  the template to preload
 * @returns React.ReactNode|null
 * @private
 */
const _memoizedTemplate = template => useMemo(() => (!template ? null : preload(template)), [])

/**
 * Simple list of links to be displayed in the application's {@link Toolbar}.
 * Each link may specify its own component, e.g. {@link AclSimpleIcon}.
 *
 * Items have to be specified in the main application configuration
 * located at {@link client/src/app/default/config/app.json5}
 *
 * @param {Object} props    props for the list, like classes.
 * @returns {JSX.Element}
 * @constructor
 */
const Links = (props) => {
  const { acl, session, intl, links } = useContext(AppContext)
  const { classes, color } = props

  const children = useAclIterator(links.map(item => ({
    ...item,
    ...item.options,
    Template: _memoizedTemplate(item.template),
    context: session[G.CONTEXT],
    selected: [],
    classes,
    color,
    label: intl._t(
      `navigation.${item.options.key}`,
      { ns: 'sidebar', _key: `navigation.${item.options.key}`, defaultValue: item.options.label },
    ),
    ...item.options.description && {
      description: intl._t(
        `description.${item.options.key}`,
        { ns: 'sidebar', _key: `description.${item.options.key}`, defaultValue: item.options.description },
      ),
    },
    ...item.options.noDataLabel && {
      noDataLabel: intl._t(
        `noData.${item.options.key}`,
        { ns: 'common', _key: `noData.${item.options.key}`, defaultValue: item.options.noDataLabel },
      ),
    },
    ...item.options.openInNewTab && {
      openInNewTabLabel: intl._t(
        'button.openTab',
        { ns: 'common', _key: 'common.button.openTab', defaultValue: 'Open in new tab' },
      ),
    },
  })),
  {
    acl: acl.link,
    hoc: NotificationHOC,
    hocProp: 'notification',
    Template: AclSimpleIcon,
  })

  return (
    <Suspense
      fallback={<span>&nbsp;</span>}
    >
      <List
        className={classes.toolbarLinks}
        children={children}
      />
    </Suspense>
  )
}

/**
 * Profile menu to be displayed in the application's {@link Toolbar}.
 *
 * Items have to be specified in the main application configuration
 * located at {@link client/src/app/default/config/app.json5}
 *
 * @param {Object} props    props for the list, like classes.
 * @returns {JSX.Element}
 * @constructor
 */
const ProfileMenu = (props) => {
  const [, startTransition] = useTransition()
  const { acl, intl, session, menu } = useContext(AppContext)
  const { classes, color } = props
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => startTransition(() => setAnchorEl(event.currentTarget))

  const handleClose = () => startTransition(() => setAnchorEl(null))

  const children = useACLIterator(menu.map(item => ({
    ...item,
    ...item.options,
    context: session[G.CONTEXT],
    label: intl._t(
      `navigation.${item.options.key}`,
      { ns: 'sidebar', _key: `navigation.${item.options.key}`, defaultValue: item.options.label },
    ),
    dense: false,
    selected: null,
  })), {
    events: { onClick: handleClose },
    acl: acl.link,
    Template: AclMenuItem,
  })

  return (
    <>
      <IconButton
        className={classes.toolbarLinksItem}
        onClick={handleClick}
        size={'small'}
      >
        <SvgIcon
          icon={'account'}
          variant={'outlined'}
          color={color}
        />
        <SvgIcon
          width={'1rem'}
          height={'1rem'}
          variant={'filled'}
          icon={'carret_down'}
          color={color}
        />
      </IconButton>
      <Menu
        // component={Menu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        component={Paper}
        children={children}
      />
    </>
  )
}

/**
 * Applications main toolbar.
 *
 * @param {Object} props    props for the list, like classes.
 * @returns {JSX.Element}
 * @constructor
 */
const Toolbar = (props) => {
  const theme = useTheme()
  const { classes, login } = props
  const color = theme.palette.black.main

  const { eventBus, intl, isLogged } = useContext(AppContext)
  const { boolean, setBoolean } = useContext(BooleanContext)

  const ClickableChip = forwardRef(ButtonHOC(Chip))

  return (
    <MuiToolbar className={classes.toolbar}>
      {(isLogged ? (
        <>
          <Grid
            item
            className={classes.toolbarAction}
            sx={{ display: { md: 'none', xs: 'block' } }}
          >
            <IconButton
              size={'small'}
              onClick={() => setBoolean(!boolean)}
            >
              <Icon
                fontSize={'small'}
                className={classes.toolbarAction}>
                menu
              </Icon>
            </IconButton>
          </Grid>
          <Grid
            item
            container
            justifyContent={settings.showBetaTag ? 'space-between' : 'flex-end'}
            alignItems={'center'}
          >
            {settings.showBetaTag && (
              <ClickableChip
                color={'info'}
                text={'Beta Version'}
                icon={'info'}
                iconEnd
                events={{ onClick: showBetaDialog(eventBus, intl) }}
                buttonProps={{
                  component: ButtonBase,
                  xs: 'auto',
                }}
              />
            )}
            <Box className={classes.toolbarItems}>
              <Links
                color={color}
                classes={classes}
              />
              <ProfileMenu
                color={color}
                classes={classes}
              />
            </Box>
          </Grid>
        </>
      ) : <ToggleLink login={login} />)}
    </MuiToolbar>
  )
}

export default Toolbar

/* global G */
import { modelComposition } from 'trait/composition'
import { pipe } from 'lib/util'
import withGetterSetter from 'trait/with/GetterSetter'
import { usesGetterSetter } from 'trait/uses'
import fromTimestamp from 'model/_shared/decorator/fromTimestamp'
import toRef from 'model/_shared/transformer/toRef'
import drop from 'model/_shared/transformer/drop'

const descriptor = 'model::optionManagement::scan'

const submitDateLabelStyle = { dateStyle: 'short', timeStyle: 'short' }

export default pipe(
  modelComposition(descriptor),
  usesGetterSetter(G.TRANSFORMER, {
    submitter: toRef('submitter'),
    submitDateLabel: drop('submitDateLabel'),
  }),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    submitDateLabel: fromTimestamp(submitDateLabelStyle, 'submitDate', 'submitDateLabel'),
  }),
)

/* eslint-disable no-param-reassign,no-await-in-loop,no-plusplus,consistent-return,max-len,no-nested-ternary */
/* global G */
import { curry, setKey } from 'lib/util'
import session from 'app/_shared/session'
import search from 'app/_shared/events/search'

/**
 * Return the following information gathered from
 * the given message:
 *
 * {@code me} {boolean}: Whether or not I send the message.
 * {@code submitter} {string}: Either {@code firstName lastName} or {@code targetPartner}
 * {@code timestamp} {string}: The timestamp when the message was submitted.
 *
 * @param message
 * @param user
 * @returns {{submitter: string, submitTimestamp: string, me: boolean}}
 */
const getRelevantInformation = (message, user) => {
  const submitterRef = message?.refs?.submitter?.[0]
  const submitterPerson = message?.refs?.submitter?.[0]?.refs?.person?.[0]?.value

  const submitter = submitterRef
    ? submitterPerson?.firstName && submitterPerson?.lastName
      ? `${submitterPerson.firstName} ${submitterPerson.lastName}`
      : '-'
    : message?.value?.targetPartner || '-'

  return {
    submitter,
    submitTimestamp: message.value.submitTimestamp,
    me: message.refs?.submitter?.[0]?.key === user,
  }
}

/**
 * Get the parent (message) for each attachment.
 *
 * @param {Gaia.AppModule.Spec}    module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current component compsition object
 * @param {string} messageId                the message id.
 * @returns {Promise<*>}
 */
const getMessage = async (module, component, messageId) => {
  const model = module[G.MODEL]
  const user = session(module).user.key({ wrap: false })
  const { ref: api } = component[G.PROPS].api
  const { version } = model[G.PROPS]
  const url = `/api/v${version}/${api}/${messageId}`

  try {
    const message = await model[G.ADAPTER][G.HTTP].get({ url })

    return getRelevantInformation(message, user)
  } catch (e) {
    console.error(e)
  }
}

/**
 * Load all attachments for a given request/ticket,
 * Set the correct submitter and timestamp for
 * each and return them.
 *
 * @param {Gaia.AppModule.Spec}    module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current component compsition object
 * @param {Object[]} messages               the array of messages already fetched
 * @returns {Promise<*[]>}
 */
// eslint-disable-next-line no-unused-vars
const loadAttachments = async (module, component, messages) => {
  const user = session(module).user.key({ wrap: false })

  // TODO: Remove after GAIA-1128
  setKey(100, 'limit', component[G.PROPS])

  const attachments = await search(null, module, component, {
    type: 'attachment',
    filter: {
      parentType: 'message',
      grandparent: module[G.MODEL][G.STATE][G.REF],
    },
  })

  const parsedAttachments = []
  for (let i = 0; i < attachments.length; i++) {
    const attachment = attachments.at(i)
    /**
     * We already fetched a bunch of messages. Maybe the attachment we are
     * dealing with is amongst them? Search the fetched messages and
     * if we find it, use it to find the information we need, if
     * we haven't fetched it yet, do so.
     *
     * @type {Object}
     */
    const parentMessage = messages.find(message => message.key === attachment.refs.parent[0])
    const {
      me,
      submitter,
      submitTimestamp,
    } = parentMessage
      ? getRelevantInformation(parentMessage, user)
      : await getMessage(module, component, attachment.refs.parent[0])

    parsedAttachments.push({
      ...attachment,
      value: {
        ...attachment.value, submitter, submitTimestamp, me,
      },
    })
  }

  return parsedAttachments
}
export default curry(loadAttachments)

/* global G */
import { useContext, useEffect, useRef, useState } from 'react'
import useEventCallback from '@platform/react/hook/useEventCallback'
import useEventHandler from '@platform/react/hook/useEventHandler'
import ApplicationContext from '@platform/react/context/application'

/**
 * useCart Hook
 *
 * Fetches the current cart and returns it.
 *
 * @returns {{cart: unknown}}
 */
const useCart = ({ id, name } = {}) => {
  const { eventBus } = useContext(ApplicationContext)
  const [cart, setCart] = useState(null)
  const [position, setPosition] = useState(null)

  useEffect(() => {
    eventBus.dispatch(eventBus.type(G.CART, G.READ))
  }, [])
  const _cartEventName = useRef(eventBus.type(G.CART, G.DONE))
  const _cartEventHandler = useEventCallback(({ detail }) => {
    const { [G.DATA]: data } = detail
    setCart(data)
    if (id || name) {
      setPosition(data?.value?.positions?.find(x => x?.key === id || x?.name === name))
    }
  })
  useEventHandler(eventBus, _cartEventName.current, _cartEventHandler)

  return {
    cart,
    position,
  }
}

export default useCart

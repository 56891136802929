/* eslint-disable object-curly-newline, no-alert, no-restricted-globals */
import { back, persistChange, persistOptions, persistScroll, redirect } from '@app/_shared/events'
import menu from 'app/_shared/events/contextmenu'
import link from 'app/_shared/events/link'
import toAction from 'app/_shared/events/redirect/toAction'
import deleteModel from 'app/_shared/events/model/delete'
import infiniteWithDrafts from 'app/optionManagement/event/infiniteWithDrafts'
import getPassword from 'app/optionManagement/event/getPassword'
import submit from 'app/optionManagement/event/submit'
import remember from 'app/_shared/events/remember'
import { chain } from 'app/_shared/events/util'

export default {
  onOpen: {
    infiniteWithDrafts: infiniteWithDrafts('verbose'),
    link,
  },
  onClose: {
    back,
  },
  onClick: {
    submit,
    back,
    redirect,
    detail: toAction('detail'),
    getPassword,
    delete: deleteModel({
      alsoDraft: true,
      optimistic: true,
      onSuccess: back,
      notificationOptions: {
        ns: 'optionManagement',
        key: 'toast.delete',
        defaultValue: 'Password successfully deleted.',
      },
      dialogOptions: {
        title: {
          ns: 'optionManagement',
          key: 'dialog.delete.title',
          defaultValue: 'Delete password',
        },
        text: {
          ns: 'optionManagement',
          key: 'dialog.delete.text',
          defaultValue: 'Are you sure you want to delete this Password?',
        },
      },
    }),
  },
  onChange: {
    persistOptions,
    persistChange,
  },
  onContextMenu: {
    menu: menu(null),
  },
  onPage: {
    remember,
  },
  onScroll: {
    persistScrollAndRemember: chain(
      persistScroll,
      remember,
    ),
  },
}

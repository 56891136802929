import { curry } from 'lib/util'
import { remove } from 'app/_shared/events/cart'

/**
 * Removes a positions from the cart.
 *
 * @param {Function} callback                 callback to execute
 * @param {Gaia.AppModule.Spec} module        module composition
 * @param {Gaia.Component.Spec} component     action component
 * @param {Gaia.AppModule.EventHandler} event triggered event
 * @returns {Promise<void>}
 */
const _remove = async (callback, module, component, event) => {
  const { key, name } = event?.detail || event || {}

  const data = { key, name }

  await remove(module, component, data, callback, event)
}

export default curry(_remove)

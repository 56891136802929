/* global PLATFORM, G */
import { asyncPipeSpread, asyncPipeSpreadIf } from 'lib/util'
import sequenceModuleAdapterSessionStateMetaSet from 'lib/sequence/module/adapter/session/meta'
import sequenceModuleAdapterSessionStateNavigationReset from 'lib/sequence/module/adapter/session/navigation'
import find from 'lib/sequence/component/children/find'
import { sequenceAppSessionStateRoleSet } from 'lib/sequence/app/session/state/meta'
import validate from 'lib/sequence/model/validate'
import { disable } from 'lib/sequence/component/state/disabled'
import session from 'app/_shared/session'
import validateRedirect from 'app/guest/action/validateRedirect'

/**
 * Sets the next route according to the number of context available to the user:
 *  - If it has a single context, sets it and redirects the user to the access action
 *  - If it has more than one context, redirects the user to the context selection action
 *
 * @type {Gaia.AppModule.ActionPartial}
 */
const setNextRoute = module => async (components, ...args) => {
  // obtaining contexts' list
  const { component } = components
  const contexts = session(module).contexts()
  let result
  if (contexts.length > 1) {
    const route = { action: module[G.ACTIONS].context._name }
    result = [route, ...args]
  } else {
    const [contextName] = contexts
    module[G.ADAPTER][G.ROUTER].context(contextName)
    session(module).context(contextName)
    // result = await actionWithHooks(module[G.ACTIONS].access)(...args)
    const route = { action: module[G.ACTIONS].access._name }
    result = [route, ...args]
  }
  return await validateRedirect(module)(component)(...result)
}

/**
 * Initializes the user session by:
 *  - resetting the navigation state,
 *  - setting the user's role
 *  - storing the login payload
 *  - setting the user's language
 *
 * @type {Gaia.AppModule.ActionPartial}
 */
const startSession = module => async (components, ...args) => {
  const modelAdapter = module[G.MODEL][G.ADAPTER] // login response
  // TODO: Refactor with SP-957 / SP-956
  sequenceModuleAdapterSessionStateNavigationReset(module, [])
  const userSession = sequenceAppSessionStateRoleSet(module, modelAdapter[G.SESSION][G.META])
  sequenceModuleAdapterSessionStateMetaSet(module, userSession)
  // delete modelAdapter[G.SESSION] // TODO: why?
  // setting user's language
  const person = session(module).person.ref()
  const { lang } = person?.value || {}
  lang && await module[G.ADAPTER][G.INTL].set(lang)
  return [components, ...args]
}

/**
 * Validates the username and password fields of the login form.
 *
 * @type {Gaia.AppModule.ActionPartial}
 */
const validateForm = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { form, options } = components
  // validating username, password and remember me fields
  if (PLATFORM !== 'mobile') {
    await validate(model, options) // there is no `options` component in mobile
  }
  await validate(model, form)

  const moduleState = module[G.STATE]
  moduleState[G.ERROR] = model[G.STATE][G.ERROR]
  return [components, ...args]
}

/**
 * Enables or disables the model's username validator according to the value of {@param enable}.
 *
 * @param {Gaia.AppModule.Spec} module  current module composition
 * @param {boolean} enable              whether to enable or disable the username validator
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const toggleUsernameValidator = (module, enable) => async (components, ...args) => {
  const model = module[G.MODEL]
  const validator = model[G.VALIDATOR].username
  disable(validator, !enable)
  return [components, ...args]
}

/**
 * Attempts to initialize a new user session according to the values inserted in the login form.
 *
 * @param {Gaia.AppModule.Spec} module  current module composition
 * @returns {function(): function(...[*]): Promise<*>}
 */
export default module => () => async (...args) => asyncPipeSpread(
  toggleUsernameValidator(module, false),
  validateForm(module),
  asyncPipeSpreadIf(() => !module[G.STATE][G.ERROR])(
    startSession(module),
    setNextRoute(module),
  ),
  toggleUsernameValidator(module, true),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)

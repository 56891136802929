/**
 * PersistenceError constructor.
 *
 * Used to throw and handle persistence-specific errors.
 *
 * @param {string} message  a short text informing about the error
 */
function PersistenceError(message) {
  this.message = message
}

PersistenceError.prototype = Object.create(Error.prototype)
PersistenceError.prototype.constructor = PersistenceError
PersistenceError.prototype.name = 'PersistenceError'

export default PersistenceError

import { Box } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

const styles = theme => ({
  root: {
    display: 'flex',
  },
  labels: {
    overflow: 'hidden',
  },
})

/**
 * Displays the part name, articleNumber and comment for a part in the 'add part' list.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
export const ListItemCellCartPartName = (classes, props = {}) => {
  const { label, info1, articleNumberLabel } = props || {}

  return (
    <Box className={classes.labels}>
      <OverflowTooltip
        variant={'14/bold'}
        color={'textDark'}
      >
        {label}
      </OverflowTooltip>
      <OverflowTooltip
        variant={'12/regular'}
        color={'textSecondary'}
      >
        {`${articleNumberLabel} - ${info1}`}
      </OverflowTooltip>
    </Box>
  )
}

export default CellHOC(ListItemCellCartPartName, styles)

import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

/**
 * Displays the number of an Organisation.
 *
 * @param {Object} props    element's properties
 * @param {Object} classes  element's style classes
 *
 * @returns {JSX.Element}
 */
export const ListItemCellOrganisationNumber = (classes, props = {}) => {
  const { number = '-' } = props

  return (
    <OverflowTooltip
      variant={'14/bold'}
      color={'common.black'}
      component={Typography}
      className={classes.row}
    >
      {number}
    </OverflowTooltip>
  )
}

export default CellHOC(ListItemCellOrganisationNumber)

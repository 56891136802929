/* eslint-disable no-param-reassign */
/* global G */

/**
 * Find Component Sequence.
 *
 * Attempts to find and return the first component from the `component`'s children tree whose
 * key has the same value as the `key` parameter. If no component is found, null is returned.
 *
 * @param {Gaia.Component.Spec} component the component from which to start searching
 * @param {Object} [cache={}]             cache used to store and obtain already traversed elements
 * @returns {(string) => object}          a function expecting the key to look for as its unique
 *                                        parameter that returns the found component or null
 */
const sequenceComponentFind = (component, cache = {}) => (key) => {
  let found = cache[key] || null
  const children = component[G.CHILDREN] || []
  // looping component's children
  for (let x = 0; x < children.length && !found; x++) {
    const child = children[x]
    const childKey = child[G.PROPS].key
    found = childKey === key ? child : null
    cache[childKey] = cache[childKey] || child
  }
  // looping component's grandchildren, recursively
  for (let x = 0; x < children.length && !found; x++) {
    found = sequenceComponentFind(children[x], cache)(key)
  }

  return found
}

/**
 * Find Component Sequence Proxy.
 *
 * Returns a {@link Proxy} object wrapping {@link sequenceComponentFind}. Attempting to obtain a
 * property of the object will call the said function with the name of that property as the key
 * parameter.
 *
 * @example
 *  // elements at any depth can be obtained from any component above
 *  const { requestForm, deviceForm, requestType } = sequenceComponentFindProxy(component)
 *
 * @param {Gaia.Component.Spec} component    the component from which to start searching
 * @returns {Object}
 */
const sequenceComponentFindProxy = component => new Proxy(sequenceComponentFind(component), {
  get(target, name) {
    return name === 'component' ? component : target(name)
  },
})

export default sequenceComponentFindProxy

import { dataService, fileService as file } from 'platform/adapter/persistence/service'
import { now } from 'platform/adapter/persistence/helper'
import descriptor from 'platform/adapter/persistence/descriptor/files'

/**
 * @memberOf Gaia.Adapter.Persistence.PersistenceAPI
 * @typedef {PersistenceAPI} FilesAPI
 * @return {FilesAPI}
 */
export default (() => {
  const fileData = dataService(descriptor)
  const folderName = descriptor.name

  return Object.create({}, {
    init: {
      /**
       * Creates the file store, if it doesn't exist.
       *
       * @return {Promise<void>}
       */
      value: async () => await fileData.init(),
      iterable: true,
      enumerable: true,
    },
    get: {
      /**
       * Attempts to obtain a file stored with `put`.
       *
       * @param {string} url      the URL the file was obtained from, which is also its identifier
       * @return {Promise<Blob>}  the contents of the file
       */
      value: async (url) => {
        const item = await fileData.get(url)
        return await file.get(item, folderName)
      },
      iterable: true,
      enumerable: true,
    },
    put: {
      /**
       * Attempts to store a new file.
       *
       * @param {Object} item
       * @param {string} item.url     the URL the file was obtained from, which is its identifier
       * @param {string} item.name    the name of the file
       * @param {Blob} item.contents  the contents of the file
       * @return {Promise<void>}    the identifier of the new persisted item
       */
      value: async ({ url, name, contents }) => {
        const item = { url, name, contents, date: now() }
        await fileData.put(item)
        try {
          await file.put(item, folderName)
        } catch (error) {
          await fileData.remove(url)
          throw error
        }
      },
      iterable: true,
      enumerable: true,
    },
    delete: {
      /**
       * Attempts to delete a file stored with `put`.
       *
       * @param {string} url      the URL the file was obtained from, which is its identifier
       * @return {Promise<void>}  the identifier of the new persisted item
       */
      value: async (url) => {
        const item = await fileData.get(url)
        await fileData.remove(url)
        return await file.remove(item, folderName)
      },
      iterable: true,
      enumerable: true,
    },
  })
})()

/* global G */

import { set as setToken } from 'app/_shared/component/token'
import { translationsMap } from 'lib/util'

/**
 * UI Cart Title Hook
 *
 * Sets the title of the cart actions according to the amount of positions (items)
 * the cart has. E.g. `Positions (3)`
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @returns {function(...[*]): *[]}
 */
const uiCartTitle = obj => (...args) => {
  const action = obj[G.STATE][G.ACTION]
  const { module } = obj[G.CONFIGURATION]
  const ns = translationsMap[module] || module
  const _key = `title.${module}.${action._name}`
  const defaultValue = action[G.UI].title
  const context = obj[G.ADAPTER][G.SESSION][G.STATE][G.CONTEXT]

  const model = obj[G.MODEL]
  const modelCache = model[G.CACHE]
  const positionCount = modelCache?.value?.positions?.length || 0

  setToken(obj, { pos: positionCount })

  action[G.UI].title = obj[G.ADAPTER][G.INTL]._t(_key, {
    _key, ns, context, defaultValue, pos: positionCount,
  })

  return args
}

export default uiCartTitle

import noop from '@gaia/util/noop/action'
import detail from 'app/cart/action/detail'
import cart from './action/cart'
import checkout from './action/checkout'
import submit from './action/submit'
import webshop from './action/webshop'
import cartPositionComment from './action/cart/positions/comment'

export default {
  index: noop,

  cart,

  // mobile actions for adding/editing a comment for a position
  cartPositionComment,
  cartPositionCommentOptions: cartPositionComment,
  cartPositionAddPart: noop,

  checkout,
  detail,
  submit,
  webshop,
  pdf: noop,
}

/* eslint-disable no-unused-vars */
/* global G */
import find from 'lib/sequence/component/children/find'
import { set } from 'lib/sequence/component/state/value'

/**
 * OptionManagement error action
 *
 * @param {Gaia.AppModule.Spec} module  current module
 */
export default module => component => async (...args) => {
  const { error: errorMessage } = args[0] || {}
  const { instructions } = find(component)
  errorMessage && set(instructions, errorMessage)

  return args
}

/* global PLATFORM, G */
import { asyncPipeSpread, asyncPipeSpreadIf, bulk, setKey } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import map from 'lib/sequence/model/api/map'
import { get, set } from 'lib/sequence/component/state/value'
import { hide, show } from 'lib/sequence/component/state/hidden'

const bulkShow = bulk(show)
const bulkHide = bulk(hide)

/**
 * Maps various model properties to the action component.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapProperties = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const { recipientData } = model[G.CHILDREN]

  // component
  const { mainSection, title } = children

  const bulkMap = bulk(map(model))
  bulkMap(mainSection, title)

  if (PLATFORM === 'mobile') {
    const { mobileContactInfoWrapper, mobileCommentWrapper } = children
    map(model)(mobileCommentWrapper)
    map(recipientData)(mobileContactInfoWrapper)
  }

  return [children, ...args]
}

/**
 * Determines if the address should be displayed.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayAddress = module => async (children, ...args) => {
  const { [G.INTL]: intlAdapter } = module[G.ADAPTER]

  // component
  const { address } = children
  const addressState = address[G.STATE]

  const intlAddress = intlAdapter.address(addressState?.value)

  intlAddress?.length
    ? set(address, intlAddress)
    : hide(address)

  return [children, ...args]
}

/**
 * Determines if the contact information should be displayed.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayContactInfo = module => async (children, ...args) => {
  // component
  const { contactInfo } = children

  !get(contactInfo) && hide(contactInfo)

  if (PLATFORM === 'mobile') {
    const { mobileContactInfoLabel, mobileContactInfoWrapper } = children
    const { contactInfo: mobileContactInfo } = sequenceComponentFind(mobileContactInfoWrapper)

    !get(mobileContactInfo)
      && hide(mobileContactInfoWrapper)
      && hide(mobileContactInfoLabel)
  }

  return [children, ...args]
}

/**
 * Determines if the request details section should be displayed.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayRequestDetails = module => async (children, ...args) => {
  // component
  const { contactInfo, address, name, recipientData } = children

  !get(address)
  && !get(name)
  && !get(contactInfo)
  && hide(recipientData)

  return [children, ...args]
}

/**
 * Determines if the comment section should be displayed.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayComment = module => async (children, ...args) => {
  // component
  const { comment } = children

  !get(comment) && hide(comment)

  if (PLATFORM === 'mobile') {
    const { mobileCommentWrapper, mobileCommentLabel } = children
    const { comment: mobileComment } = sequenceComponentFind(mobileCommentWrapper)

    !get(mobileComment)
      && hide(mobileCommentWrapper)
      && hide(mobileCommentLabel)
  }

  return [children, ...args]
}

/**
 * Displays the total amount of positions of the cart.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayTotal = module => async (children, ...args) => {
  const model = module[G.MODEL]
  const modelCache = model[G.CACHE]
  const positionCount = modelCache.value?.positions?.length || 0

  // component
  const { total } = children

  set(total, positionCount)

  return [children, ...args]
}

/**
 * Reloads the summary list
 *
 * This needs to be done because we may come back here from the cart modal, executing back
 * in that case doesn't rerender the summary list.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const reloadSummary = module => async (children, ...args) => {
  const { summary } = children

  setKey({}, 'reload', summary[G.STATE])

  return [children, ...args]
}

/**
 * Determines what actions should be displayed.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayActions = module => async (children, ...args) => {
// model
  const model = module[G.MODEL]
  const { status } = model[G.CHILDREN]
  const currentStatus = status[G.CACHE] || 0

  // component
  const { btnEdit, btnCart, btnTransfer, btnPdf } = children

  currentStatus === 80
    ? bulkShow(btnTransfer, btnPdf)
    : bulkHide(btnTransfer, btnPdf)

  currentStatus === 0 || currentStatus === 80
    ? bulkShow(btnEdit)
    : bulkHide(btnEdit)

  currentStatus === 50
    ? show(btnCart)
    : hide(btnCart)

  return args
}

/**
 * Cart Detail Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  mapProperties(module),
  displayAddress(module),
  displayContactInfo(module),
  displayRequestDetails(module),
  displayComment(module),
  displayTotal(module),
  asyncPipeSpreadIf(() => PLATFORM === 'web')(
    reloadSummary(module),
  ),
  displayActions(module),
)(sequenceComponentFind(component), ...args)

/* global G */
/* eslint-disable no-use-before-define,no-param-reassign */
import { curry } from 'lib/util'

/**
 *
 * @param module
 * @param component
 * @return {*}
 */
const provideEvents = (module, component) => {
  const events = component[G.CONFIGURATION].options && component[G.CONFIGURATION].options.events
  const moduleEvents = module[G.EVENTS]
  if (events && moduleEvents) {
    component[G.EVENTS] = {}
    Object.keys(events).forEach((name) => {
      const handlerName = events[name]
      const handlerExists = moduleEvents[name]
        && Object.prototype.hasOwnProperty.call(moduleEvents[name], handlerName)
      // todo: turn into pipe(hasNamespace, usesNamespace)
      handlerExists
      && (component[G.EVENTS][name] = {})
      && (component[G.EVENTS][name] = moduleEvents[name][handlerName](module, component))
      !handlerExists && console.warn(
        `ATTENTION!!! missing required event handler '${handlerName}' for '${name}' event trigger`,
      )
    })
  }
  return component
}

export default curry(provideEvents)

/* global G */
import routeComposition from 'trait/composition/route'
import seqSessionCookieLogout from '@gaia/sequence/app/session/cookie/logout'
import seqModuleAdapterSessionStateNavigationReset from '@gaia/sequence/module/adapter/session/navigation'
import seqResetAcl from '@gaia/sequence/app/acl/reset'
import seqDisconnectPubSub from 'lib/sequence/app/pubsub/disconnect'
import seqCleanAttachmentCache from 'lib/sequence/app/init/cleanAttachmentCache'
import settings from '@tenant/settings'

const action = module => () => async (...args) => {
  // disconnect to pubsub channels
  seqDisconnectPubSub(module, null)
  // invalidate cookie
  await seqSessionCookieLogout(module)(null)
  // reset nav stack
  seqModuleAdapterSessionStateNavigationReset(module, [])
  // reset acl manager
  seqResetAcl(module, null)
  // Clean attachment cache
  seqCleanAttachmentCache(module)()
  // create module.state.route
  const moduleState = module[G.STATE]
  // eslint-disable-next-line no-undefined
  moduleState[G.ROUTE] = routeComposition(undefined, 'index')
  // clearing any errors, to make sure we can go to the route set above
  moduleState[G.ERROR] = false
  // set default context
  module[G.ADAPTER][G.ROUTER].defaultContext()
  // set default language
  await module[G.ADAPTER][G.INTL].defaultLanguage()
  // deleting cached auto-login request
  !settings.suppressPersistence && await module[G.ADAPTER][G.PERSISTENCE].requests.delete({
    url: '/api/v1/public/login',
    method: 'GET',
  })
  // remove storage's impersonation entry
  module[G.ADAPTER][G.STORAGE].remove('imp')
  // call plugins' logout lifecycle method
  await module[G.ADAPTER][G.PLUGIN].logout()
  // let hook redirect, do its magic
  return args
}

export default action

/* global PLATFORM, G */
import { asyncPipeSpread, asyncPipeSpreadIf, bulk, getFirstItem, setKey } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import asObject from 'lib/sequence/component/children/asObject'
import details from 'app/cart/action/cart/details'
import positions from 'app/cart/action/cart/positions'
import refresh from 'lib/sequence/model/api/refresh'
import map from 'lib/sequence/model/api/map'

/**
 * Executes the action for each cart step.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, *, ...[*]): Promise<*|Promise<Awaited<*>>>}
 */
const cartActions = module => async ({ actions, children, ...args }) => {
  const { steps: stepsComponent, cart } = children
  const steps = asObject(stepsComponent[G.CHILDREN])
  // grouping all step actions and obtaining a list with their names
  const actionKeys = Object.keys(actions)
  // looping steps
  return await actionKeys.reduce(async (acc, key) => {
    const step = steps[key]
    // calling step action
    return step ? await actions[key](module)(cart)(await acc) : acc
  }, Promise.resolve(args))
}

/**
 * Sets the currently active step.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, *, ...[*]): Promise<*|Promise<Awaited<*>>>}
 */
const setCurrentStep = module => async (children, ...args) => {
  const { cart } = children
  const cartState = cart[G.STATE]

  if (!cartState?.activeStep) {
    setKey(0, 'activeStep', cartState)
  }

  return [children, ...args]
}

/**
 * Displays the correct action button depending on the current step.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, *, ...[*]): Promise<*|Promise<Awaited<*>>>}
 */
const displayActionButtons = module => async (children, ...args) => {
  const model = module[G.MODEL]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const actions = getFirstItem(actionComponent[G.ACTIONS])
  const { btnStep, btnCheckout } = sequenceComponentFind(actions)

  const modelCache = model[G.CACHE]
  const { value: cartCache } = modelCache

  const positionsCount = cartCache?.positions?.length || 0

  const { cart, steps } = children
  const cartState = cart[G.STATE]
  const stepCount = steps[G.CHILDREN]?.length || 0
  const canStep = cartState.activeStep < stepCount - 1

  setKey(!canStep, 'hidden', btnStep[G.STATE])
  setKey(canStep, 'hidden', btnCheckout[G.STATE])

  setKey(!positionsCount, 'hidden', actions[G.STATE])

  return [children, ...args]
}

/**
 * Gets the current cart from the cart adapter and sets the model to it.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, *, ...[*]): Promise<*|Promise<Awaited<*>>>}
 */
const setCart = module => async (children, ...args) => {
  // model
  const model = module[G.MODEL]
  const eventBus = module[G.ADAPTER][G.EVENTS]

  // component
  const { cart, mainContainer, commentData } = children
  const cartState = cart[G.STATE]

  const data = await new Promise((resolve) => {
    eventBus.add(eventBus.type(G.CART, G.DONE), ({ detail }) => {
      const { [G.DATA]: currentCart } = detail
      resolve(currentCart)
    }, { once: true })
    eventBus.dispatch(eventBus.type(G.CART, G.READ))
  })

  // Setting the cart model so we can map/validate it
  data && await refresh(model, data)

  if (!cartState?.mapped) {
    const bulkMap = bulk(map(model))
    bulkMap(mainContainer, commentData)
    setKey(true, 'mapped', cartState)
  }

  setKey(data, 'cart', cartState)

  return [children, ...args]
}

/**
 * Determines whether the summary items should be shown for the current active step.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, *, ...[*]): Promise<*|Promise<Awaited<*>>>}
 */
const displaySummaryItems = module => async (children, ...args) => {
  const { cart, summary } = children
  const cartState = cart[G.STATE]
  const summaryState = summary[G.STATE]

  switch (cartState?.activeStep) {
    case 0: setKey(false, 'showItems', summaryState)
      break
    case 1: setKey(true, 'showItems', summaryState)
      break
    default: break
  }

  return [children, ...args]
}

/**
 * Reloads the positions list every time we execute the action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, *, ...[*]): Promise<*|Promise<Awaited<*>>>}
 */
const updatePositions = module => async (children, ...args) => {
  const model = module[G.MODEL]
  const modelCache = model[G.CACHE]
  const { positions: cachedPositions } = module[G.STATE]
  const cartPositions = modelCache?.value?.positions?.length
    ? modelCache.value.positions.map(x => ({ key: x?.key || x.name, value: { ...x } }))
    : []

  const { positions: positionStep } = children
  const { list } = sequenceComponentFind(positionStep)

  const hasPositionsChanged = cachedPositions?.length !== cartPositions.length

  if (hasPositionsChanged) {
    if (cartPositions?.length) {
      setKey(cartPositions, 'data', list[G.STATE])
      setKey({}, 'update', list[G.STATE])
    } else {
      // We removed last position, cart is now empty, we need to reload
      setKey(null, 'data', list[G.STATE])
      setKey({}, 'reload', list[G.STATE])
    }
  }

  // Unfortunately, we need to use the moduleState here. There are two scenarios
  // 1. We change an existing position in the cart (change amount, comment, ...)
  //    In this case, we don't want to reload the list, because the cells themselves
  //    use the `useCart` hook and will be notified about the change
  // 2. We add or remove a position, in this case, we need to reload the list
  //
  // We execute this action in either of those two cases. So for the latter case, we
  // need to keep track of the number of positions, so that we know when we need
  // to reload the list and when not to, we can't use the actionState for this
  // because adding a position is its own action (on mobile) and would kill the
  // state when going back
  setKey(cartPositions, 'positions', module[G.STATE])

  return [children, ...args]
}
/**
 * Gathers the actions for the stepper.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
const initCartActions = module => async (...args) => [
  { positions, details }, args,
]

/**
 * Initializes the cart
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, *, ...[*]): Promise<*|Promise<Awaited<*>>>}
 */
// eslint-disable-next-line max-len
const initCart = module => async (actions, children, ...args) => await cartActions(module)({ actions, children, ...args })

/**
 * Cart Cart Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  setCart(module),
  setCurrentStep(module),
  asyncPipeSpreadIf(() => PLATFORM === 'mobile')(
    displayActionButtons(module),
    updatePositions(module),
  ),
  displaySummaryItems(module),
  initCartActions(module),
  initCart(module),
)(sequenceComponentFind(component), ...args)

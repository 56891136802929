/* global G */
import { curry, def } from 'lib/util'
import { empty } from 'lib/util/object'

/**
 *
 * @type Gaia.AppModule.EventHandler
 */
// eslint-disable-next-line no-unused-vars
const getValidationStatus = async (module, component, event) => {
  const model = module[G.MODEL]
  const { toBeValidated } = model[G.CHILDREN]
  const validationCache = toBeValidated?.[G.CACHE] || {}

  return def(validationCache) && !empty(validationCache)
    ? [{
      key: 'to_be_validated',
      icon: 'fiber_manual_record',
      color: 'signal',
      value: module[G.ADAPTER][G.INTL]._t(
        'label.toBeValidated',
        {
          ns: 'common',
          _key: 'label.toBeValidated',
          defaultValue: 'To be validated',
        },
      ),
    }] : [{}]
}

export default curry(getValidationStatus)

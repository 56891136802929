/* eslint-disable object-curly-newline */
import destroy from 'app/_shared/events/destroy'
import list from 'app/_shared/events/list'
import redirect from 'app/_shared/events/redirect'
import back from 'app/_shared/events/redirect/back'
import detail from 'app/_shared/events/action/detail'
import edit from 'app/_shared/events/action/edit'
import submit from 'app/_shared/events/action/submit'
import bulk from 'app/_shared/events/action/bulk'
import {
  persistChange,
  persistCheck,
  persistOption,
  persistOptions,
  persistScroll,
} from './persist'

/**
 * Event Handler.
 *
 * A module's event handler always gets the module itself, the component that fired the event and
 * the fired event as props.
 *
 * @memberOf AppModule#
 * @typedef {function} Gaia.AppModule.EventHandler
 * @param {Gaia.AppModule.Spec} module    the current module object composition
 * @param {Gaia.Component.Spec} component the object composition of the component that triggered the
 *                                        event
 * @param {Gaia.PlatformEvent} event      the event object
 * @returns {Promise<void|any>}
 */

const sharedEvents = {
  persistChange,
  persistCheck,
  persistScroll,
  edit,
  list,
  redirect,
  persistOptions,
  persistOption,
  submit,
  bulk,
  detail,
  back,
  destroy,
}

export {
  sharedEvents as default,
  persistChange,
  persistCheck,
  persistOptions,
  persistOption,
  persistScroll,
  edit,
  list,
  redirect,
  submit,
  bulk,
  detail,
  back,
  destroy,
}

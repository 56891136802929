import settings from '@tenant/settings'
import disabled from './disabled'
import requests from './requests'
import files from './files'
import drafts from './drafts'

const apis = { requests, files, drafts }

/**
 * @typedef {Function} DataServiceFactory
 * @param {StoreDescriptor} storeDescriptor
 * @returns {DataService}
 */

/**
 * @typedef {Object} DataService
 * @property {function(): Promise<void>} init
 * @property {function(number|string|array): Promise<any>} get
 * @property {function(Object): Promise<any>} getAll
 * @property {function(Object): Promise<Object>} put
 * @property {function(number|string|array): Promise<any>} remove
 * @property {function(): Promise<any>} removeAll
 */

/**
 * @typedef {Object} FileService
 * @property {function(Object, string): Promise<Blob>} get
 * @property {function(Object, string): Promise<void|boolean>} put
 * @property {function(Object, string): Promise<void|boolean>} remove
 */

/**
 * @typedef {Object} PersistenceAPI
 * @property {function(): Promise<void>} init
 * @property {function(number|string|array): Promise<any>} get
 * @property {function(Object): Promise<any>} [getAll]
 * @property {function(Object): Promise<Object>} put
 * @property {function(number|string|array): Promise<any>} delete
 * @property {function(): Promise<any>} [deleteAll]
 */

/**
 * Persistence API
 *
 * Defines the methods to be exposed by the API object of the Persistence Adapter.
 *
 * @memberOf Gaia.Adapter.Persistence
 * @typedef {Object} PersistenceAPIs
 * @property {RequestsAPI} requests
 * @property {FilesAPI} files
 * @property {DraftsAPI} drafts
 */

/**
 * If the `suppressPersistence` setting is true, returns the {@link disabled} api, otherwise returns
 * `api`.
 *
 * @param {PersistenceAPI} api          the object to return if persistence is enabled
 * @return {function(): PersistenceAPI} the corresponding persistence API
 */
const disabledOr = api => () => (settings.suppressPersistence ? disabled : api)

/**
 * Initializes one store after another.
 *
 * @return {Promise<void>}
 */
export const initialize = async () => {
  await Promise.all(Object.keys(apis).map(async (name) => {
    await apis[name].init()
  }))
}

/**
 * Returns an implementation of {@link PersistenceAPIs}. Intended to abstract the use of the
 * underlying platform-dependent persistence mechanisms.
 *
 * {@see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API}
 * @returns {PersistenceAPIs} api - storage api
 */
const api = () => Object.create({}, Object.keys(apis).reduce((acc, name) => ({
  ...acc,
  [name]: {
    get: disabledOr(apis[name]),
    iterable: true,
    enumerable: true,
  },
}), {}))

export default api

/* global G */
import { curry, setKey } from 'lib/util'

/**
 * Set Search In Tree Event Handler
 *
 * Sets the incoming information about the search in the action state as well
 * as the component state. If `component` was specified in the actionUi's `search`
 * property, it will be that component. Otherwise, it'll be the action component.
 *
 * @param {Gaia.AppModule.Spec} module          the current module composition object
 * @param {Gaia.Component.Spec} component       the current action's main component
 * @param {Gaia.AppModule.EventHandler} event   event that triggered this handler
 */
const setSearchInTree = (module, component, event) => {
  const componentState = component[G.STATE]
  const actionState = module[G.STATE][G.ACTION][G.STATE]
  const { open, term } = event?.detail || event || {}

  // Setting the action state
  setKey(term, 'searchTerm', actionState)
  setKey(open, 'searchOpen', actionState)

  // Setting the component state
  // If `search.back.component` is set in the action ui, `component` will
  // be the actual Explorer/Hotspot component we are currently viewing
  setKey(term, 'searchTerm', componentState)
  setKey(open, 'searchOpen', componentState)

  module[G.ADAPTER][G.UI].update(module)
}

export default curry(setSearchInTree)

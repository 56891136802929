/* eslint-disable no-unused-vars,no-param-reassign */
/* global G */
import { curry, getFirstItem, PlatformEvent } from 'lib/util'

/**
 * Finds the first node up the tree that has multiple children and
 * returns either it or the root node.
 *
 * @param {Object} node the current node
 * @returns {*}
 */
const findParentNode = (node) => {
  if (node?.parent?.$children && node.parent.$children.length === 1) {
    return findParentNode(node.parent)
  }

  return node.parent
}
/**
 * Set Parent Node Or Back Event Handler
 *
 * Inspects the currently selected node of the tree currently being displayed,
 * and if it has a parent, will execute the trees {@link onChange} handler with
 * the given node and rerender the tree afterward. If not, will execute
 * platform's {@code back} action.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {PlatformEvent} event             the event object that triggered this handler
 * @returns {Promise<void>}
 */
const setParentNodeOrBack = async (module, component, event) => {
  const actionState = module[G.STATE][G.ACTION][G.STATE]
  const { tree = {}, node = {} } = actionState || {}

  // A node may have the property {@code initialNode} set to {@code true}. That
  // basically sets this node as the root node for the current tree, meaning
  // we should not go to its parent on back, but rather jump out of the tree.
  // However, we may only use this if the component also has the {@code onInitialNode}
  // handler present, if it doesn't, this means that this functionality is
  // disabled
  const hasInitialNode = node?.initialNode && component[G.EVENTS]?.onInitialNode

  if (!node?.name || !node?.parent || hasInitialNode) {
    // In case we don't have a parent for the current node, we are clicking 'back' with the
    // root node displayed. In this case, just perform a native 'back' event.
    await module[G.ADAPTER][G.ROUTER].back()
    return
  }

  if (node.parent) {
    const { onChange = null } = component[G.EVENTS]
    const clickEvent = new PlatformEvent(event, {
      value: {
        tree,
        // If the component has {@code skipSingleNode} set to {@code true}, it means
        // that we may skip nodes going down the tree. In that case, we have to do
        // the same when going up the tree, so that the user doesn't get to nodes
        // (going up the tree) they didn't go through when going down the tree.
        node: {
          ...component[G.PROPS]?.skipSingleNode
            ? findParentNode(node)
            : node.parent,
          back: true,
        },
      },
    })
    // Forcing rerender of the component
    component[G.STATE].update = {}

    /**
     * Changing the current node by executing the explorers {@link onChange} event. This is usually
     * {@link unsavePersistTreeAndRemember}.
     */
    await onChange?.(clickEvent)

    // Executing UI update
    module[G.ADAPTER][G.UI].update(module)
  }
}

export default curry(setParentNodeOrBack)

/* global G */
import { pipe } from '@gaia/util'
import { hasNamespace } from '@gaia/trait/has'
import { withGetterSetterFn } from '@gaia/trait/with/GetterSetter'
import { usesNamespace, usesGetterSetter } from '@gaia/trait/uses'
import { withObjectFreeze, withDescriptor } from '@gaia/trait/with'
import api from '@platform/adapter/plugin/api'

const descriptor = 'adapter:Plugin'

/**
 * Plugin Adapter.
 *
 * Adapter used to manage plugins and external libraries which can be dynamically added and removed.
 *
 * @memberOf Gaia.Adapter
 * @typedef {Function} Gaia.Adapter.Plugin
 * @property {Gaia.Adapter.Plugin.API} G.API
 * @property {Object} G.CACHE
 */

/**
 * @param {Gaia.Web.Application} obj
 * @returns {Gaia.Adapter.Plugin}
 */
const adapter = obj => pipe(
  withDescriptor(descriptor),
  hasNamespace(G.API),
  usesNamespace(G.API, api(obj)),
  withGetterSetterFn(G.CACHE),
  usesGetterSetter(G.CACHE, {}),
  withObjectFreeze,
)({})

export default adapter

/* eslint-disable object-curly-newline,no-unused-expressions,no-unused-vars */
/* global PLATFORM, G */
import { settings } from 'app/_shared/session'
import { persistChange, persistCheck, redirect } from '@app/_shared/events'
import actionByKey from 'app/_shared/events/action/byKey'
import chain from 'app/_shared/events/util/chain'
import platform from 'app/_shared/events/util/platform'
import contextList from 'app/guest/event/list/context'
import setContext from 'app/guest/event/setContext'
import closeDialog from 'app/guest/event/closeDialog'
import getSupport from 'app/guest/event/getSupport'
import redirectWithUsername from 'app/guest/event/redirectWithUsername'
import resetForm from 'app/guest/event/resetForm'
import ui from 'app/guest/event/ui'
import needHelp from 'app/guest/event/needHelp'
import { curry } from 'lib/util'
import find from 'lib/sequence/component/children/find'

export default {
  onOpen: {
    contextList,
  },
  onClick: {
    setContext,
    validateLogin: actionByKey('validateLogin'),
    validateRedirect: actionByKey('validateRedirect'),
    closeDialog,
    getSupport,
    redirect,
    redirectWithUsername,
    needHelp,
  },
  onClose: {
    closeDialog,
    redirect,
  },
  onChange: {
    persistChange,
    persistUsername: chain(
      persistChange,
      platform({
        web: resetForm,
      }),
    ),
    persistCheck,
  },
  onSubmit: {
    validateLogin: actionByKey('validateLogin'),
    focusPasswordField: curry((module, component, event) => {
      const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
      const { password } = find(actionComponent)
      password[G.STATE].focus = {}
      module[G.ADAPTER][G.UI].update(module)
    }),
  },
  onBlur: {
    validateUsername: chain(
      (module, component, event) => {
        (PLATFORM !== 'mobile' && !settings.singleStepLogin) && throw Error('No single step login')
      },
      actionByKey('validateRedirect'),
      // If we don't update the UI, the AppBar won't display
      // the actual state of the history stack after it has
      // been altered by the redirect hook
      ui,
    ),
  },
}

import { curry } from 'lib/util'
import { change } from 'app/_shared/events/cart'

/**
 * Changes the amount of a position of the cart.
 *
 * @param {Function} callback                 callback to execute
 * @param {Gaia.AppModule.Spec} module        module composition
 * @param {Gaia.Component.Spec} component     action component
 * @param {Gaia.AppModule.EventHandler} event triggered event
 * @returns {Promise<void>}
 */
const amount = async (callback, module, component, event) => {
  const { key, name, amount: posAmount } = event?.detail || event || {}
  const data = { key, name, data: { amount: posAmount } }

  await change(module, component, data, callback, event)
}

export default curry(amount)

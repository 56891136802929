/* eslint-disable no-unused-expressions,object-curly-newline,no-unused-vars */
/* global G */
import { asyncPipeSpread } from 'lib/util'
import { show } from 'lib/sequence/component/state/hidden'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import presetRoleField from 'app/admin/action/approve/partials/presetRoleField'
import { displayVerifyButtonForApproval } from 'app/admin/action/approve/partials/displayVerifyButton'
import presetOrganisationName from 'app/admin/action/approve/partials/presetOrganisationName'

/**
 * Maps various properties inside the property box.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayExplanationField = module => async (children, ...args) => {
  const { approveDuplicateExplanation } = children

  approveDuplicateExplanation && show(approveDuplicateExplanation)

  return [children, ...args]
}

/**
 * Organisation Action Check Duplicate
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  presetRoleField(module)(component),
  displayExplanationField(module),
  presetOrganisationName(module),
  displayVerifyButtonForApproval(module),
)(sequenceComponentFind(component), ...args)

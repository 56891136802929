/* eslint-disable no-unused-vars */
/* global G */

import { curry } from 'lib/util'

/**
 * Set Title From Node Event Handler
 *
 * Sets the action title for the {@link Hotspot} component based on the currently
 * selected `node` inside the action state.
 *
 * @param {Gaia.AppModule.Spec} module          the current module composition object
 * @param {Gaia.Component.Spec} component       the current action's main component
 * @param {Gaia.AppModule.EventHandler} event   the event object that triggered this handler
 */
const setTitleFromNode = (module, component, event) => {
  const model = module[G.MODEL]
  const { node } = module[G.STATE][G.ACTION][G.STATE]
  const { depth, name } = node || {}
  const actionUi = module[G.STATE][G.ACTION][G.UI]

  const isChild = depth !== 0
  const isDirectory = node?.type === 'Directory'

  if (name && isChild && isDirectory) {
    actionUi.title = name
    actionUi.subTitle = null
  }

  if (!isChild && isDirectory) {
    const { equipment: { [G.CACHE]: {
      name: equipmentName = null,
      serial = null,
      serialLong = null,
    } = {} } = {} } = model[G.CHILDREN]
    actionUi.title = equipmentName;
    (serialLong || serial) && (actionUi.subTitle = serialLong || serial)
  }

  module[G.ADAPTER][G.UI].update(module)
}

export default curry(setTitleFromNode)

/* global G */
import { isArr } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'
import { disable, enable } from 'lib/sequence/component/state/disabled'
import { checked } from 'lib/sequence/component/state/checked'
import settings from '@tenant/settings'
import { hide } from 'lib/sequence/component/state/hidden'

/**
 * Enables/Disables the {@code verify} button based on the confirmation checkbox and tenant
 * settings for the block and delete options.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
export const displayVerifyButtonForAuxiliary = module => async (children, ...args) => {
  const skipVerification = settings?.skipVerificationOnApproval
  const model = module[G.MODEL]
  const { ticket } = model[G.CHILDREN]

  const hasError = model[G.STATE][G.ERROR] || ticket[G.STATE][G.ERROR]

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const [checkboxes, actions] = actionComponent[G.ACTIONS]

  const { confirm } = sequenceComponentFind(checkboxes)
  const { btnVerify } = sequenceComponentFind(actions)

  skipVerification && hide(confirm)

  !hasError && (skipVerification ? true : checked(confirm))
    ? enable(btnVerify)
    : disable(btnVerify)

  return args
}

/**
 * Enables/Disables the {@code verify} button based on the selected role and confirm
 * checkbox and the tenant settings for the approval option.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
export const displayVerifyButtonForApproval = module => async (children, ...args) => {
  const skipVerification = settings?.skipVerificationOnApproval
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const [checkboxes, actions] = actionComponent[G.ACTIONS]

  const { role } = children
  const { confirm } = sequenceComponentFind(checkboxes)
  const { btnVerify } = sequenceComponentFind(actions)

  skipVerification && hide(confirm)

  const roleValue = get(role)
  const hasValue = roleValue && isArr(roleValue) && roleValue.length

  hasValue && (skipVerification ? true : checked(confirm))
    ? enable(btnVerify)
    : disable(btnVerify)

  return args
}

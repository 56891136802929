/* eslint-disable max-len,no-nested-ternary */
import useAcl from '@platform/react/hook/useAcl'
import { Divider } from '@mui/material'

/**
 * Iterate through provided children, check ACLs and return a component for each child.
 *
 * One can specify `hoc`, which should be a React component, if specified, the `Template`
 * will be wrapped in it. Additionally, one can specify `hocProp`, which should be a
 * string. If specified, the prop with that name from the incoming options will be provided
 * to the hoc component.
 *
 * If `withDivider` is specified, the iterator can, if requested by the child component,
 * render a {@link Divider}.
 *
 * @param {Object[]} children               react children, or data, based on whatever event handler can handle
 * @param {Function} acl                    event handler fn
 * @param {ReactNode} Template              the react component to render for all children
 * @param {Object[]} options                additional options
 * @param {ReactNode} [options.hoc]         HOC component to use
 * @param {string} [options.hocProp]        prop in the incoming options that the hoc will use
 * @param {boolean} [options.withDivider]   whether the iterator can render a divider if specified
 *
 * @returns {*}
 * @private
 */
const _iterate = (children, { acl, Template: ParentTemplate, ...options }) => children.map((child) => {
  const [aclProps] = useAcl(acl, child)

  // Give each child the option to specify its own Template.
  const Template = child.Template || ParentTemplate
  // shallow copying child, so that we can rewrite its props
  const { ...item } = child

  const { hoc, hocProp, withDivider, ...restOptions } = options

  /**
   * passing aclProps to child. In the config this would be:
   *   {
   *     config: '...',
   *     acl: { ... },
   *     aclProps: {
   *       hidden: false,   // <--
   *       disabled: true,  // <--
   *     },
   *     options: {
   *        ...
   *     },
   *   },
   */
  if (item.props) {
    // `item` can be two different things, either a react component (like when using it inside `Section`),
    // in which case add the props returned by `useAcl` to its props
    item.props = { ...item.props, ...aclProps }
  } else {
    /**
     * Or it can be just a config (like when using it with `TabBar`, `SideBar`, `ToolBar`),
     * in which case we need to add the aclProps to item directly, if we don't do this, we'll add a `props` prop
     * to the child, the component that should render the item will then receive
     *
     * ```
     * { props // aclProps, ...rest} = props
     * ```
     *
     * if we just want to hide the item in case the acl check failed, this is no problem, because we are already
     * not returining the child from this component in that case. However, if we want to only disable the item, but
     * still show it, this will fail because the `disabled` prop will be hidden in `props.props.disabled` instead of
     * `props.disabled`
     */
    Object.keys(aclProps).forEach((aclProp) => { item[aclProp] = aclProps[aclProp] })
  }

  const isHidden = item?.props?.hidden || item?.hidden

  const templateOptions = {
    key: item.key,
    child: item,
    options: {
      ...restOptions,
      ...hocProp && {
        [hocProp]: item.options?.[hocProp],
      },
    },
  }

  const HOC = hoc && item?.options?.[hocProp]
    ? hoc(Template)
    : null

  const dividerComponent = withDivider && item.options?.divider
    ? <Divider key={item.key} />
    : null

  return !isHidden
    ? (HOC && <HOC {...templateOptions} />) || dividerComponent || <Template {...templateOptions} />
    : null
}).filter(child => !!child) // filter out hidden items

/**
 * Iterates over children, uses provided acl event handlers on each entry
 *
 * @param {Object[]} children react children, or data, based on whatever event handler can handle
 * @param {Function} acl      event handler fn
 * @param {Object[]} options  additional options
 */
const useACLIterator = (children, { acl, ...options }) => _iterate(children, { acl, ...options })

export default useACLIterator

/* global G */
import { modelComposition } from 'trait/composition'
import { pipe } from 'lib/util'
import { withGetterSetter } from 'trait/with'
import { usesGetterSetter } from 'trait/uses'
import toRef from 'model/_shared/transformer/toRef'
import fromSingleRef from 'model/_shared/decorator/fromSingleRef'
import toToBeValidated from 'model/_shared/transformer/toToBeValidated'
import toMaintainedBy from 'model/serviceItem/transformer/toMaintainedBy'
import toStatus from 'model/_shared/transformer/toStatus'
import fromMultipleRef from 'model/_shared/decorator/fromMultipleRef'
import toBoolean from 'model/_shared/transformer/toBoolean'
import validator from './validator'

const descriptor = 'model::serviceItem'

export default pipe(
  modelComposition(descriptor),
  withGetterSetter(G.VALIDATOR),
  usesGetterSetter(G.VALIDATOR, validator),
  usesGetterSetter(G.TRANSFORMER, {
    equipment: toRef('equipment'),
    product: toRef('product'),
    installedAt: toRef('installedAt'),
    serviceBy: toRef('serviceBy'),
    maintainedBy: toMaintainedBy('maintainedBy'),
    toBeValidated: toToBeValidated('toBeValidated'),
    duplicateOf: toRef('duplicateOf'),
    soldTo: toRef('soldTo'),
    status: toStatus('status'),
    notConfirmed: toBoolean('notConfirmed', true),
  }),
  withGetterSetter(G.DECORATOR),
  usesGetterSetter(G.DECORATOR, {
    equipment: fromSingleRef('equipment', 'equipment'),
    product: fromSingleRef('product', 'product'),
    soldTo: fromSingleRef('soldTo', 'soldTo'),
    maintainedBy: fromMultipleRef('maintainedBy', 'maintainedBy'),
    installedAt: fromSingleRef('installedAt', 'installedAt'),
    serviceBy: fromSingleRef('serviceBy', 'serviceBy'),
  }),
)

import noop from '@gaia/util/noop/action'
import equipment from 'app/equipmentInformation/action/equipment'
import equipmentDetails from 'app/equipmentInformation/action/equipmentDetails'
import part from 'app/equipmentInformation/action/part'
import results from 'app/equipmentInformation/action/results'
import documentation from 'app/equipmentInformation/action/documentation'
import document from 'app/equipmentInformation/action/document'
import equipmentDocumentation from 'app/equipmentInformation/action/equipmentDocumentation'
import equipmentErrorTrees from 'app/equipmentInformation/action/equipmentErrorTrees'
import equipmentBulletin from 'app/equipmentInformation/action/equipmentBulletin'
import serviceReference from 'app/equipmentInformation/action/serviceReference'
import attachmentWithFeedback from 'app/equipmentInformation/action/attachmentWithFeeback'
import electricDiagram from 'app/equipmentInformation/action/electricDiagram'
import attachmentTreeWithFeedback from 'app/equipmentInformation/action/attachmentTreeWithFeeback'
import submitFeedback from 'app/_shared/action/feedback/submit'
import successFeedback from 'app/_shared/action/feedback/success'
import submitFavorite from 'app/_shared/action/favorite/submit'
import successFavorite from 'app/_shared/action/favorite/success'

export default {
  index: noop,
  document,
  equipment,
  equipmentDetails,
  part,
  explosionDrawing: noop,
  results,
  soon: noop,
  pdf: noop,
  attachmentWithFeedback,
  attachmentTreeWithFeedback,
  submitFeedback,
  successFeedback,
  submitFavorite,
  successFavorite,
  electricDiagram,
  documentation,
  drilldown: noop,
  equipmentDocumentation,
  equipmentErrorTrees,
  equipmentBulletin,
  serviceReference,
}

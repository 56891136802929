/* eslint-disable no-unused-vars */
/* global G */
import { asyncPipeSpread } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import map from 'lib/sequence/model/api/map'
import { hidden, hide, show } from 'lib/sequence/component/state/hidden'
import { setPasswordInfo } from 'app/optionManagement/action/create'
import { set } from 'lib/sequence/component/state/value'

/**
 * Maps the current model to the view's info component.
 *
 * @param {Gaia.AppModule.Spec} module  current module
 * @return {function(*, ...[*]): Promise<*[]>}
 */
const mapCodeInfo = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { info } = components

  map(model)(info)

  return [components, ...args]
}

/**
 * Toggles the display of the 'Get password' button so that it's shown only if the current model
 * doesn't have a password set.
 *
 * @param {Gaia.AppModule.Spec} module  current module
 * @return {function(*, ...[*]): Promise<*[]>}
 */
const displayGetPasswordButton = module => async (components, ...args) => {
  const model = module[G.MODEL]
  const { password } = model[G.CHILDREN]
  const { component } = components
  const actionsRoot = component[G.ACTIONS][0]

  password[G.CACHE]
    ? hide(actionsRoot)
    : show(actionsRoot)

  return [components, ...args]
}

/**
 * Displays the password section by showing the password of the current model, if it has any, or
 * information about the last attempt to obtain it.
 *
 * @param {Gaia.AppModule.Spec} module  current module
 * @return {function(*, ...[*]): Promise<*[]>}
 */
export const displayPasswordSection = module => async (components, ...args) => {
  const intl = module[G.ADAPTER][G.INTL]
  const { response, password } = module[G.MODEL][G.CHILDREN]
  const { passwordInfo, noPasswordInfo, responseText } = components

  password[G.CACHE]
    ? show(passwordInfo) && hide(noPasswordInfo)
    : show(noPasswordInfo) && hide(passwordInfo)

  if (!hidden(noPasswordInfo) && response[G.CACHE]) {
    const { dialogMessageKey, dialogMessage = '' } = response[G.CACHE]
    const dialogMessageTextKey = `${dialogMessageKey}.text`
    const { messageKey: _key = dialogMessageTextKey, message = dialogMessage } = response[G.CACHE]

    if (_key) {
      const messageText = await intl._t(_key, {
        _key,
        ns: 'optionManagement',
        defaultValue: message,
      })
      set(responseText, messageText)
    }
  }

  return args
}

/**
 * OptionManagement detail action
 *
 * @param {Gaia.AppModule.Spec} module  current module
 */
export default module => component => async (...args) => asyncPipeSpread(
  mapCodeInfo(module),
  displayGetPasswordButton(module),
  setPasswordInfo(module),
  displayPasswordSection(module),
)(find(component), ...args)

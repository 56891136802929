/* global G */
import seqRead from '@gaia/sequence/model/api/read'

const descriptor = Symbol('hook::model::read').toString()

/**
 * Model Read Hook.
 *
 * Executes sequence read, which in turn maps payload to provided by the passed component.
 *
 * It attempts to obtain a local draft in the first place.
 *
 * @param {Gaia.AppModule.Spec} obj current module
 * @return {function(...[*]): Promise<*[]>}
 */
const modelRead = obj => async (...args) => {
  const { drafts } = obj[G.ADAPTER][G.PERSISTENCE]
  const model = obj[G.MODEL]
  try {
    const id = model[G.STATE][G.REF]
    const draft = await drafts.get(id)
    if (draft) {
      model[G.CACHE] = draft.data
    }
    await seqRead(model)(obj[G.STATE][G.ACTION][G.COMPONENT])
  } catch (e) {
    throw Error(`${obj._name} ${descriptor} - ${e.message}`)
  }
  return args
}

export default modelRead

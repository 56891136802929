/* eslint-disable arrow-body-style,no-unused-expressions */
import { curry } from 'lib/util'

const DATABASE_NAME = 'gaia'
const DATABASE_VERSION = 1
const READ_MODE = true
const WRITE_MODE = false

let upgrade = () => Promise.resolve()

/**
 * Describes a store by specifying its name, the paths to the attributes (i.e. keys) that define the
 * key of a record and the attributes used to create the store's indexes.
 *
 * @typedef {Readonly<Object>} StoreDescriptor
 * @property {string} name              store identifier
 * @property {string|string[]} keyPath  the object's paths to its key/s
 * @property {string[][]} [indexes]     pairs name, key of store indexes to be created
 */

/**
 * Converts a `method` of {@link IDBObjectStore} by wrapping its resulting {@link IDBRequest} with a
 * promise.
 *
 * @param {function(any)} method
 * @return {function(...[*]): Promise<unknown>}
 */
const promisify = method => (...params) => new Promise((resolve, reject) => {
  const request = method(...params)
  request.onsuccess = () => resolve(request.result)
  request.oncomplete = () => resolve(request.result)
  request.onerror = reject
})

/**
 * Represents a specific kind of object that can be persisted in a {@link Store}.
 *
 * @typedef {Object} Entity
 * @typedef {number|string|any[]} EntityKey
 */

/**
 * Offers methods to interact with a specific store (table), used to persist a specific kind of
 * item. Intended to abstract an underlying persistence system.
 *
 * @interface
 * @typedef {Object<Entity>} Store
 * @property {string} name
 * @property {function(EntityKey): Promise<Entity>} get
 * @property {function(Object|null): Promise<Entity[]>} getAll
 * @property {function(Entity): Promise<any>} put
 * @property {function(EntityKey): Promise<any>} remove
 * @property {function(): Promise<any>} removeAll
 */

/**
 * Wraps `objectStore` with an object that complies with the {@link Store} interface.
 *
 * @param {IDBObjectStore} objectStore
 * @returns {Store}
 */
const adapt = async objectStore => ({
  name: objectStore.name,
  get: promisify(objectStore.get.bind(objectStore)),
  getAll: (filter) => {
    const names = Object.keys(filter)
    const values = Object.values(filter)
    const keyRange = IDBKeyRange.only(values)
    const index = objectStore.index(names.join(', '))
    return promisify(index.openCursor.bind(index))(IDBKeyRange.only(keyRange))
  },
  put: promisify(objectStore.put.bind(objectStore)),
  remove: promisify(objectStore.delete.bind(objectStore)),
  removeAll: promisify(objectStore.clear.bind(objectStore)),
})

/**
 * Uses {@link IDBObjectStore} to connect to the database with name `databaseName`, starts a
 * transaction and returns a single {@link Store} object, intended to manage the store with name
 * `storeName`.
 *
 * @param {StoreDescriptor} descriptor  target object store's descriptor
 * @param {boolean} [readMode]          whether to perform a readonly transaction (to better handle
 *                                      concurrent accesses)
 * @returns {Promise<Store>} the manager object
 */
const connect = (descriptor, readMode) => new Promise((resolve, reject) => {
  const storeName = descriptor.name
  const mode = readMode ? 'readonly' : 'readwrite'
  const openRequest = indexedDB.open(DATABASE_NAME, DATABASE_VERSION)
  openRequest.onerror = reject
  openRequest.onsuccess = () => {
    const database = openRequest.result
    const transaction = database.transaction([storeName], mode)
    const objectStore = transaction.objectStore(storeName)
    resolve(adapt(objectStore))
    database.close()
  }
})

/**
 * Initializes an {@link indexedDB} database according to `storeDescriptor`.
 *
 * @param {StoreDescriptor} storeDescriptor descriptor of the store to initialize
 * @returns {function(): Promise<void>}
 */
const init = storeDescriptor => async () => {
  const openRequest = indexedDB.open(DATABASE_NAME, DATABASE_VERSION)
  const previousUpgrade = upgrade

  upgrade = async (database) => {
    const { name: storeName, keyPath, indexes } = storeDescriptor
    const objectStore = await database.createObjectStore(storeName, { keyPath })
    await Promise.all(indexes?.map(async ([name, key]) => await objectStore.createIndex(name, key)))
    await previousUpgrade(database)
  }

  openRequest.onupgradeneeded = async event => await upgrade(event.target.result)
  openRequest.onerror = error => console.error('Error during persistence initialization', error)
}

/**
 * Attempts to obtain a persisted {@link Entity} from the current database.
 *
 * @param {StoreDescriptor} descriptor descriptor of the store where the item is to be obtained from
 * @param {EntityKey} key              identifier of the {@link Entity} to obtain
 * @returns {Promise<Entity>} the obtained {@link Entity}
 */
const get = async (descriptor, key) => {
  const store = await connect(descriptor, READ_MODE)
  return await store.get(key)
}

/**
 * Attempts to obtain a list of persisted {@link Entity} objects from the store at the database with
 * name `databaseName`.
 *
 * @param {StoreDescriptor} descriptor descriptor of the store where the item is to be obtained from
 * @param {Object} filter              an object defining a property with a value both of which the
 *                                     returned items must have (e.g. `{ type: 'person' }`)
 * @returns {Promise<Entity>}
 */
const getAll = async (descriptor, filter) => {
  const store = await connect(descriptor, READ_MODE)
  return await store.getAll(filter)
}

/**
 * Attempts to persist `item` in the current database, overwriting any records identified by
 * the same key in the process.
 *
 * @param {StoreDescriptor} descriptor  descriptor of the store where the item is to be persisted
 * @param {Entity} item                 the item to persist
 * @returns {function(Object): Promise<any>}  the identifier of the new persisted item
 */
const put = async (descriptor, item) => {
  const store = await connect(descriptor, WRITE_MODE)
  return await store.put(item)
}

/**
 * Attempts to delete a persisted {@link Entity} by its `key`.
 *
 * @param {StoreDescriptor} descriptor  descriptor of the store where the item is to be deleted from
 * @param {EntityKey} key               the identifier of the {@link Entity} to delete
 * @returns {Promise<any>}
 */
const remove = async (descriptor, key) => {
  const store = await connect(descriptor, WRITE_MODE)
  return await store.remove(key)
}

/**
 * Attempts to delete all persisted {@link Entity} objects.
 *
 * @param {StoreDescriptor} descriptor  descriptor of the store where the item is to be deleted from
 * @returns {function(): Promise<*>}
 */
const removeAll = descriptor => async () => {
  const store = await connect(descriptor, WRITE_MODE)
  return await store.removeAll()
}

/**
 * @type {DataServiceFactory}
 */
export default storeDescriptor => ({
  init: init(storeDescriptor),
  get: curry(get)(storeDescriptor),
  getAll: curry(getAll)(storeDescriptor),
  put: curry(put)(storeDescriptor),
  remove: curry(remove)(storeDescriptor),
  removeAll: removeAll(storeDescriptor),
})

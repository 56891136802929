/* eslint-disable symbol-description */

const _Symbol = description => Symbol(description)

/**
 * G - Gaia Library Static Definitions.
 *
 * @type {Readonly<{
 *  INTL: symbol,
 *  API: symbol,
 *  CONFIGURATION: symbol,
 *  VIEW: symbol,
 *  MODEL: symbol,
 *  CHILDREN: symbol,
 *  ACTIONS: symbol,
 *  TEMPLATE: symbol,
 *  VALIDATOR: symbol,
 *  UI: symbol,
 *  REF: symbol,
 *  COMPONENT: symbol,
 *  FN: symbol,
 *  EVENTS: symbol,
 *  APP: symbol,
 *  HOOKS: symbol,
 *  SESSION: symbol,
 *  SETTINGS: symbol,
 *  PROVIDER: symbol,
 *  STATE: symbol,
 *  ADAPTER: symbol,
 *  ROUTER: symbol,
 *  DATA: symbol,
 *  DECORATOR: symbol,
 *  PLUGIN: symbol,
 *  ERROR: symbol,
 *  HTTP: symbol,
 *  CACHE: symbol,
 *  PROPS: symbol,
 *  GROUP: symbol,
 *  FILTER: symbol,
 *  PUBSUB: symbol,
 *  STORAGE: symbol,
 *  PERSISTENCE: symbol,
 *  PARENT: symbol,
 *  ONLINE: symbol,
 *  LANGUAGE: symbol,
 *  ROUTE: symbol,
 *  CONTEXT: symbol,
 *  MODULE: symbol,
 *  ACTION: symbol,
 *  CURRENT: symbol,
 *  NEXT: symbol,
 *  PREV: symbol,
 *  ORIGIN: symbol,
 *  USER: symbol,
 *  LIST: symbol,
 *  META: symbol,
 *  LIMIT: symbol,
 *  OFFSET: symbol,
 *  PAGE: symbol,
 *  KEYS: symbol,
 *  KEY: symbol,
 *  TRANSFORMER: symbol,
 *  ATTACHMENT: symbol,
 *  MESSAGE: symbol,
 *  NOTE: symbol,
 *  NOTIFICATION: symbol,
 *  CART: symbol,
 *  MODAL: symbol,
 *  DRAWER: symbol,
 *  FRAME: symbol,
 *  MENU: symbol,
 *  CREATE: symbol,
 *  READ: symbol,
 *  UPDATE: symbol,
 *  DELETE: symbol,
 *  DRAFT: symbol,
 *  ACTIVATE: symbol,
 *  ADD: symbol,
 *  REMOVE: symbol,
 *  APPLY: symbol,
 *  UNDO: symbol,
 *  INIT: symbol,
 *  DESTROY: symbol,
 *  DONE: symbol,
 *  ACL: symbol,
 *  BULK: symbol,
 *  SET: symbol,
 *  CHANGE: symbol,
 *  LOAD: symbol,
 * INSERT: symbol,
 * CLEAN: symbol,
 * }>}
 */
const G = Object.freeze({
  APP: _Symbol('app'),
  PROVIDER: _Symbol('provider'),
  EVENTS: _Symbol('events'),
  API: _Symbol('api'),
  CONFIGURATION: _Symbol('configuration'),
  MODULE: _Symbol('module'),
  ACTION: _Symbol('action'),
  HOOKS: _Symbol('hooks'),
  MODEL: _Symbol('model'),
  VIEW: _Symbol('view'),
  COMPONENT: _Symbol('component'),
  CHILDREN: _Symbol('children'),
  ACTIONS: _Symbol('actions'),
  TEMPLATE: _Symbol('template'),

  FN: _Symbol('fn'),
  SESSION: _Symbol('session'),
  ERROR: _Symbol('error'),
  GROUP: _Symbol('group'),
  FILTER: _Symbol('filter'),
  PARENT: _Symbol('parent'),
  ONLINE: _Symbol('online'),
  LANGUAGE: _Symbol('language'),
  ROUTE: _Symbol('route'),
  CONTEXT: _Symbol('context'),
  CURRENT: _Symbol('current'),
  NEXT: _Symbol('next'),
  PREV: _Symbol('prev'),
  ORIGIN: _Symbol('origin'),
  USER: _Symbol('user'),

  STATE: _Symbol('state'),
  PROPS: _Symbol('props'),
  CACHE: _Symbol('cache'),
  DATA: _Symbol('data'),
  VALIDATOR: _Symbol('validator'),
  DECORATOR: _Symbol('decorator'),
  TRANSFORMER: _Symbol('transformer'),

  LIST: _Symbol('list'),
  META: _Symbol('meta'),
  LIMIT: _Symbol('limit'),
  OFFSET: _Symbol('offset'),
  PAGE: _Symbol('page'),

  KEYS: _Symbol('keys'),
  KEY: _Symbol('key'),
  REF: _Symbol('ref'),

  ADAPTER: _Symbol('adapter'),
  UI: _Symbol('ui'),
  ROUTER: _Symbol('router'),
  HTTP: _Symbol('http'),
  INTL: _Symbol('intl'),
  ATTACHMENT: _Symbol('attachment'),
  MESSAGE: _Symbol('message'),
  NOTE: _Symbol('note'),
  NOTIFICATION: _Symbol('notification'),
  CART: _Symbol('cart'),
  PLUGIN: _Symbol('plugin'),
  SETTINGS: _Symbol('settings'),
  PUBSUB: _Symbol('pubsub'),
  STORAGE: _Symbol('storage'),
  PERSISTENCE: _Symbol('persistence'),

  MODAL: _Symbol('modal'),
  DRAWER: _Symbol('drawer'),
  FRAME: _Symbol('frame'),
  MENU: _Symbol('menu'),
  SEARCH: _Symbol('search'),

  CREATE: _Symbol('create'),
  READ: _Symbol('read'),
  UPDATE: _Symbol('update'),
  DELETE: _Symbol('delete'),
  DRAFT: _Symbol('draft'),
  ACTIVATE: _Symbol('activate'),
  ADD: _Symbol('add'),
  REMOVE: _Symbol('remove'),
  APPLY: _Symbol('apply'),
  UNDO: _Symbol('undo'),
  INIT: _Symbol('init'),
  DESTROY: _Symbol('destroy'),
  DONE: _Symbol('done'),
  ACL: _Symbol('acl'),
  BULK: _Symbol('bulk'),
  SET: _Symbol('set'),
  CHANGE: _Symbol('change'),
  INSERT: _Symbol('insert'),
  CLEAN: _Symbol('clean'),
  LOAD: _Symbol('load'),
})

module.exports = G

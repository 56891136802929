/* eslint-disable implicit-arrow-linebreak,no-param-reassign,arrow-body-style */
import { Suspense, useCallback, useEffect, useRef, useState, useTransition } from 'react'
import { Grid, List, ListItem, ListItemButton } from '@mui/material'
import { def, isArr, PlatformEvent, repeat } from 'lib/util'
import { preload, useMemoRef, usePagination, useSockets } from '@platform/react/hook'
import NoDataHOC from '@platform/react/hoc/nodata'
import ErrorBoundary from 'ui/Error'
import SubHeader from 'ui/Element/List/Header/Sub'
import Header from 'ui/Element/List/Header/Main'

const sx = {
  root: {
    position: 'relative',
    backgroundColor: 'inherit',
    paddingTop: 0,
    paddingBottom: 0,
    maxWidth: '100%',
    overflowY: 'auto',
    alignSelf: 'center',
    flexBasis: '100%',
    alignContent: 'baseline',
    width: '100%',
  },
  serial: theme => ({
    color: theme.palette.text.disabled,
  }),
  subHeader: theme => ({
    fontSize: '1rem',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.main,
    borderStyle: 'solid',
    borderColor: '#E6E7E8',
    borderWidth: '0 0 1px 0',
    top: 0,
    padding: 2,
    '& > *': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  }),
  placeholder: {
    width: '100%',
    marginLeft: 0,
  },
  segmentPlaceholder: () => ({
    width: '100%',
    margin: [[1, 0]],
  }),
  variant: {
    default: () => ({
      borderRadius: 2,
      padding: 2,
      width: '100%',
      margin: 0,
    }),
    border: theme => ({
      borderStyle: 'solid',
      borderColor: theme.palette.border.main,
      borderWidth: 0,
      '& + li': {
        borderTopWidth: '1px!important',
      },
    }),
    borderBottom: theme => ({
      padding: 2,
      '&:not(:last-of-type)': {
        borderStyle: 'solid',
        borderColor: theme.palette.border.main,
        borderBottomWidth: '1px!important',
      },
    }),
    borderTop: theme => ({
      padding: 2,
      '&:not(:first-of-type)': {
        borderStyle: 'solid',
        borderColor: theme.palette.border.main,
        borderTopWidth: '1px!important',
      },
    }),
    simple: () => ({
      borderRadius: 2,
      padding: 2,
      '& + li': {
        marginTop: 1,
      },
    }),
    cartPositions: theme => ({
      backgroundColor: theme.palette.background.content,
      borderRadius: '0.5rem',
      padding: 2,
      marginBottom: 2,
      // flexDirection: 'column',
      '&:hover': {
        backgroundColor: theme.palette.background.content,
      },
      '> *': {
        height: '100%',
      },
    }),
    cartSummary: theme => ({
      paddingLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 2.5,
      marginBottom: 2.5,
      borderBottom: `1px solid ${theme.palette.gray.dark}`,
      '&:hover': {
        backgroundColor: theme.palette.background.content,
      },
      '> *': {
        height: '100%',
      },
      '&:last-child': {
        marginBottom: 0,
      },
    }),
    parts: () => ({
      justifyContent: 'space-between',
      padding: [['1rem', '0.5rem']],
      cursor: 'auto',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    }),
  },
}

/**
 * Creates a memoized placeholder row element for each {@param segment} that has the placeholder
 * prop defined. Each row will be made by lazy-loading the element pointed by that property and some
 * props defined in the list's {@param placeholders} options.
 *
 * If no {@param segments} are passed or {@param unsegmented} is true, no elements will be returned.
 *
 * @param {Object} [segments]                           the list's segments configuration
 * @param {Object} [placeholders]                       an object to configure all rows
 * @param {string|number} [placeholders.segmentHeight]  the height of all placeholders
 * @param {boolean} [unsegmented]                       whether to ignore {@param segments}
 *
 * @returns {React.ReactNode[]}
 */
const SegmentsPlaceholders = useMemoRef(({ segments, placeholders = {}, unsegmented }) =>
  // filtering all items from segments that have the placeholder property
  (segments && !unsegmented ? Object.keys(segments)
    .filter(segment => segments[segment]?.placeholder)
    .map((segment, index) => {
      const View = preload(segments[segment].placeholder)
      return (
        <Grid
          item
          container
          key={index}
          component={ListItem}
          alignItems={'center'}
          sx={sx.segmentPlaceholder}
          height={placeholders.segmentHeight?.[index] || placeholders.segmentHeight}
          padding={0}
          xs={12}
        >
          <Suspense fallback={''}>
            <View
              fullWidth
              size={12}
            />
          </Suspense>
        </Grid>
      )
    }) : []),
props => [props.unsegmented])

/**
 * Creates a memoized placeholder row element. The placeholder will be made of cells according to
 * some props defined in {@param structure}, therefore, if some items from {@param structure} are
 * missing the placeholder (view path) prop, an empty array will be returned.
 *
 * @param {ItemProps[]} structure                       an object defining the props of each row
 * @param {Object} [placeholders]                       an object to configure all rows
 * @param {string|number} [placeholders.height]         the height of all placeholders
 * @param {string|number} [placeholders.iconSize]       the height and width of icon placeholders
 * @param {string|number} [placeholders.blockHeight]    the height of the block placeholders
 * @param {string|number} [placeholders.blockWidth]     the width of the block placeholders
 * @param {string|number} [placeholders.textLines]      the number of lines of text placeholders
 * @param {string|number} [placeholders.textLineWidth]  the width of the lines of text placeholders
 * @param {string|number} [placeholders.textLineHeight] the height of the lines of text placeholders
 * @param {Object} props                                additional properties
 * @param {number[]} props.size                         an array with the grid width of each cell
 * @param {string} [props.justify]                      the horizontal alignment the cells' content
 * @param {number} [props.spacing]                      the amount of space between each column
 */
const Placeholder = useMemoRef(({ structure, placeholders = {}, ...props }, ref) => (
  <Grid
    re={ref}
    item
    container
    component={ListItem}
    alignItems={'center'}
    justifyContent={props.justify || 'space-around'}
    columnSpacing={props.spacing}
    sx={{
      ...sx.variant.default,
      ...sx.placeholder,
      height: placeholders.height,
    }}
  >
    <Suspense fallback={''}>
      {structure.filter(item => item.placeholder).map(({ placeholder: view, options }, i) => {
        const View = preload(view)
        return (
          <View
            {...placeholders}
            {...options.placeholder}
            key={i}
            size={props.size?.[i] || options.xs}
          />
        )
      })}
    </Suspense>
  </Grid>
), [])

/**
 * Creates {@param count} memoized placeholder row elements. Each row will be made of cells
 * according to some props defined in {@param structure}, therefore, if some items from
 * {@param structure} are missing the placeholder (view path) prop, an empty array is returned.
 *
 * @see Placeholder
 *
 * @param {number} count                                the number of placeholder rows to create
 * @returns {React.ReactNode[]}
 */
const Placeholders = useMemoRef(({ count, structure, placeholders = {}, ...props }) => {
  // checking whether all items from structure have the placeholder property
  const missingView = structure.some(item => !item.placeholder)
  // if some element is missing it, we set the placeholder row's count to 0
  const placeholdersCount = missingView ? 0 : count
  return repeat(placeholdersCount, index => (
    <Placeholder
      key={index}
      structure={structure}
      placeholders={placeholders}
      {...props}
    />
  ))
}, props => [props.count])

/**
 * Row component used to display data from an {@param item} created with {@link createItems}.
 *
 * @param {Item} item                     a data item processed by {@link createItems}
 * @param {Object} [value]                selected value. Its row will be selected
 * @param {Boolean|string[]} [disabled]   disabled state of the list. Can either be a boolean,
 *                                        in which case the whole list will be disabled if
 *                                        it's {@code true}, or it can be a list of ids, in
 *                                        which case the corresponding rows will be disabled
 * @param {Object} variant                the row variant styles
 * @param {Function} onClick              an event handler to execute whenever the row is clicked
 * @param {Function} onChange             an event handler to execute whenever the row is clicked.
 *                                        if this handler is present, it will replace the
 *                                        {@param onClick} handler. It's intended to be used if
 *                                        the list should behave as a normal input field that has
 *                                        a value that can be changed
 * @param {Function} onContextMenu        an event handler to execute whenever the context menu is
 *                                        invoked
 * @param {string} [align]                vertical alignment of the row's cells
 * @param {string} [justify]              horizontal alignment of the row's cells
 * @param {Number} [spacing]              amount of spacing between each cell
 */
const Row = useMemoRef(({ item, value, disabled, variant, align, justify, spacing, ...props }) => {
  const { onClick, onChange, onContextMenu } = props
  const [hovered, setHovered] = useState(false)
  const handleClick = useCallback((event) => {
    const clickEvent = new PlatformEvent(event, { key: item.key, item: item.data })

    onChange
      ? onChange(clickEvent)
      : onClick?.(clickEvent)
  }, [item, onClick, onChange])

  const isDisabled = disabled === true
    || (disabled && isArr(disabled) && disabled.includes(item.key))

  return (
    <Grid
      key={item.key}
      item
      container

      disabled={isDisabled}
      aria-disabled={isDisabled}
      selected={!isDisabled && value === item.key}
      aria-selected={!isDisabled && value === item.key}

      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      component={ListItem}
      alignItems={align || 'center'}
      justifyContent={justify || 'space-around'}
      {...item.data ? {
        component: ListItemButton,
        onClick: handleClick,
        onContextMenu,
        columnSpacing: spacing,
        sx: variant,
      } : {
        padding: 0,
      }}
    >
      {item.cells.map(({ View, cellProps, sizes }, index) => (
        <Grid
          key={index}
          item
          {...sizes}
        >
          <ErrorBoundary>
            <View rowHovered={hovered} {...cellProps} /* events={transform(events)} */ />
          </ErrorBoundary>
        </Grid>
      ))}
    </Grid>
  )
}, props => [props.item, props.value, props.disabled])

/**
 * Adds the parameters and handler necessary for a cell to be subscribed.
 *
 * @param {'count'|'cell'} caption  the cell subscription type
 * @param {*} value                 the item's value
 * @param {Object} options          the cell options
 * @param {string} ref              the id of the item to subscribe to
 * @param {Object} events           an object that may contain the following event handlers
 * @param {Function} [events.count] the event handler to use when there is a {@code count} option
 * @param {Function} [events.cell]  the event handler to use when there is a {@code cell} option
 *
 * @returns {{handler: *, ref, options, value}}
 * @private
 */
const _addSubParameters = (caption, value, options, ref, events) => ({
  value,
  options,
  ref,
  handler: events[caption],
})

/**
 * Returns all objects containing all values from {@param item} (i.e. from its value and refs
 * properties) that can also be found inside {@param keys}. If {@param keys} also contains a
 * {@code count} or a {@code cell} options, adds the subscription parameters to it.
 *
 * If there are no {@param keys}, the whole value of {@param item} is returned.
 *
 * @param {Object} item             an object containing data to extract from
 * @param {Object} keys             an object with keys matching those that should be extracted from
 *                                  item
 * @param {Object} events           an object that may contain the following event handlers
 * @param {Function} [events.count] the event handler to use when there is a {@code count} option
 * @param {Function} [events.cell]  the event handler to use when there is a {@code cell} option
 *
 * @returns {{}|{value}}
 * @private
 */
export const _modelProps = (item, keys, events) => {
  const { key, value, refs } = item
  const optionKeys = Object.keys(keys)
  return optionKeys.length > 0 ? optionKeys.reduce((agg, option) => {
    agg[option] = option === 'count' || option === 'cell'
      ? _addSubParameters(option, value[option], keys[option], key, events)
      : value[option] ?? (refs && refs[option] && refs[option][0])
    return agg
  }, {}) : { value }
}

/**
 * Sorts {@param a} and {@param b} by comparing some of their attributes, which should be defined in
 * {@param attributeNames}. Both elements are sorted by the first attribute in
 * {@param attributeNames} by which they differ, meaning that they will be first compared by the
 * first attribute in the {@param attributeNames} array, and will only be compared by the next one
 * if they have the same value for the first one.
 *
 * Returns 1 if {@param a} should go first, -1 if {@param b} should go first or 0 if their
 * attributes are equal.
 *
 * Can be used to sort arrays by passing the callback to their sort function.
 *
 * @param {string[]} attributeNames
 * @param {number} depth
 * @returns {function(Object, Object): number}
 */
const attributeSort = (attributeNames, depth = 0) => (a, b) => {
  const attributeName = attributeNames[depth]

  if (attributeName) {
    const attributeA = String(a?.value?.[attributeName] ?? '')
    const attributeB = String(b?.value?.[attributeName] ?? '')

    return attributeA.localeCompare(attributeB)
      || attributeSort(attributeNames, depth + 1)(a, b)
  }
  return 0
}

/**
 * Returns a new array containing all items from {@aram data} sorted by the attributes defined in
 * {@param segments}.
 *
 * @param {Object[]} data   the data to sort
 * @param {Object} segments the segment's configuration, which should be an object whose properties
 *                          have the names of the properties of {@param data} to compare
 * @returns {Object[]}
 */
const sortData = (data, { segments }) => {
  const attributeNames = segments ? Object.keys(segments) : []

  return [...data].sort(attributeSort(attributeNames))
}

/**
 * Virtually segments the list by adding header elements between the child elements that have a
 * different value for the property with which a segment template is declared. For that, the data
 * has to be already sorted by those properties.
 *
 * If there is a {@param segmentLabel} assigned to a value of a property, it will be passed to the
 * header element.
 *
 * @example declaration of segments in config:
 *  segments: {
 *    installedAt: 'Element/List/Segment/Organisation',
 *    locationExtension: 'Element/List/Segment/Title',
 *    placement: 'Element/List/Segment/Stripe',
 *  },
 *  segmentLabels: {
 *    placement: {
 *      'somewhere': 'The device is placed somewhere',
 *    }
 *  }
 *
 * Here, 'somewhere' should be a possible value for the placement property of each child, in which
 * case the header element would obtain its value as the label.
 *
 * @param {Object[]} data         the already sorted list of elements to segment
 * @param {Object} groupValues    the current values of the attributes used to segment the items
 * @param {Object} [segments]     an object containing pairs: property name - template's path
 * @param {Object} [segmentLabel] an object containing pairs: property name - label
 * @param {boolean} [unsegmented] whether to ignore {@param segments}
 *
 * @return {Item[]}               a copy of {@param data} with the corresponding added segments
 */
const segmentItems = (data, groupValues, { segments = {}, segmentLabel = {}, unsegmented }) => {
  const attributes = Object.keys(segments)

  return !attributes.length || unsegmented ? data : data.reduce((acc, item, x) => {
    const itemData = item.data

    attributes.forEach((attribute, index) => {
      const value = itemData.value?.[attribute] || itemData.refs?.[attribute]
      const lastValue = groupValues[attribute]
      const changed = def(value) && (value[0]?.key && lastValue?.[0]
        ? value[0].key !== lastValue[0].key
        : value !== lastValue)

      if (changed) {
        // clearing subordinate attribute values if the current one has changed
        Object.keys(groupValues)
          .slice(index)
          .forEach(key => delete groupValues[key])
        // updating current attribute value
        groupValues[attribute] = value
        // gathering segment's view and props
        const view = segments[attribute]?.view || segments[attribute]
        const View = preload(view)
        const key = `segment-${attribute}-${x}`
        const labels = segmentLabel[attribute]
        const cellProps = { value, labels }
        const sizes = { xs: 12 }
        const cell = { View, cellProps, sizes }
        const cells = [cell]
        const segment = { key, cells }

        acc.push(segment)
      }
    })
    acc.push(item)
    return acc
  }, [])
}

/**
 * Creates the items to be displayed as rows by the list. For that, it maps each object inside
 * {@param data} to a group of cell elements according to {@param props.structure}.
 *
 * @typedef {Object} Item
 * @property {string} key
 * @property {Cell[]} cells
 * @property {Object[]} [data]
 *
 * @param {Object[]} data               data to display as list of cell groups
 * @param {Object} props                additional properties
 * @param {ItemProps[]} props.structure view and props of each cell
 * @param {number[]} props.size         grid width of each cell
 * @param {Object} props.events         event handlers available for the cells
 * @returns {Item[]}                    the list of mapped data-structure items
 */
const createItems = (data, { structure, size, events }) => data.map((item) => {
  /**
   * @typedef {Object} Cell
   * @property {React.ReactNode} View
   * @property {Object} cellProps
   * @property {*} cellProps.value
   * @property {Object} cellProps.labels
   * @property {Object} sizes
   * @property {number} sizes.xs
   * @type {Cell[]}
   */
  const cells = item.value.error || structure.map(({ view, options }, cellIndex) => {
    const { keys = {}, xs, sm, md, lg, xl, ...rest } = options || {}
    const modelProps = _modelProps(item, keys, events)
    const View = preload(view)
    const cellProps = { ...modelProps, ...rest, events, id: item.key }
    const sizes = { xs: size?.[cellIndex] || xs, sm, md, lg, xl }

    return { View, cellProps, sizes }
  })

  return { key: item.key, data: item, cells }
})

/**
 * StructuredList component
 *
 * Makes use of Material UI's {@link List} components to display the information obtained by calling
 * {@param props.events.onOpen} as {@link ListItem} elements. The {@param props.structure} can
 * contain {@link ItemProps} objects to be loaded and used as cells of each item.
 *
 * If calling {@param props.events.onOpen} returns a page, the component will place an observer at
 * the bottom of the list to trigger the next page load.
 *
 * If {@param props.events.list} and {@param props.events.onPublication} are defined, the
 * {@link useSockets} hook will use {@param props.events.list} and {@param props.documentType} to
 * subscribe to its channel.
 *
 * As there could be found no proper explanation about how to handle data using the {@link Suspense}
 * functionality, which doesn't work anymore as expected, this component implements its own suspense
 * mechanism by triggering the display of placeholders when the loading or the pending states are
 * true.
 *
 * @typedef {Object} ItemProps
 * @property {string} view
 * @property {string} placeholder
 * @property {Object} options
 * @property {Object} options.keys
 * @property {Object} options.labels
 * @property {Object} options.placeholder
 *
 * @param {React.Ref} forwardedRef              a reference to the root element
 * @param {Object} props                        additional component's properties
 * @param {Object[]} [props.data]               initial data to be displayed by the list
 * @param {string} [props.page]                 hash of the next page to be obtained by the list
 * @param {Object} [props.value]                selected value. Its row will be selected
 * @param {Boolean|string[]} [props.disabled]   disabled state of the list. Can either be a boolean,
 *                                              in which case the whole list will be disabled if
 *                                              it's {@code true}, or it can be alist of ids, in
 *                                              which case the corresponding rows will be disabled
 * @param {Function} [props.iterator]           function used for iterating over the structure
 * @param {ItemProps[]} props.structure         an array of objects defining view and props of each
 *                                              cell
 * @param {number} props.limit                  how many items to fetch on each request
 * @param {string} [props.placeholders]         fallback to display for suspended children
 * @param {Object} props.events                 an object containing the component's event handlers
 * @param {Function} props.events.onOpen        a function to call to obtain the items to display
 * @param {Function} props.events.onPage        called whenever the list obtains new data
 * @param {Function} [props.events.list]        used to obtain the list's channel id
 * @param {Object} [props.events.onPublication]
 * @param {string} [props.documentType]         the type of document displayed by the list
 * @param {string[]} [props.label]              an array of labels to be used as column headers
 * @param {string} [props.header]               the path to a header element to be loaded on runtime
 * @param {string} [props.headerLabel]          a label to be passed to the header element
 * @param {number} [props.variant]              the appearance variant to be used by the component
 * @param {string} [props.justify]              the horizontal alignment of all cells' content
 * @param {number} [props.spacing]              the amount of space between each column
 * @param {number[]} [props.size]               an array defining the grid width of each column
 * @param {Object} [props.segments]             attribute names mapped to view paths
 *                                              {@see segmentItems}
 * @param {Object} [props.segmentLabel]         attribute names mapped to labels {@see segmentItems}
 * @param {boolean} [props.unsegmented]         whether to ignore {@param props.segment}
 * @param {JSX.Element} [props.noData]          a component to be displayed when there is no data
 * @param {boolean} [props.autoHide]            whether the component must be hidden if there is no
 *                                              data
 * @param {Object} [props.update]               whether to force a rerender of the list
 * @param {Object} [props.reload]               whether to force a reload of the list's elements
 *
 * @returns {JSX.Element}
 * @constructor
 */
const StructuredList = ({ forwardedRef, ...props }) => {
  const { data: startData, page: nextPage, unsegmented } = props
  const { children, events, action = true, value = null, disabled = false } = props
  const { onOpen, onClick, onChange = null, onPage, onContextMenu, list, onPublication } = events

  const [, startTransition] = useTransition()
  const [currentPage, setCurrentPage] = useState(null)
  const [data, setData] = useState([]) // raw data obtained from the server
  const [items, setItems] = useState([]) // modified data (to be easily displayed) and segments
  const [updateKey, forceUpdate] = useState(0)
  const [loading, setLoading] = useState(true)
  const [firstLoad, setFirstLoad] = useState(true)
  const [connect] = useState(!!list && !!onPublication)
  const groupValuesRef = useRef({})

  const variant = sx.variant[props.variant] || sx.variant.default
  const createItemsFromData = props.iterator || createItems

  const pagination = usePagination(onOpen, startData, nextPage, currentPage, setCurrentPage, [updateKey])
  const { data: totalData } = connect ? useSockets(data, props, [updateKey]) : { data }
  const { data: pageData, page, pending, observer } = pagination

  const hasData = totalData.length > 0 || pageData?.length > 0 || data?.length > 0

  useEffect(() => {
    if (props.update) {
      startTransition(() => {
        // Scenario: Both {@code startData} and {@code update} are set. This means we want
        // to rerender the list with OUR new data, but don't do this on first load, because
        // on first load, usePagination will be executed and return pageData as well, this
        // will cause duplicate key errors
        if (startData && !firstLoad) {
          const newItems = createItemsFromData(startData, props)
          const processedItems = segmentItems(newItems, groupValuesRef.current, props)
          setData(startData)
          setItems(processedItems)
        } else {
          setItems(items.map(item => ({ ...item })))
        }
      })
    }
  }, [props.update])

  useEffect(() => {
    if (props.reload && !firstLoad) {
      startTransition(() => {
        setCurrentPage(null)
        forceUpdate(n => n + 1)
        setData([])
        setItems([])
      })
    }
  }, [props.reload])

  useEffect(() => {
    if (data !== totalData) {
      startTransition(() => {
        groupValuesRef.current = {}
        const sortedData = sortData(totalData, props)
        const newItems = createItemsFromData(sortedData, props)
        const processedItems = segmentItems(newItems, groupValuesRef.current, props)
        setData(sortedData)
        setItems(processedItems)
      })
    }
  }, [totalData])

  useEffect(() => {
    startTransition(() => setLoading(true))
    if (!pending) {
      const newItems = createItemsFromData(pageData, props)
      const processedItems = segmentItems(newItems, groupValuesRef.current, props)
      startTransition(() => {
        setData(prevData => prevData.concat(pageData))
        setItems(prevItems => prevItems.concat(processedItems))
        setLoading(false)
        setFirstLoad(false)
      })
    }
  }, [pageData, pending])

  useEffect(() => {
    onPage?.(new PlatformEvent('page', { data, page, unsegmented }))
  }, [data])

  return hasData || pending || loading ? (
    <ErrorBoundary>
      <Header
        view={props.header}
        label={props.headerLabel}
        hidden={!props.header || loading}
        sx={sx.header}
      />
      <SubHeader
        label={props.label}
        justify={props.justify}
        spacing={props.spacing}
        size={props.size}
        hidden={!props.label}
        sx={sx.subHeader}
      />
      <Grid
        key={'container'}
        ref={forwardedRef}
        container
        component={List}
        sx={sx.root}
      >
        {items.map(item => (
          <Row
            key={item.key}
            item={item}

            value={value}
            disabled={disabled}

            variant={variant}
            // if "action && ..." is used it will default to "false" if action is not defined
            onClick={action ? onClick : undefined}
            onChange={onChange}
            onContextMenu={onContextMenu?.(item.data)}
            justify={props.justify}
            align={props.align}
            spacing={props.spacing}
          />
        ))}
        {(pending || loading) && firstLoad && (
          <SegmentsPlaceholders
            {...props}
          />
        )}
        {(pending || loading) && (
          <Placeholders
            {...props}
            count={startData?.length || props.placeholders?.count || props.limit}
          />
        )}
        {!loading && observer}
      </Grid>
      {children}
    </ErrorBoundary>
  ) : !props.autoHide && props.noData
}

export default useMemoRef(NoDataHOC(StructuredList), props => [
  props.update,
  props.reload,
  props.value,
  props.disabled,
])

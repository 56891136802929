/* eslint-disable no-unused-vars,arrow-body-style */
/* global G */
import { curry, isObj } from 'lib/util'
import modelDelete from '@gaia/sequence/model/api/delete'
import modelCreate from 'lib/sequence/model/api/create'
import modelTransform from 'lib/sequence/model/transformer'
import { showBlockingDialog } from 'app/_shared/events/dialog'

/**
 * @typedef {Object|string} DeleteEventHandlerNotificationOptions
 * @property {string} key
 * @property {string} ns
 * @property {string} defaultValue
 */

/**
 * @typedef DeleteEventHandlerOptions
 * @property {boolean} [alsoDraft]                      whether related draft should also be deleted
 * @property {boolean} [onlyDraft]                      whether only related draft should be deleted
 * @property {boolean} [optimistic]                     whether to ignore errors
 * @property {Gaia.AppModule.EventHandler} [onSuccess]  handler called if operation is successful
 * @property {DialogOptions|null} [dialogOptions]       options applied to the confirmation dialog
 * @property {DeleteEventHandlerNotificationOptions} notificationOptions
 */

/**
 *
 * @param {Gaia.AppModule.Spec} obj current module
 * @param {DeleteEventHandlerNotificationOptions} message
 */
const showNotification = (obj, message) => {
  const intl = obj[G.ADAPTER][G.INTL]
  const eventBus = obj[G.ADAPTER][G.EVENTS]
  eventBus.dispatch(eventBus.type(G.DATA, G.UPDATE), {
    duration: 5000,
    message: !isObj(message) ? message : intl._t(message.key, { ...message, _key: message.key }),
  })
}

/**
 * Shows a dialog asking the user for confirmation before deleting a model.
 *
 * TODO: translations should come from `component` but our `quickActions` cannot have a _t property.
 *       They should be refactored so that we can handle them like usual `component`s, at least as
 *       far as the translation of properties is concerned.
 *
 * @param {Gaia.AppModule.Spec} module    the current module object composition
 * @param {Gaia.Component.Spec} component the object composition of the UI component that
 *                                        triggered the event
 * @param {DialogOptions} configuration   custom configuration for the confirmation dialog
 * @return {Promise<*>}
 */
const showConfirmationDialog = async (module, component, configuration = {}) => {
  return await showBlockingDialog(module, component, {
    title: {
      ns: 'common',
      key: 'dialog.deleteModel.title',
      defaultValue: 'Delete',
    },
    text: {
      ns: 'common',
      key: 'dialog.deleteModel.text',
      defaultValue: 'Are you sure you want to delete this item?',
    },
    ...configuration,
  })
}

/**
 * Creates a new {@link Draft} object from `model`.
 *
 * @param {Gaia.Model.Spec} model
 * @return {Draft}}
 */
const createDeleteDraft = (model) => {
  const { api: type } = model[G.PROPS]
  const key = model[G.STATE][G.REF]

  modelCreate(model)
  modelTransform(model)

  const data = model[G.STATE][G.DATA]

  return {
    id: key,
    revision: '0',
    remoteRevision: '0',
    data: {
      ...data,
      key,
      _id: key,
    },
    type,
    action: 'delete',
    save: true,
    submit: true,
    error: null,
  }
}

/**
 * After asking for confirmation, attempts to delete the current model from the server, empties the
 * local model and redirects to the route configured in `component`.
 *
 * @param {DeleteEventHandlerOptions} [options] custom configuration for the confirmation dialog
 * @param {Gaia.AppModule.Spec} module          the current module object composition
 * @param {Gaia.Component.Spec} component       the object composition of the UI component that
 *                                              triggered the event
 * @param {Gaia.PlatformEvent} event            an object containing information about the event
 *                                              triggered by the `component`'s element
 */
const deleteModel = async (options, module, component, event) => {
  const { alsoDraft, onlyDraft, optimistic, onSuccess } = options || {}
  const { dialogOptions, notificationOptions } = options || {}
  const { drafts } = module[G.ADAPTER][G.PERSISTENCE]
  const model = module[G.MODEL]
  const online = module[G.ADAPTER][G.SESSION][G.STATE][G.ONLINE]
  const isRemote = !!model[G.CACHE]._rev
  console.log('delete isRemote', isRemote)
  console.log('delete key', model[G.STATE][G.REF])
  let error

  if (await showConfirmationDialog(module, component, dialogOptions)) {
    if (alsoDraft || onlyDraft) {
      try {
        isRemote && !online
          ? await drafts.put(createDeleteDraft(model))
          : await drafts.delete(model[G.STATE][G.REF])
      } catch (e) {
        error = e
        console.error(e)
      }
    }

    if (!onlyDraft && isRemote && online) {
      try {
        await modelDelete(model)
      } catch (e) {
        error = e
        console.log('Error', e)
        console.error(e)
      }
    }

    if ((!error || optimistic) && onSuccess) {
      showNotification(module, notificationOptions)
      await onSuccess(module, component, event)
    }
  }
}

export default curry(deleteModel)

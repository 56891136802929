/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import search from 'app/_shared/events/search'
import destroy from 'app/_shared/events/destroy'
import dialog from 'app/_shared/events/dialog'

/**
 * Asks the server to return all items that have `source` in `ref`.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, *): Promise<*|(function(...[*]): (*))>}
 * @private
 */
const _checkSubItems = module => async (source, ref) => await search(
  null, module, module[G.STATE][G.ACTION][G.COMPONENT], {
    type: source,
    filter: { [ref]: module[G.MODEL][G.STATE][G.REF] },
  },
)

/**
 * Delete If No SubItems Event Handler
 *
 * Attempts to delete the current organisation. Checks if there are serviceitems installed at the
 * organisation as well as persons or sub-locations associated with the organisation. If so, it
 * shows an error dialog, if not, it attempts to delete it using the {@link destroy} event handler.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @returns {Promise<void>}
 */
const destroyIfNoSubItems = async (module, component, event) => {
  try {
    const subOrganisations = await _checkSubItems(module)('organisation', 'parent')
    const supportedByOrganisations = await _checkSubItems(module)('organisation', 'supportedBy')
    const subServiceItems = await _checkSubItems(module)('serviceitem', 'installedAt')
    const subPersons = await _checkSubItems(module)('person', 'organisation')

    if (
      subOrganisations?.length
        || supportedByOrganisations?.length
        || subServiceItems?.length
        || subPersons?.length
    ) {
      await dialog(module, component, {
        cancel: false,
        title: {
          ns: 'organisation',
          key: 'dialog.deleteError.title',
          defaultValue: 'Deletion error',
        },
        text: {
          ns: 'organisation',
          key: 'dialog.deleteError.text',
          defaultValue: 'Sorry, organization has related data. Please unrelate or delete devices, child locations, contact and users first. Alternatively, set organization status to obsolete or archived.',
        },
      })
    } else {
      await destroy(module, component, event)
    }
  } catch (e) {
    console.error(e)
  }
}

export default curry(destroyIfNoSubItems)

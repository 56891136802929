/* global G */
import { curry, getFirstItem } from 'lib/util'
import { add } from 'app/_shared/events/cart'

/**
 * Adds a position to the cart.
 *
 * @param {Function} callback                 callback to execute
 * @param {Gaia.AppModule.Spec} module        module composition
 * @param {Gaia.Component.Spec} component     action component
 * @param {Gaia.AppModule.EventHandler} event triggered event
 * @returns {Promise<void>}
 */
const _add = async (callback, module, component, event) => {
  const partCache = getFirstItem(module[G.MODEL][G.CHILDREN].article[G.CACHE])

  // Part could either be inserted via the event (in case of selecting one from the hotspot
  // component) or the current model cache (in case of parts detail page)
  const { node: part = null } = event?.detail || event || {}

  const key = part?.articleId || partCache?.key || null
  const name = part?.name || partCache?.value?.name || null

  const data = {
    key,
    name,
    data: {
      key,
      name,
      amount: 1,
      articleNumber: part?.orderNumber || partCache?.value?.orderNumber || null,
    },
  }

  await add(module, component, data, callback, event)
}

export default curry(_add)

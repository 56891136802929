/* eslint-disable no-new,max-len */
/* global G */
import { curry, isObj, isStr, PlatformEvent, setKey } from 'lib/util'
import { parseURL } from 'whatwg-url-without-unicode'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import search from 'app/_shared/events/search'
import { showBlockingDialog } from 'app/_shared/events/dialog'
import routeComposition from 'trait/composition/route'
import settings from '@tenant/settings'

/**
 * Shows a dialog informing the user that the scanned code is a URL and asks them whether they want
 * to open it.
 *
 * @param {Gaia.AppModule.Spec} module  current module composition object
 * @param {string} url                  scanned url
 * @return {Promise<boolean>}           whether the user wanted to open the URL
 */
const showUrlDialog = async (module, url) => await showBlockingDialog(module, null, {
  ...(url.includes('tel') && {
    title: {
      ns: 'common',
      key: 'dialog.urlScan.title.phone',
      defaultValue: 'Phone number',
    },
  }) || (url.includes('mailto') && {
    title: {
      ns: 'common',
      key: 'dialog.urlScan.title.mail',
      defaultValue: 'Email Address',
    },
  }) || {
    title: {
      ns: 'common',
      key: 'dialog.urlScan.title.website',
      defaultValue: 'Website Address',
    },
  },
  text: {
    ns: 'common',
    key: 'dialog.urlScan.textMD',
    defaultValue: 'Scanned **{{url}}**.',
    md: true,
    url: url.replace(/^(tel|mailto):/g, ''),
  },
  ok: {
    ns: 'common',
    key: 'button.open',
    defaultValue: 'Open',
  },
})

/**
 * Shows a dialog informing the user that the scanned code should be handled by the Pin Creator app
 * and asks them whether they want to open it.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @return {Promise<boolean>}           whether the user wanted to open the Pin Creator app
 */
const showPinCreatorDialog = async module => await showBlockingDialog(module, null, {
  title: {
    ns: 'common',
    key: 'dialog.pinCreatorScan.title',
    defaultValue: 'Pin Creator',
  },
  text: {
    ns: 'common',
    key: 'dialog.pinCreatorScan.textMD',
    defaultValue: 'Please, scan with **Pin Creator App**.',
    md: true,
  },
  ok: {
    ns: 'common',
    key: 'button.open',
    defaultValue: 'Open',
  },
})

/**
 * Shows a dialog informing the user that `code` cannot be recognized.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {string} code                 a non-recognizable code
 * @return {Promise<boolean>}
 */
const showUnknownCodeDialog = async (module, code) => await showBlockingDialog(module, null, {
  title: {
    ns: 'common',
    key: 'dialog.unknownCodeScan.title',
    defaultValue: 'Unknown code',
  },
  text: {
    ns: 'common',
    key: 'dialog.unknownCodeScan.textMD',
    defaultValue: 'The code **{{code}}** cannot be recognised.',
    md: true,
    code,
  },
  ok: {
    ns: 'common',
    key: 'button.copy',
    defaultValue: 'Copy',
  },
})

/**
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Object} payload              OptionManagement QR code payload
 * @return {Promise<*>}
 */
const redirectToOptionManagement = async (module, payload) => {
  const moduleState = module[G.STATE]
  console.log('payload', payload)
  moduleState[G.ROUTE] = routeComposition('optionManagement', 'generate')
  return await redirectSequence(module)({ payload })
}

/**
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {string} key                  key of the equipment to redirect to
 * @return {Promise<*>}
 */
const redirectToEquipmentDetail = async (module, key) => {
  const moduleState = module[G.STATE]
  moduleState[G.ROUTE] = routeComposition('equipmentInformation', 'part')
  setKey(key, G.REF, module[G.MODEL][G.STATE])
  return await redirectSequence(module)({ key })
}

/**
 * Attempts to obtain an article whose key contains the string passed in {@param event.detail.value}
 * and, if found, returns its complete key.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {PlatformEvent} event             information about the event that triggered this handler
 * @param {Object} event.detail             event details
 * @param {string} event.detail.value       string used to search for the ID of an article
 * @return {Promise<*>}
 */
const getArticleKey = async (module, component, event) => {
  const { value } = event.detail || {}
  const id = value.replace(/[A-Za-z|%]/g, '')

  const searchEvent = new PlatformEvent('search', { term: id, type: 'article' })
  const result = await search(null, module, component, searchEvent)

  const article = result.find(item => isStr(item.key) && item.key.endsWith(id))

  return article?.key
}

// TODO: The following payloads are used to test the functionality of the OptionManagement module in
//  the emulator, without having to actually scan QR codes. To be removed once OptionManagement is
//  stable enough.
// const payloadInvalid = '{"payload":"eyJzZXJpYWwiOiJFMDdWSzIyMTE4MDY4NjgxIiwidGltZXN0YW1wIjoiMjAyNC0wMi0wNVQxNDo0NjozMS4wMDArMDE6MDAiLCJjaGFuZ2VkT3B0aW9uIjoiU1BfT1BDX1VBX1BST0ZJTEUiLCJuZXdWYWx1ZSI6Ins3NThCNEE1Ri03MUMzLTQxOTAtOEM2Ny05MEEzQTc1OEE0MDB9Iiwib2xkVmFsdWUiOiJudWxsIn0=","signature":"96aa1573109aca72d15bddedcb2aa3a94de6d9af9c5ce4bfe6a2820846d2ee51"}'
// const payloadGasOption = '{"payload": "eyJzZXJpYWwiOiJHMjFTSjE5MTIyODAyNTUyIiwidGltZXN0YW1wIjoiMjAyNC0wOS0xMFQxMTo1MzozOC4wMDArMDA6MDAiLCJjaGFuZ2VkT3B0aW9uIjoiU1BfR0FTVFlQRSIsIm5ld1ZhbHVlIjoiQlAzIiwib2xkVmFsdWUiOiJHMjAifQ==", "signature": "1e39a280a03e5714890b809c8fb924899e6f2a39583183d6dc6312facceed5c2"}'
// const payloadIVarioHeight = '{"payload": "eyJzZXJpYWwiOiJFMTFQSzIzMTI4MDc3MDY1IiwidGltZXN0YW1wIjoiMjAyNC0wOS0xMFQxMjozOTo1MC4wMDArMDA6MDAiLCJjaGFuZ2VkT3B0aW9uIjoiU1BfT1BUX0hFSUdIVF9DVFJMIiwibmV3VmFsdWUiOiJ0cnVlIiwib2xkVmFsdWUiOiJmYWxzZSJ9", "signature": "6b901d3c803d3880427a24c0c340717e403ed392afecd666417b84eb8b78357e"}'
// const payloadIVarioDeepFry = '{"payload": "eyJzZXJpYWwiOiJFMTFQSzIzMTI4MDc3MDY1IiwidGltZXN0YW1wIjoiMjAyNC0wOS0xMFQxMjozOTozNi4wMDArMDA6MDAiLCJjaGFuZ2VkT3B0aW9uIjoiU1BfT1BUX0RFRVBGUllJTkciLCJuZXdWYWx1ZSI6ImZhbHNlIiwib2xkVmFsdWUiOiJ0cnVlIn0=", "signature": "c17119d85e098624ca3dfbaa550ad29bf2e30904495fdf4814cbf89368efd037"}'
// const payloadIVarioEnergyOPT = '{"payload": "eyJzZXJpYWwiOiJFMTFQSzIzMTI4MDc3MDY1IiwidGltZXN0YW1wIjoiMjAyNC0wOS0xMFQxMjo0MDoxNS4wMDArMDA6MDAiLCJjaGFuZ2VkT3B0aW9uIjoiU1BfT1BUX1NJQ09UUk9OSUMiLCJuZXdWYWx1ZSI6ImZhbHNlIiwib2xkVmFsdWUiOiJ0cnVlIn0=", "signature": "852da15652a9f368de0b1ed7affb6ce54ce0421a30f32dabe72e1ee3296465b4"}'
// const payloadIVarioBalancedPower = '{"payload": "eyJzZXJpYWwiOiJFMjFQSzIzMTI4MDc3MjEyIiwidGltZXN0YW1wIjoiMjAyNC0wOS0xMVQxMDowODozMi4wMDArMDA6MDAiLCJjaGFuZ2VkT3B0aW9uIjoiU1BfT1BUX0RZTkFNSUMiLCJuZXdWYWx1ZSI6IkxPVyIsIm9sZFZhbHVlIjoiRlVMTCJ9", "signature": "261b329ee0d5f332c7b04fd608ed9c17bf5bee9e420b42a037771fe8f8ecfa85"}'
// const payloadIVarioIZoneControl = '{"payload": "eyJzZXJpYWwiOiJFMjFQSzIzMTI4MDc3MjEyIiwidGltZXN0YW1wIjoiMjAyNC0wOS0xMVQxMDowNzoyNS4wMDArMDA6MDAiLCJjaGFuZ2VkT3B0aW9uIjoiU1BfT1BUX0laT05FQ09OVFJPTCIsIm5ld1ZhbHVlIjoiZmFsc2UiLCJvbGRWYWx1ZSI6InRydWUifQ==", "signature": "993547c510686a3c3a54e8fc4e870fb4638b90aba892048713492015afb097d5"}'
// const payloadIVarioOPCUA = '{"payload": "eyJzZXJpYWwiOiJFMjFQSzIzMTI4MDc3MjEyIiwidGltZXN0YW1wIjoiMjAyNC0wOS0xMVQxMDowODo0Ni4wMDArMDA6MDAiLCJjaGFuZ2VkT3B0aW9uIjoiU1BfT1BDX1VBX1BST0ZJTEUiLCJuZXdWYWx1ZSI6Ins3NThCNEE1Ri03MUMzLTQxOTAtOEM2Ny05MEEzQTc1OEE0MDB9Iiwib2xkVmFsdWUiOiJudWxsIn0=", "signature": "63dbc79d9bc7092f2d8e01a1f6287b49776b64f5a53e91c2013f44709b0cc35a"}'
// const payloadICombiOPCUA = '{"payload": "eyJzZXJpYWwiOiJFMTFTSjIzMDMzMDQ2ODYzIiwidGltZXN0YW1wIjoiMjAyNC0wOS0xMVQxMToyNjowOS4wMDArMDA6MDAiLCJjaGFuZ2VkT3B0aW9uIjoiU1BfT1BDX1VBX1BST0ZJTEUiLCJuZXdWYWx1ZSI6Ins3NThCNEE1Ri03MUMzLTQxOTAtOEM2Ny05MEEzQTc1OEE0MDB9Iiwib2xkVmFsdWUiOiJudWxsIn0=", "signature": "aab1aa55074a0b47923573883a2284b5be59f0e6124fab9808918a88002bd438"}'

/**
 * Process Scanned Code Event Handler
 *
 * Attempts to recognize a scanned code and display a dialog accordingly:
 * - If code is a URL, the dialog asks the user whether they want to open it.
 * - If code is a QR code, it attempts to parse the code string as JSON:
 *  - If code can be parsed:
 *    - If code is an object with payload and signature properties, it redirects to OpenManagement.
 *    - Otherwise, it asks the user whether they want to open the Pin Creator application.
 *  - If code cannot be parsed, it shows a dialog informing that the code cannot be recognized.
 * - If code isn't a QR code, attempts to find an article whose key contains the code string:
 *  - If an article is found, it redirects the user to it.
 *  - Otherwise, it shows a dialog informing that the code cannot be recognized.
 *
 * If the result of displaying a dialog is `true` (i.e. the user has clicked the 'ok' option), in
 * most cases, this event handler returns an object to be handled by the component that called it.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {Gaia.PlatformEvent} event        information about the event that triggered this handler
 * @param {Object} event.detail             event details
 * @param {string} event.detail.type        the type of scanned code
 * @param {string} event.detail.value       the scanned code
 * @return {Promise<Object|boolean>}
 */
const processScannedCode = async (module, component, event) => {
  const { type = 'qr', value /* = payloadGasOption */ } = event.detail || {}

  const url = parseURL(value, {})

  if (url) {
    return await showUrlDialog(module, value) && { link: value }
  }

  if (type === 'qr') {
    try {
      const valueObj = JSON.parse(value.replace(/\n/g, ''))

      return isObj(valueObj) && valueObj.payload && valueObj.signature
        ? await redirectToOptionManagement(module, valueObj) && { disable: true }
        : await showUnknownCodeDialog(module, value) && { clipboard: value }
    } catch (ignored) {}

    return await showPinCreatorDialog(module) && {
      link: settings.pinCreatorLink,
      store: {
        android: settings.pinCreatorAndroidStoreLink,
        ios: settings.pinCreatorIOSStoreLink,
      },
    }
  }

  const key = await getArticleKey(module, component, event)

  return key
    ? await redirectToEquipmentDetail(module, key) && { disable: true }
    : await showUnknownCodeDialog(module, value) && { clipboard: value }
}

export default curry(processScannedCode)

/* eslint-disable arrow-body-style */
/* global G */
import { curry } from 'lib/util'
import infinite from 'app/_shared/events/search/infinite'

/**
 * Attempts to obtain a local list of domain objects according to the configuration of the current
 * module's model.
 *
 * @param {Gaia.AppModule.Spec} module  current module
 * @return {Promise<*>}
 */
const getLocalList = async (module) => {
  const model = module[G.MODEL]
  const { api: type } = model[G.PROPS]
  const { drafts } = module[G.ADAPTER][G.PERSISTENCE]
  const localList = await drafts.getAll({ type })
  return localList.map((item) => {
    const { data, action } = item
    data.value.delete = action === 'delete' // makes `mergeLists` hide them after being considered
    data.value.draft = true // TODO: remove. Added for testing purposes
    return data
  })
}

/**
 * Attempts to obtain a remote list of domain objects, according to the `component` configuration
 * and the current module's model.
 *
 * @param {string} key                    listing type (e.g. short, verbose, etc.)
 * @param {Gaia.AppModule.Spec} module    current module
 * @param {Gaia.Component.Spec} component component that triggered the event
 * @param {Gaia.PlatformEvent} event      the event object
 * @return {Promise<*|(function(...[*]): (*))|*[]>}
 */
const getRemoteList = async (key, module, component, event) => {
  const model = module[G.MODEL]
  const httpAdapter = model[G.ADAPTER][G.HTTP]
  try {
    const options = { middleware: ({ error, ...middleware }) => Object.values(middleware) }
    const { data = [] } = await httpAdapter.withContext(options, async () => {
      return infinite(key, module, component, event)
    })
    return data.filter(item => item.key && item.key !== -1)
  } catch (e) {
    console.log('Unable to get remote list:', e)
    return []
  }
}

/**
 * Merges `localList` and `remoteList` by filtering out all items of the latter found in the former
 * and, afterward, also those (local ones) marked to be deleted.
 *
 * @param {Object[]} localList  list of domain objects obtained locally
 * @param {Object[]} remoteList list of domain objects obtained remotely
 */
const mergeLists = (localList, remoteList) => {
  const ids = []

  return localList.concat(remoteList).reduce((list, item) => {
    const { key, value: { delete: hidden } = {} } = item
    if (!ids.includes(key)) {
      ids.push(key)
      // TODO: delete drafts should be hidden, but we are temporarily displaying them for testing
      //  purposes. The comment of the following two lines should be toggled before going to
      //  production.
      // !hidden && list.push(item)
      list.push(item)
    }
    return list
  }, [])
}

/**
 * Returns a paginated list of remote and local documents.
 *
 * @extends Gaia.AppModule.EventHandler
 * @param {string} key - listing type, ie short, verbose, etc
 * @param {Gaia.AppModule.Spec} module    current module
 * @param {Gaia.Component.Spec} component component that triggered the event
 * @param {Gaia.PlatformEvent} event      event information
 */
const infiniteWithDrafts = async (key, module, component, event) => {
  const remoteList = await getRemoteList(key, module, component, event)
  const localList = await getLocalList(module)
  const data = mergeLists(localList, remoteList)
  return { data }
}

export default curry(infiniteWithDrafts)

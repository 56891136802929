/* global G */
import { curry, setKey } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import getCartPositions from 'app/cart/event/getCartPositions'

/**
 * Updates the list denoted by `key` to reflect the newest positions on next render.
 *
 * @param {string} key                        key of the list
 * @param {Gaia.AppModule.Spec} module        module composition
 * @param {Gaia.Component.Spec} component     action component
 * @param {Gaia.AppModule.EventHandler} event triggered event
 * @returns {Promise<void>}
 */
const updatePositions = async (key, module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { [key]: list } = sequenceComponentFindProxy(actionComponent)
  const cartData = await getCartPositions(module, component, event)

  setKey(cartData, 'data', list[G.STATE])
  setKey({}, 'update', list[G.STATE])
}

export default curry(updatePositions)

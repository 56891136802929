/* global G */
import session from 'app/_shared/session'
import { setData } from 'lib/sequence/model/api/set'

/**
 * Sets `submitter` and `submitDate` before submitting a new (optionManagement/scan) model.
 *
 * @param {Gaia.AppModule.Spec} module  current module
 * @private
 */
const _new = (module) => {
  const model = module[G.MODEL]
  const user = session(module).user.ref({ wrap: true })
  const submitDate = new Date().toISOString()
  setData(model, { submitter: user, submitDate })
}

/**
 * OptionManagement submit action
 *
 * @param {Gaia.AppModule.Spec} module  current module
 * @returns {function(): function(...[*]): Promise<*[]>}
 */
export default module => () => async (...args) => {
  const model = module[G.MODEL]
  const validationError = model[G.STATE][G.ERROR]
  const moduleState = module[G.STATE]
  moduleState[G.ERROR] = !!validationError

  switch (module[G.STATE][G.ACTION][G.COMPONENT]._name) {
    case module[G.ACTIONS].new._name:
      !validationError && _new(module)
      break
    default:
  }

  return args
}

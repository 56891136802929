import { hash, lowercase, now } from 'platform/adapter/persistence/helper'
import { dataService } from 'platform/adapter/persistence/service'
import descriptor from 'platform/adapter/persistence/descriptor/requests'

/**
 * A key that uniquely identifies a {@link RequestRecord}.
 *
 * @memberOf Gaia.Adapter.Persistence
 * @typedef {EntityKey} RequestRecordKey
 * @property {string} url       the request's URL
 * @property {string} method    the request's method
 * @property {Object} [payload] the request's payload, if applicable
 */

/**
 * An entity intended to store/cache network requests together with their responses.
 *
 * A RequestRecord entity is identified by the fields in its {@link RequestRecordKey}
 *
 * @memberOf Gaia.Adapter.Persistence
 * @typedef {Entity} RequestRecord
 * @property {string} url       the request's URL
 * @property {string} method    the request's method
 * @property {Object} [payload] the request's payload, if applicable
 * @property {Object} data      the response data
 * @property {string} date      the time at which the entity was created
 */

/**
 * @memberOf Gaia.Adapter.Persistence.PersistenceAPI
 * @typedef {PersistenceAPI} RequestsAPI
 * @return {RequestsAPI}
 */
export default (() => {
  const requests = dataService(descriptor)

  return Object.create({}, {
    init: {
      /**
       * Creates the requests store, if it doesn't exist.
       *
       * @return {Promise<void>}
       */
      value: async () => await requests.init(),
      iterable: true,
      enumerable: true,
    },
    get: {
      /**
       * Attempts to obtain a persisted {@link RequestRecord} from the database.
       *
       * @param {RequestRecordKey} key the identifier of the {@link RequestRecord} to obtain
       * @return {Promise<any>}        the data contents of the obtained {@link RequestRecord}
       */
      value: async (key) => {
        const { url, method, payload } = key
        const params = [url, lowercase(method), hash(payload)]
        const item = await requests.get(params)
        return item.data
      },
      iterable: true,
      enumerable: true,
    },
    put: {
      /**
       * Attempts to persist `item` in the database, overwriting any records identified by
       * the same key in the process.
       *
       * @param {RequestRecord} item  the item to persist
       * @return {Promise<any>}       the identifier of the new persisted item
       */
      value: async (item) => {
        const { url, payload, data } = item
        const method = lowercase(item.method)
        const params = { url, method, hash: hash(payload), payload, data, date: now() }
        return await requests.put(params)
      },
      iterable: true,
      enumerable: true,
    },
    delete: {
      /**
       * Attempts to delete a persisted {@link RequestRecord} by its `key`.
       *
       * @param {RequestRecordKey} key  the identifier of the {@link RequestRecord} to delete
       * @return {Promise<any>}
       */
      value: async (key) => {
        const { url, method, payload } = key
        const params = [url, lowercase(method), hash(payload)]
        return await requests.remove(params)
      },
      iterable: true,
      enumerable: true,
    },
  })
})()

import noop from '@gaia/util/noop/action'
import generate from './action/generate'
import create from './action/create'
import edit from './action/edit'
import detail from './action/detail'
import password from './action/password'
import submit from './action/submit'
import error from './action/error'

export default {
  index: noop,
  generate,
  new: create,
  edit,
  detail,
  submit,
  password,
  error,
}

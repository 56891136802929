/* global G */
import { curry, getFirstItem } from 'lib/util'
import routeComposition from 'trait/composition/route'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import { refList } from 'app/_shared/events/list'
import session from 'app/_shared/session'

/**
 * Redirect To Person Or User Event Handler
 *
 * Redirects either to `component[G.CONFIGURATION].admRoute` in case user is within the ADM context
 * and the person in question has a respective user, or `component[G.CONFIGURATION].route` in any
 * other case
 *
 * @type Gaia.AppModule.EventHandler
 */
// eslint-disable-next-line no-unused-vars
const redirectToPersonOrUser = async (module, component, event) => {
  const { detail } = event

  const isAdmContext = session(module).isContext('adm')

  let userId = null
  let targetRoute = component[G.CONFIGURATION]?.route

  if (isAdmContext) {
    // Using reflisting to get the user for the current person
    const user = await refList('default', module, component, event)
    userId = getFirstItem(user)?.key || null

    targetRoute = userId ? component[G.CONFIGURATION]?.admRoute : targetRoute
  }

  const moduleState = module[G.STATE]
  moduleState[G.ROUTE] = routeComposition(targetRoute.module, targetRoute.action)

  await redirectSequence(module)({ detail, ...userId && { key: userId } })
}

export default curry(redirectToPersonOrUser)

/* global G */
import { curry } from 'lib/util'
import { change } from 'app/_shared/events/cart'

/**
 * Removes the comment of a position in the cart.
 *
 * @param {Function} callback                 callback to execute
 * @param {Gaia.AppModule.Spec} module        module composition
 * @param {Gaia.Component.Spec} component     action component
 * @param {Gaia.AppModule.EventHandler} event triggered event
 * @returns {Promise<void>}
 */
const remove = async (callback, module, component, event) => {
  const { key: eventKey, name: eventName } = event?.detail || event || {}

  const { id: key = eventKey, name = eventName } = module[G.STATE][G.ACTION][G.STATE]

  const data = {
    key,
    name,
    data: { comment: null },
  }

  await change(module, component, data, callback, event)
}

export default curry(remove)

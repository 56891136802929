/* eslint-disable object-curly-newline */
/* global G */
import settings from '@tenant/settings'
import readWorkerBody from '@platform/worker/attachment/read'
import previewWorkerBody from '@platform/worker/attachment/preview'
import { createWorker, terminate, isRemote, getReadUrl } from '@platform/adapter/attachment/helper'

/**
 * Shows the timeout dialog with a button to cancel the current request.
 *
 * @param {Gaia.Web.Application} obj        the web platform application
 */
const showAbortDialog = (obj) => {
  const eventBus = obj[G.ADAPTER][G.EVENTS]
  const intl = obj[G.ADAPTER][G.INTL]

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: intl._t(
      'dialog.error.abort.title',
      {
        ns: 'common',
        _key: 'dialog.abort.timeout.title',
        defaultValue: 'Sorry',
      },
    ),
    text: intl._t(
      'dialog.error.abort.text',
      {
        ns: 'common',
        _key: 'dialog.error.abort.text',
        defaultValue: 'There was an error fetching the attachment. Please try again later.'
      },
    ),
    children: {
      ok: {
        key: 'ok',
        value: intl._t(
          'button.ok',
          {
            ns: 'common',
            _key: 'button.ok',
            defaultValue: 'Ok',
          },
        ),
      },
    },
    okHandler: () => { eventBus.dispatch(eventBus.type(G.LOAD, G.DONE)) },
    elevated: true,
  })
}

/**
 * MessageEvent Listener function executed on #postMessage from inside the {@link Worker}.
 *
 * @param {Object} worker                       worker instance
 * @param {string} source                       blob url of the attachment
 * @param {Gaia.Web.Application} app            the Web platform Application
 * @param {string} group                        the attachment group identifier
 * @param {Object} item                         meta data of the attachment
 * @param {boolean} persist                     whether to persist the attachment
 * @returns {((MessageEvent) => Promise<void>)} the MessageEvent listener
 */
export const onMessage = (worker, source, app, group, item, persist) => async (evt) => {
  const { blob, key = item?.name, url, ...rest } = evt.data
  const storage = app[G.ADAPTER][G.PERSISTENCE][G.API]
  const bus = app[G.ADAPTER][G.EVENTS]

  const { name } = item

  const type = blob ? G.DONE : G.READ
  const objectUrl = blob && URL.createObjectURL(blob)
  const attachmentGroup = app[G.ADAPTER][G.ATTACHMENT][G.GROUP][group]

  // First, we'll gather all remote attachments from the group
  const groupAttachments = attachmentGroup
    ? attachmentGroup[G.DATA].concat(attachmentGroup[G.CACHE])
    : null

  // Then, we'll check if the attachmen we are dealing with is part of it
  const groupAttachment = groupAttachments?.length
    ? groupAttachments.find(x => x.key === key)
    : false
  const attachment = groupAttachment || [{ key }]

  const attachmentData = { ...rest, key, value: item, url: objectUrl }

  attachment.url = objectUrl // adding blob url to the attachment
  bus.dispatch(bus.type(G.ATTACHMENT, type, key), attachmentData)

  const fetchedFromRemote = isRemote({ key, value: item })

  /**
   * The following conditions have to be met:
   * - Attachment was fetched from remote
   * - Attachment has been fully fetched (`blob` is present)
   * - A attachment group exists
   * - The fetched attachment is not part of it
   *
   * If all conditions are met, we'll add the attachment to the groups'
   * remote attachment list, and emit `G.ATTACHMENT, G.DONE, group` with
   * a concatenation of both the local and remote attachments
   */
  if (fetchedFromRemote && blob && attachmentGroup && !groupAttachment) {
    attachmentGroup[G.CACHE] = groupAttachments.concat(attachmentData)
    bus.dispatch(bus.type(G.ATTACHMENT, G.DONE, group), {
      [G.DATA]: attachmentGroup[G.DATA].concat(attachmentGroup[G.CACHE]),
      [G.STATE]: attachmentGroup[G.STATE],
    })
  }

  try {
    url && blob && persist && worker && await storage.files.put({ url, name, contents: blob })
  } catch (error) {
    console.warn(`Unable to persist file ${name} with url: ${url}`)
  }

  // Shows a dialog if the fetching was aborted due to a timeout
  rest?.status && rest?.status === 408 && showAbortDialog(app);

  // Terminate the worker if the attachment couldn't be found or the request is completed
  ((rest?.status && rest?.status !== 200) || blob) && worker && terminate(worker, source, group)()
}

/**
 * Initializes a {@link Worker} in order to obtain a remote or local attachment.
 *
 * @param {Gaia.Web.Application} app  the Web platform Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => async ({ detail }) => {
  const storage = app[G.ADAPTER][G.PERSISTENCE][G.API]
  const online = app[G.ADAPTER][G.SESSION][G.STATE][G.ONLINE]
  const persistenceEnabled = !settings.suppressPersistence

  const { key, uuid = key, name, api, group, value: item } = detail[G.DATA]
  const _isRemote = isRemote(detail[G.DATA])
  const url = getReadUrl(app, api, key, name)
  const data = _isRemote ? { url, key, uuid } : { item, key, uuid }

  if (online) {
    const { source, worker } = createWorker(_isRemote ? readWorkerBody : previewWorkerBody)
    worker.onmessage = onMessage(worker, source, app, group, item, persistenceEnabled)
    worker.onerror = terminate(worker, source)
    worker.postMessage(data)
  } else if (persistenceEnabled) {
    try {
      const blob = await storage.files.get(url)
      const event = new MessageEvent('message', { data: { ...data, blob } })
      await onMessage(null, null, app, group, item, false)(event)
    } catch (error) {
      console.warn(`Unable to obtain persisted file by url: ${url}`, error)
    }
  }
}

export default fn

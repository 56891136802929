/* global G */
import { asyncpipe } from 'lib/util'
import modelReset from '@gaia/sequence/model/api/reset'

/**
 * Attempts to delete `obj` data from the server.
 *
 * @param {Gaia.Model.Spec} obj the current module's model
 * @return {Promise<Gaia.Model.Spec>} `obj`
 */
const deleteData = async (obj) => {
  const { api, version } = obj[G.PROPS]
  const id = obj[G.CACHE].key || obj[G.CACHE][0].key
  const url = `/api/v${version}/${api}/${id}`
  await obj[G.ADAPTER][G.HTTP].delete({ url })
  return obj
}

/**
 * Attempts to delete `obj` data from the server and, if successful, it also empties the local model
 * object.
 *
 * @param {Gaia.Model.Spec} obj the current module's model
 * @return {Promise<Gaia.Model.Spec>} `obj`
 */
export default async obj => await asyncpipe(
  deleteData,
  modelReset,
)(obj)

/* eslint-disable no-unused-vars */
/* global G */
import find from 'lib/sequence/component/children/find'
import map from 'lib/sequence/model/api/map'

/**
 * OptionManagement password action
 *
 * @param {Gaia.AppModule.Spec} module  current module
 */
export default module => component => async (...args) => {
  const model = module[G.MODEL]
  const { box } = find(component)

  map(model)(box)

  return args
}

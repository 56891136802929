/* global G */
import { curry } from 'lib/util'

/**
 * Gets the assemblies the current article is part of and returns them as a list.
 *
 * @param {Gaia.AppModule.Spec} module        app module
 * @param {Gaia.AppModule.Action} component   action component
 * @param {Gaia.AppModule.EventHandler} event the event that triggered this handler
 * @returns {{key: *, value: *}[]}
 */
const getPartAssemblies = (module, component, event) => {
  const model = module[G.MODEL][G.CHILDREN]?.article || {}
  const modelCache = model[G.CACHE]

  const assemblies = modelCache?.value?.installedIn || []

  return assemblies.map((assembly, index) => ({
    key: index,
    value: assembly,
  }))
}

export default curry(getPartAssemblies)

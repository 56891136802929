/* eslint-disable no-unused-vars */
import { asyncPipeSpread } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import sequenceComponentState from 'lib/sequence/component/state'

const {
  set: activate,
} = sequenceComponentState('active')

/**
 * Activates the camera.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @return {function(*, ...[*]): *[]}
 */
const activateCamera = module => (components, ...args) => {
  const { camera } = components
  activate(camera)
  return args
}

/**
 * Cart Cart Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  activateCamera(module),
)(find(component), ...args)

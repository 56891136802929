/* global G */
/* eslint-disable no-restricted-exports */
import { curry } from 'lib/util'

/**
 * Cart Event Dispatcher
 *
 * Dispatches cart `type` event, passing it `details`. Will wait for the
 * operation to finish and execute `callback` afterward, if present.
 *
 * @param {Gaia.AppModule.Spec} obj   module composition
 * @param {EventBus.type} type        type of the event
 * @param {Object} detail             data for the cart adapter
 * @param {Function} [callback=null]  optional callback to execute
 * @returns {(function(): Promise<void>)|*}
 */
const seqModuleAdapterEventsDispatchFn = (obj, type, detail, callback = null) => async () => {
  const eventBus = obj[G.ADAPTER][G.EVENTS]
  const cartData = await new Promise((resolve) => {
    eventBus.add(eventBus.type(G.CART, G.DONE), ({ detail: cart }) => {
      const { [G.DATA]: currentCart } = cart
      resolve(currentCart)
    }, { once: true })

    eventBus.dispatch(eventBus.type(...type), { [G.DATA]: detail })
  })

  cartData && await callback?.(cartData)
}
const seqModuleAdapterEventsDispatch = curry(seqModuleAdapterEventsDispatchFn)

/**
 * Changes a position in the cart.
 *
 * @type {(function(...[*]): (*))|*}
 */
const change = curry(
  async (module, component, detail, callback, e) => await seqModuleAdapterEventsDispatch(
    module,
    [G.CART, G.UPDATE],
    detail,
    callback,
  )(e),
)

/**
 * Activates the cart contained in `detail`.
 *
 * @type {(function(...[*]): (*))|*}
 */
const activate = curry(
  async (module, component, detail, callback, e) => await seqModuleAdapterEventsDispatch(
    module,
    [G.CART, G.ACTIVATE],
    detail,
    callback,
  )(e),
)

/**
 * Deletes the cart contained in `detail` or the active one.
 *
 * @type {(function(...[*]): (*))|*}
 */
const discard = curry(
  async (module, component, detail, callback, e) => await seqModuleAdapterEventsDispatch(
    module,
    [G.CART, G.DELETE],
    detail,
    callback,
  )(e),
)

/**
 * Removes a position from the cart.
 *
 * @type {(function(...[*]): (*))|*}
 */
const remove = curry(
  async (module, component, detail, callback, e) => await seqModuleAdapterEventsDispatch(
    module,
    [G.CART, G.REMOVE],
    detail,
    callback,
  )(e),
)

/**
 * Adds a position to the cart.
 *
 * @type {(function(...[*]): (*))|*}
 */
const add = curry(
  async (module, component, detail, callback, e) => await seqModuleAdapterEventsDispatch(
    module,
    [G.CART, G.ADD],
    detail,
    callback,
  )(e),
)

const cartEvents = {
  add,
  discard,
  activate,
  change,
  remove,
}

export {
  cartEvents as default,
  add,
  discard,
  activate,
  change,
  remove,
}

/* global G */
import { curry } from 'lib/util'
import { discard } from 'app/_shared/events/cart'

/**
 * Deletes the cart denoted by `key` by calling `G.CART, G.DELETE`.
 *
 * If `key` is falsy, the currently active cart will be deleted (as per `delete` method
 * on the cart adapter).
 *
 * @param {Function} callback                 callback to execute
 * @param {Gaia.AppModule.Spec} module        module composition
 * @param {Gaia.Component.Spec} component     action component
 * @param {Gaia.AppModule.EventHandler} event triggered event
 * @returns {Promise<void>}
 * @private
 */
const _discard = async (callback, module, component, event) => {
  const { key } = event?.detail || event || {}

  const data = { key }

  await discard(module, component, data, callback, event)
}

export default curry(_discard)

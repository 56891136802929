/* global G, PLATFORM */
import { setKey } from '@gaia/util'
import routeComposition from 'trait/composition/route'
import sequenceInitAcl from 'lib/sequence/app/acl/init'
import seqConnectPubSub from 'lib/sequence/app/pubsub/connect'
import seqInitCart from 'lib/sequence/app/init/cart'
import notificationsInit from 'lib/sequence/app/init/notifications'
import settingsInit from 'lib/sequence/app/init/settings'
import settings from '@tenant/settings'

/**
 * Guest Access Action
 *
 * This is a pure business logic action, it has no UI
 *
 * Sets the application default route for the current module.
 *
 * @param {Gaia.AppModule.Spec} module - application module
 * @return {function(): function(...[*]): Promise<*[]>}
 */
const action = module => () => async (...args) => {
  const moduleState = module[G.STATE]
  const route = module[G.ADAPTER][G.ROUTER].restorePoint()
  const sessionState = module[G.ADAPTER][G.SESSION][G.STATE]
  const { module: moduleName, action: actionName, key } = route
  // TODO: Refactor with SP-957 / SP-956
  const userRole = module[G.ADAPTER][G.SESSION][G.STATE][G.META].role
  const cartAcl = module[G.ADAPTER][G.SESSION][G.STATE][G.META].acl?.cart

  sequenceInitAcl(module, null)
  userRole.hasMessages && seqConnectPubSub(module, null)

  if (PLATFORM !== 'mobile') { // TODO: not yet implemented for the mobile platform
    cartAcl && cartAcl?.includes(10) && await seqInitCart(module)()
  }

  // Init settings
  await settingsInit(module)()

  // Init notification groups that need to be instantiated from within the business logic.
  // See {@code notificationGroups} in {@link notificationsInit}.
  await notificationsInit(module)()
  // call plugins' login lifecycle method
  await module[G.ADAPTER][G.PLUGIN].login()
  // manually power-caching auto-login request in case the application goes offline before
  // calling it itself. This only has an effect if the user goes offline
  if (!settings.suppressPersistence) {
    const { context, refs } = module[G.ADAPTER][G.SESSION][G.STATE][G.META]
    await module[G.ADAPTER][G.PERSISTENCE].requests.put({
      url: '/api/v1/public/login',
      method: 'GET',
      data: { context, refs },
    })
  }

  setKey([], G.PREV, sessionState) // clearing history stack
  setKey(routeComposition(moduleName, actionName, key), G.ROUTE, moduleState)
  setKey(false, G.ERROR, moduleState)
  key && args.unshift({ key })

  return args
}

export default action

import { Box, Grid, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import { debounce, PlatformEvent } from 'lib/util'
import { startTransition, useCallback, useState } from 'react'
import Input from 'ui/Element/Field/Input'
import Button from 'ui/Element/Button/Simple'
import useCart from 'platform/react/hook/useCart'
import SvgIcon from 'ui/Element/Icon/Svg'
import Amount from 'ui/Element/Field/Amount'
import Text from 'ui/Element/Text/Simple'

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    gap: theme.spacing(2),
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gap: theme.spacing(2),
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    alignSelf: 'stretch',
  },
  infoAndComment: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
  commentButton: {
    padding: 0,
    height: 'auto!important',
    minHeight: '0!important',
  },
  icon: {
    display: 'flex',
    width: '5.25rem',
    height: '5.25rem',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.gray[960],
    borderRadius: '0.5rem',
  },
})

/**
 * Displays information about a part of the cart for the positions list.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
const ListItemCellCartPosition = (classes, props = {}) => {
  const {
    id,
    name,
    partIcon,
    articleNumber,
    commentAddLabel,
    commentIcon,
    events,
  } = props

  const theme = useTheme()
  const [showInput, setShowInput] = useState(false)
  const [inputDisabled, setInputDisabled] = useState(false)
  const { position } = useCart({ id, name })
  const { comment = null, amount } = position || {}

  const handleAmount = useCallback((e) => {
    const clickEvent = new PlatformEvent(e, { key: id, name, amount: e.detail.value })
    events?.onAmount(clickEvent)
  }, [events, id, name, amount])

  const handleCommentClick = useCallback(() => setShowInput(!showInput), [])

  const handleCommentAdd = debounce((e) => {
    const clickEvent = new PlatformEvent(e, { key: id, name, comment: e.detail.value })
    startTransition(() => events?.onCommentAdd(clickEvent))

    if (e?.key === 'Enter') {
      setShowInput(false)
      setInputDisabled(false)
    }
  }, 1000, false)

  const handleRemove = useCallback((e) => {
    const clickEvent = new PlatformEvent(e, { key: id, name })
    events?.onRemove?.(clickEvent)
  }, [events, id, name])

  return (
    <Grid
      container
      className={classes.container}
    >
      <Grid
        container
        item
        xs={12}
        sm={10}
        className={classes.left}
      >
        <Box className={classes.icon}>
          <SvgIcon
            icon={partIcon.name}
            variant={partIcon.variant}
            color={'gray.500'}
            width={'1.5rem'}
            height={'1.5rem'}
          />
        </Box>
        <Grid
          container
          className={classes.infoAndComment}
        >
          <Grid
            container
            className={classes.info}
          >
            <Text
              maxLines={1}
              variant={'16/bold'}
              color={'common.black'}
              value={name}
            />
            <Text
              maxLines={1}
              variant={'14/medium'}
              color={'common.black'}
              value={articleNumber || '-'}
            />
          </Grid>
          <Grid container>
            {!comment || showInput ? (
              <>
                {showInput && (
                  <Box sx={{ width: '100%', marginTop: '1rem' }}>
                    <Input
                      label={commentAddLabel}
                      value={comment || ''}
                      disabled={inputDisabled}
                      events={{ onKeyPress: (e) => {
                        if (e?.key === 'Enter') setInputDisabled(true)
                        handleCommentAdd(e)
                      } }}
                    />
                  </Box>
                )}
                {!showInput && (
                  <Button
                    bold={false}
                    variant={'text'}
                    fullWidth={false}
                    events={{ onClick: handleCommentClick }}
                    startIcon={commentIcon.name}
                    className={classes.commentButton}
                    startIconProps={{
                      variant: commentIcon.variant,
                      color: theme.palette.text.secondary,
                    }}
                    value={commentAddLabel}
                  />
                )}
              </>
            ) : (
              <Box onClick={() => setShowInput(true)}>
                <Text
                  maxLines={1}
                  variant={'14/medium'}
                  color={'textDescription'}
                  value={comment}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={'auto'}
        className={classes.right}
      >
        <Box onClick={handleRemove}>
          <SvgIcon
            icon={'close'}
            variant={'filled'}
            color={'common.black'}
          />
        </Box>
        <Amount
          value={amount}
          events={{ onChange: handleAmount }}
          color={theme.palette.common.black}
        />
      </Grid>
    </Grid>
  )
}

export default CellHOC(ListItemCellCartPosition, styles)

/* eslint-disable no-throw-literal,no-unused-expressions */
/* global G */
import routeComposition from 'trait/composition/route'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import { setData } from 'lib/sequence/model/api/set'
import { showBlockingDialog } from 'app/_shared/events/dialog'

const NoInternetError = () => ({
  messageKey: 'label.noInternetConnection',
  message: 'No internet connection',
  ns: 'common',
})

const RejectionError = response => ({
  messageKey: 'text.operationCancelled',
  message: 'Operation cancelled',
  ...response,
})

/**
 * @typedef {Object} OptionPasswordResponse
 * @description A response returned by the option management endpoint.
 * @property {string} [password]          the option activation password
 * @property {string} [messageKey]        the translation key for a final message
 * @property {string} [message]           the default final message
 * @property {string} [dialogMessageKey]  the translation key for a message to be displayed if the
 *                                        generate request has to be confirmed
 * @property {string} [dialogMessage]     the default confirmation message
 * @property {string} [rejectMessageKey]  the translation key for a message to be displayed if the
 *                                        user cancels the confirmation dialog
 * @property {string} [rejectMessage]     the default reject message
 * @property {string} [confirmationNonce]
 */

/**
 * Shows a dialog asking the user for confirmation.
 *
 * @param {Gaia.AppModule.Spec} module    current module
 * @param {Object} info                   translation object
 * @param {string} info.dialogMessageKey  dialog message translation key
 * @param {string} info.dialogMessage     dialog message translation default text
 * @return {Promise<*>}
 */
const showConfirmationDialog = async (module, info) => await showBlockingDialog(module, null, {
  title: {
    ns: 'optionManagement',
    key: `${info.dialogMessageKey}.title`,
    defaultValue: 'Confirmation',
  },
  text: {
    ns: 'optionManagement',
    key: `${info.dialogMessageKey}.text`,
    defaultValue: info.dialogMessage,
    md: true,
  },
  ok: {
    ns: 'common',
    key: 'button.confirm',
    defaultValue: 'Confirm',
  },
  cancel: {
    ns: 'common',
    key: 'button.cancel',
    defaultValue: 'Cancel',
  },
})

/**
 * Shows a dialog after the user has rejected a request required to finish the process.
 *
 * @param {Gaia.AppModule.Spec} module    current module
 * @param {Object} info                   translation object
 * @param {string} info.rejectMessageKey  dialog message translation key
 * @param {string} info.rejectMessage     dialog message translation default text
 * @return {Promise<*>}
 */
const showRejectionDialog = async (module, info) => await showBlockingDialog(module, null, {
  title: {
    ns: 'optionManagement',
    key: `${info.rejectMessageKey}.title`,
    defaultValue: 'Option couldn\'t be activated',
  },
  text: {
    ns: 'optionManagement',
    key: `${info.rejectMessageKey}.text`,
    defaultValue: info.rejectMessage,
    md: true,
  },
  ok: {
    ns: 'common',
    key: 'button.ok',
    defaultValue: 'Ok',
  },
  cancel: null,
})

/**
 * Attempts to obtain the password for an OptionManagement by sending a request with the `payload`
 * contents. If no `payload` is passed, its value is taken from the `request` attribute of the
 * module's (optionManagement/scan) model.
 *
 * If the response contains a `confirmationNonce` property, it uses the response messages to display
 * confirmation and rejection dialogs and performs the confirmation request accordingly.
 *
 * @param {Gaia.AppModule.Spec} module  current module
 * @param {Object} [payload]            scanned code contents. if not passed, are taken from model
 * @return {Promise<{response: OptionPasswordResponse, [password]: string}>}
 */
export const requestOptionPassword = async (module, payload) => {
  const model = module[G.MODEL]
  const { version } = model[G.PROPS]
  const { request } = model[G.CHILDREN]
  const params = payload || request[G.CACHE]
  const online = module[G.ADAPTER][G.SESSION][G.STATE][G.ONLINE]

  !online && throw NoInternetError()

  let result

  const url = `/api/v${version}/optionmanagement`

  const options = { middleware: ({ persistence, ...middleware }) => Object.values(middleware) }
  /** @type {OptionPasswordResponse} */
  const response = await model[G.ADAPTER][G.HTTP].post({ url, params }, options)
  const { password, confirmationNonce } = response || {}

  result = { response }

  if (password) {
    result = { response, password }
  } else if (confirmationNonce) {
    if (await showConfirmationDialog(module, response)) {
      const confirmation = { ...params, confirmationNonce }
      result = await requestOptionPassword(module, confirmation)
    } else {
      await showRejectionDialog(module, response)
      throw RejectionError(response)
    }
  } else {
    throw response
  }
  return result
}

/**
 * OptionManagement generate action
 *
 * @param {Gaia.AppModule.Spec} module  current module
 * @returns {function(): function(...[*]): Promise<*[]>}
 */
export default module => () => async (...args) => {
  const { payload: request } = args[0] || {}
  const intl = module[G.ADAPTER][G.INTL]
  const moduleState = module[G.STATE]
  const model = module[G.MODEL]
  const submitDate = new Date().toISOString()

  setData(model, {
    request,
    submitDate,
  }, {
    updateCache: true,
  })

  try {
    const result = await requestOptionPassword(module, request)
    const { response, password } = result
    setData(model, { response, password }, { updateCache: true })

    moduleState[G.ROUTE] = routeComposition(null, 'password')
    return await redirectSequence(module)({})
  } catch (e) {
    // const response = { messageKey: e.messageKey, message: e.message }
    setData(model, { response: e }, { updateCache: true })

    moduleState[G.ROUTE] = routeComposition(null, 'error')
    const error = await intl._t(e.messageKey, {
      _key: e.messageKey,
      ns: e.ns || 'optionManagement',
      defaultValue: e.message,
    })
    return await redirectSequence(module)({ error })
  }
}

/* eslint-disable no-unused-vars */
import { curry } from 'lib/util'
import concatQueryParams from 'app/_shared/events/search/query/util'
import { filterUserRoles } from 'app/_shared/events/collection/listUserRoles'

/**
 * Return a search query that filters the search to only contain users that are active,
 * and mentionable.
 *
 * @param {Gaia.AppModule.Spec} module      app module
 * @param {Gaia.Component.Spec} component   action component
 * @param {Event|object} event              information about the event that triggered this handler
 * @returns {string}
 */
const userIsMentionable = (module, component, event) => {
  const mentionableRoles = filterUserRoles('canBeMentioned', module, component, event)
  const roleString = mentionableRoles.map(role => `${role.key}*`).join(' ')

  const params = {
    type: 'metatype:user',
    status: 'AND status:(50)',
    term: event?.detail?.value
      ? `AND id:org.couchdb.user\\:${event.detail.value}* `
      : '',
    role: `AND roles:(${roleString})`,
  }

  return concatQueryParams(params)
}

export default curry(userIsMentionable)

import { Box, Typography, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import SvgIcon from 'ui/Element/Icon/Svg'
import { getThemeColor } from 'lib/util'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { forwardRef } from 'react'
import Chip from 'ui/Element/Text/Chip'

const styles = theme => ({
  documentation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  label: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  chips: {
    '& .MuiChip-root': {
      '&:not(:last-child)': {
        marginRight: '0.5rem',
      },
    },
    '& .MuiChip-label': {
      fontSize: '0.75rem',
    },
  },
})

/**
 * Displays a doc chunk.
 *
 * @param {Object} classes  element's style classes
 * @param {Object} props    element's properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ListItemCellEquipmentInformationNameDocumentation = (classes, props = {}) => {
  const theme = useTheme()
  const { label = '-', product } = props

  const ForwardedChip = forwardRef((chipProps, ref) => <Chip forwardedRef={ref} {...chipProps} />)
  const productLabels = product.map((item, index) => (<ForwardedChip key={index} text={item} />))

  return (
    <Box className={classes.documentation}>
      <Box className={classes.label}>
        <OverflowTooltip
          variant={'14/bold'}
          color={'common.black'}
          component={Typography}
          classes={{ label: classes.row }}
        >
          {label}
        </OverflowTooltip>
        <OverflowTooltip classes={{ label: classes.chips }}>
          {productLabels}
        </OverflowTooltip>
      </Box>
      <Box>
        <SvgIcon
          variant={'filled'}
          icon={'chevron_right'}
          color={getThemeColor(theme, 'text.secondary')}
        />
      </Box>
    </Box>
  )
}

export default CellHOC(ListItemCellEquipmentInformationNameDocumentation, styles)

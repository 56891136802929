/* eslint-disable no-param-reassign,no-unused-vars */
/* global G */
import ui from 'lib/hook/ui/update'
import read from 'lib/hook/model/read'
import check from 'app/_shared/hook/check'
import back from 'app/_shared/hook/redirect/back'
import appBarBack from 'app/_shared/hook/appbar/back'
import recall from 'app/_shared/hook/component/recall'
import updateCache from 'app/_shared/hook/model/updateCache'
import platform from 'app/_shared/hook/platform'
import uiCartTitle from 'app/cart/hook/uiCartTitle'
import quickActions from 'app/_shared/hook/appbar/quickActions'
import redirect from 'lib/hook/router/redirect'

const _indexHooks = {
  before: [
    recall,
  ],
  after: [
    ui,
  ],
}

const _cartHooks = {
  before: [],
  after: [
    platform({
      mobile: [appBarBack, uiCartTitle, quickActions],
    }),
    ui,
  ],
}

const _pdfHooks = {
  before: [],
  after: [
    platform({
      mobile: [quickActions],
    }),
    ui,
  ],
}

const _cartPositionHooks = {
  before: [],
  after: [
    ui,
  ],
}

const _webshopHooks = {
  before: [],
  after: [
    ui,
  ],
}

const _detailHooks = {
  before: [
    read,
  ],
  after: [
    platform({
      mobile: [quickActions],
    }),
    ui,
  ],
}

const _checkoutHooks = {
  before: [
    // validation takes place in action logic
  ],
  after: [
    ui,
    check,
    redirect,
  ],
}

const _submitHooks = {
  before: [
    // validation takes place in action logic
  ],
  after: [
    ui,
    check,
    updateCache,
    back,
  ],
}

const hooks = {
  [G.ACTIONS]: {
    index: _indexHooks,

    cart: _cartHooks,
    cartPositionComment: _cartPositionHooks,
    cartPositionCommentOptions: _cartPositionHooks,
    cartPositionAddPart: _cartPositionHooks,

    checkout: _checkoutHooks,
    detail: _detailHooks,
    submit: _submitHooks,
    webshop: _webshopHooks,
    pdf: _pdfHooks,
  },
}

export default hooks

/* global G */
import { useCallback, useEffect, useState } from 'react'

/**
 * useLoader Hook
 *
 * Will listen to `G.LOAD, G.INIT` as well as `G.LOAD, G.DONE` events to expose the current
 * loading state of the application.
 *
 * @param {Gaia.Adapter.EventBus} eventBus  the application's event adapter
 * @returns {boolean} isLoading             whether the application is currently in loading state
 */
const useLoader = (eventBus) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleIsLoading = useCallback(() => setIsLoading(true), [])
  const handleNotLoading = useCallback(() => setIsLoading(false), [])

  useEffect(() => {
    eventBus.add(eventBus.type(G.LOAD, G.INIT), handleIsLoading)
    eventBus.add(eventBus.type(G.LOAD, G.DONE), handleNotLoading)

    return () => {
      eventBus.remove(eventBus.type(G.LOAD, G.INIT), handleIsLoading)
      eventBus.remove(eventBus.type(G.LOAD, G.DONE), handleNotLoading)
    }
  }, [])

  return isLoading
}

export default useLoader

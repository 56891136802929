/* global G */
import { asyncPipeSpread, setKey } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { set } from 'lib/sequence/component/state/value'

/**
 * Sets the `id`, `name` and `comment` for the current position to the action state
 * if possible. Also sets the value of the `comment` field if available.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const initComment = module => async (children, ...args) => {
  const { key, name, comment } = args[0]
  const actionState = module[G.STATE][G.ACTION][G.STATE]

  setKey(key, 'key', actionState)
  setKey(name, 'name', actionState)
  setKey(comment, 'comment', actionState)

  const { comment: commentComponent } = children

  commentComponent && set(commentComponent, comment)

  return args
}

/**
 * Cart Position Comment Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  initComment(module),
)(sequenceComponentFind(component), ...args)

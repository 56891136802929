/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import sequenceComponentState from 'lib/sequence/component/state'

const {
  set: enableTorch,
  get: torchEnabled,
} = sequenceComponentState('torch')

const {
  set: activate,
} = sequenceComponentState('active')

/**
 * Toggles the camera's flashlight. It does so by toggling the `torch` prop of the first component
 * with key 'camera' found in the component tree. It also toggles the `active` prop of the component
 * that triggered the event handler.
 *
 * @type Gaia.AppModule.EventHandler
 */
const toggleFlashlight = async (module, component, event) => {
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { camera } = find(actionComponent)
  const isTorchEnabled = torchEnabled(camera)
  enableTorch(camera, !isTorchEnabled)
  activate(component, !isTorchEnabled)
  module[G.ADAPTER][G.UI].update(module)
}

export default curry(toggleFlashlight)

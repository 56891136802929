/* global G */

/**
 * UI Equipment Title Hook
 *
 * Sets the title of the action to the name of the current equipment and the subtitle to
 * its serial.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @returns {function(...[*]): *[]}
 */
const uiEquipmentTitle = obj => (...args) => {
  const { equipment: { [G.CACHE]: {
    name = null,
    serial = null,
    serialLong = null,
  } = {} } = {} } = obj[G.MODEL][G.CHILDREN]

  const action = obj[G.STATE][G.ACTION]

  name && (action[G.UI].title = name);
  (serialLong || serial) && (action[G.UI].subTitle = serialLong || serial)

  return args
}

export default uiEquipmentTitle

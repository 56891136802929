/* eslint-disable no-unused-vars */
/* global G */

import { curry } from 'lib/util'

/**
 * Confirmation Event Handler
 *
 * Shows a toast of `type` with `severity`. Optionally, the `ns` of the translations can be set.
 *
 * @param {string} type                 type of the confirmation
 * @param {string} [severity='info']    severity of the confirmation
 * @param {boolean} [portal=false]      whether to display the toast through the portal
 * @param {string} [ns='common']        namespace to use for the translations
 * @returns {(function(...[*]): (*))|*}
 */
const confirmation = (type, severity = 'info', portal = false, ns = 'common') => curry((module, component, event) => {
  // Disabled if type not set, see GAIA-989
  if (type) {
    module[G.ADAPTER][G.EVENTS].dispatch(
      module[G.ADAPTER][G.EVENTS].type(G.DATA, G.UPDATE),
      {
        message: module[G.ADAPTER][G.INTL]._t(
          `data.${type}`,
          { ns, _key: `data.${type}`, defaultValue: 'Document was updated' },
        ),
        severity,
        portal,
        close: 5000,
      },
    )
  }
})

export default confirmation

/* eslint-disable no-unused-vars */
/* global G, PLATFORM */
import { asyncPipeSpread } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import initDocumentation from 'app/_shared/action/partial/feedback/initDocumentation'
import { set } from 'lib/sequence/component/state/value'

/**
 * Assembles a tree from the incoming args and sets the action component's
 * state to it.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(*, ...[*]): Promise<*>}
 */
const setTree = module => component => async (components, ...args) => {
  const [{ detail: { documentation = [] } = {} } = {}] = args || []

  const { documentation: actionComponent } = components

  const tree = {
    type: 'Directory',
    $children: documentation.map((item, i) => ({
      documentationId: item.id,
      ...PLATFORM !== 'mobile' && {
        initialNode: i === 0,
      },
      name: item.type,
      attachmentId: item.attachmentId,
      attachment: {
        key: item.attachmentId,
        ...item.attachment,
      },
      labels: { [item.type]: { [item.type]: item.title } },
      nodeType: 'documentation',
      type: 'File',
    })),
  }

  set(actionComponent, { tree })

  return [components, ...args]
}

/**
 * EquipmentInformation Attchment Tree With Feedback Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => component => async (...args) => asyncPipeSpread(
  setTree(module)(component),
  initDocumentation(module)(component),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)

import CellHOC from '@platform/react/hoc/list/cell'
import ApplicationContext from '@platform/react/context/application'
import { useContext } from 'react'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { Typography } from '@mui/material'

/**
 * Intended to display the name of the submitTimestamp of a cart.
 *
 * @param {Object} props    element's properties
 * @param {Object} classes  element's style classes
 *
 * @returns {JSX.Element}
 */
const ListItemCellCartCreated = (classes, props = {}) => {
  const { intl } = useContext(ApplicationContext)
  const { submitTimestamp } = props

  const created = intl.getLocaleDate(submitTimestamp, { dateStyle: 'long' })

  return (
    <OverflowTooltip
      component={Typography}
      variant={'14/medium'}
      color={'white.contrastText'}
    >
      {submitTimestamp ? created : '-'}
    </OverflowTooltip>
  )
}

export default CellHOC(ListItemCellCartCreated)

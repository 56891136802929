/* global G */
import { pipe, curry } from '@gaia/util'
import { hasNamespace } from '@gaia/trait/has'
import { withGetterSetterFn } from '@gaia/trait/with/GetterSetter'
import { usesNamespace, usesGetterSetter } from '@gaia/trait/uses'
import { withObjectFreeze, withDescriptor } from 'trait/with'
import api from './api'

const descriptor = 'adapterUI'

/**
 * @typedef {Object} ContentState
 *
 * Represents the `content` property of the `ui` property in an action configuration.
 *
 * @property {number|string} padding        padding for the content
 * @property {number|string} paddingTop     top padding for the content
 * @property {number|string} paddingBottom  bottom padding for the content
 * @property {number|string} paddingLeft    left padding for the content
 * @property {number|string} paddingRight   right padding for the content
 * @property {boolean} scroll               whether the content is scrollable
 * @property {boolean} animateScroll        whether to animate when scrolling to a position
 * @property {string} backgroundColor       background color for the content
 */

/**
 * @typedef {Object} UIState
 *
 * Represents the `ui` property in an action configuration. This is a global type definition
 * that will contain properties that may not be applicable for this specific platform, like
 * `snap` for example.
 *
 * @property {string} title                                 title of the action
 * @property {'left'|'center'|'right'} titleAlign           alignment for the title in the app bar
 * @property {string} [subTitle=null]                       sub title of the action
 * @property {boolean} drawer                               whether to open the action in
 *                                                          {@link Drawer}
 * @property {boolean} bookmark                             whether this action is bookmarkable
 * @property {boolean} history                              whether this action creates a history
 *                                                          entry
 *
 * @property {Object} search                                options for the search field
 * @property {string[]} search.contexts                     list of contexts the field is visible
 *                                                          for
 * @property {string} search.placeholder                    placeholder for the field
 * @property {Object} search.transform                      options for the query the search
 *                                                          field fires
 * @property {boolean} [search.runOnVisibilityChange=false] whether to run the onSearch handler
 *                                                          every time the visibility of the
 *                                                          search field changes
 * @property {string} [search.value]                        the value of the search field
 * @property {string} [search.term]                         the term of the search field
 *
 * @property {Object} create                                options for the create button
 * @property {Object} create.route                          route the button redirects to on click
 * @property {Object} create.route.action                   action of the route
 * @property {Object} create.route.module                   module of the route
 *
 * @property {boolean} logo                                 whether to show the tenant logo in the
 *                                                          app bar
 * @property {boolean} appBar                               whether to show the app bar
 * @property {boolean} fullScreen                           whether to show the action in full
 *                                                          screen, meaning to hide the side and
 *                                                          top bar
 * @property {string[]|number[]} snap                       snap points for the action
 * @property {Object} back                                  options for a custom back handler
 * @property {string} back.component                        component to use for the back handler
 *                                                          (see {@link back} app bar hook)
 * @property {QuickAction[]} quickActions                   quick actions for the action
 * @property {ContentState} content                         state for the content of the action
 * @property {Object[]} actions                             buttons for the actions
 *                                                          (usually "Cancel", "Ok") for
 *                                                          {@link Drawer} or {@link Dialog}
 */

/**
 * UI Adapter.
 *
 * Adapter used to render application ui.
 *
 * @memberOf Gaia.Adapter#
 * @typedef Adapter.UI
 *
 * @param { ReactDOM } renderer - React Dom Renderer
 * @param { Gaia.Web } obj - Web Application
 * @return {function(*=): *}
 */
const uiAdapter = (renderer, obj) => pipe(
  withDescriptor(descriptor),
  hasNamespace(G.API),
  usesNamespace(G.API, api(renderer)(obj)),
  withGetterSetterFn(G.APP),
  usesGetterSetter(G.APP, () => obj),
  withGetterSetterFn(G.REF),
  withObjectFreeze,
)({})

export default curry(uiAdapter)

/* global G */
import { curry, setKey } from 'lib/util'
import fetchTree from 'app/_shared/events/file/tree/fetchTree'
import structuredClone from '@ungap/structured-clone'

/**
 * Get Hotspot Tree Event Handler
 *
 * Retrieves the tree by first looking into the action state. If a tree for the current
 * equipment is present, it'll return it. If not, it'll use {@link fetchTree} to fetch
 * the tree in save it into the action state as `<equipment key>: <tree>` and return it.
 *
 * @param {Gaia.AppModule.Spec} module      the current module composition object
 * @param {Gaia.Component.Spec} component   the current action's main component
 * @param {PlatformEvent} event             the event object that triggered this handler
 * @returns {Promise<{tree: (*|(function(...[*]): (*)))}|*>}
 */
const getHotspotTree = async (module, component, event) => {
  const eventBus = module[G.ADAPTER][G.EVENTS]

  const { equipment } = module[G.MODEL][G.CHILDREN]
  const equipmentKey = equipment[G.STATE][G.REF]

  const moduleState = module[G.STATE]
  const actionState = moduleState[G.ACTION][G.STATE]
  const { [equipmentKey]: cachedTree = null } = actionState

  if (cachedTree) {
    return cachedTree
  }

  try {
    // Init loader
    eventBus.dispatch(eventBus.type(G.LOAD, G.INIT))

    const result = await fetchTree(module, component, event)
    const fetchedTree = result?.$children ? result : { $children: result, type: 'Directory' }

    if (!cachedTree && fetchedTree) {
      const clonedTree = structuredClone(fetchedTree)
      // Saving the tree to the actionState as `{'EQUIPMENT:xxx': {...}}`, so that we can save
      // trees for multiple equipments
      setKey(clonedTree, equipmentKey, actionState)
    }

    // Close loader
    eventBus.dispatch(eventBus.type(G.LOAD, G.DONE))

    return { tree: fetchedTree }
  } catch (e) {
    console.error(e)
    return { $children: [], type: 'Root' }
  }
}

export default curry(getHotspotTree)

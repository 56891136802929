/* global G */
import { pipe } from 'lib/util'
import appModuleComposition from 'trait/composition/appmodule'
import { usesGetterSetter } from 'trait/uses'
import { canHookInto } from 'trait/can'
import actions from '@app/error/actions'
import events from '@app/error/events'
import hooks from '@app/error/hooks'

const descriptor = 'module::error'

/**
 * Module Error
 *
 * used for errors, ie navigation, acl
 *
 * @param {Gaia.AppModule.Spec} obj - configuration object
 * @return {Gaia.AppModule.Spec} obj - object composition
 */
const composition = pipe(
  appModuleComposition(descriptor),
  usesGetterSetter(G.ACTIONS, actions),
  usesGetterSetter(G.EVENTS, events),
  canHookInto(G.ACTIONS, hooks),
)

export default composition

import { v4 as uuidV4 } from 'uuid'
import descriptor from 'platform/adapter/persistence/descriptor/drafts'
import { dataService } from 'platform/adapter/persistence/service'

/**
 * An entity intended to store/cache network requests together with their responses.
 *
 * @memberOf Gaia.Adapter.Persistence
 * @typedef {Entity} Draft
 * @property {string} id              the draft's identifier
 * @property {string} revision        an identifier of the current state of the draft
 * @property {string} remoteRevision  an identifier of the remote state of the draft
 * @property {Object} data            the drafted object
 * @property {string} type            an identifier for the kind of `data` saved (e.g. `'person'`)
 * @property {string} action          the action performed with `data`, that should be done during
 *                                    synchronization if `submit` is `true`
 * @property {boolean} save
 * @property {boolean} submit         whether `data` should be submitted during the next
 *                                    synchronization
 * @property {string|null} error      error information in case the synchronization failed
 */

/**
 * @memberOf Gaia.Adapter.Persistence.PersistenceAPI
 * @typedef {PersistenceAPI} DraftsAPI
 * @return {DraftsAPI}
 */
export default (() => {
  const drafts = dataService(descriptor)

  return Object.create({}, {
    init: {
      /**
       * Creates the drafts store, if it doesn't exist.
       *
       * @return {Promise<void>}
       */
      value: async () => await drafts.init(),
      iterable: true,
      enumerable: true,
    },
    get: {
      /**
       * Attempts to obtain a persisted {@link Draft} from the database.
       *
       * @param {string} id the identifier of the {@link Draft} to obtain
       * @return {Promise<Draft>} the obtained {@link Draft}
       */
      value: async id => await drafts.get(id),
      iterable: true,
      enumerable: true,
    },
    getAll: {
      /**
       * Attempts to obtain the list of persisted {@link Draft} entities that fulfills `condition`
       * from the database.
       *
       * @param {Object} condition an object defining a property with a value both of which the
       *                           returned items must have (e.g. `{ type: 'person' }`)
       * @return {Promise<Draft>} the obtained {@link Draft}
       */
      value: async condition => await drafts.getAll(condition),
      iterable: true,
      enumerable: true,
    },
    put: {
      /**
       * Attempts to persist `item` in the database, overwriting any records identified by the same
       * key in the process.
       *
       * @param {Draft} item  the item to persist
       * @return {Promise<any>}       the identifier of the new persisted item
       */
      value: async (item) => {
        const id = item.id || item.value?.key || uuidV4()
        await drafts.put({ ...item, id })
      },
      iterable: true,
      enumerable: true,
    },
    delete: {
      /**
       * Attempts to delete a persisted {@link Draft} by its `key`.
       *
       * @param {string} id  the identifier of the {@link Draft} to delete
       * @return {Promise<any>}
       */
      value: async id => await drafts.remove(id),
      iterable: true,
      enumerable: true,
    },
    deleteAll: {
      /**
       * Attempts to delete all persisted {@link Draft} objects.
       */
      value: async () => await drafts.removeAll(),
      iterable: true,
      enumerable: true,
    },
  })
})()

/* global React */
/* eslint-disable object-curly-newline */
import { useEffect, useMemo, useRef, useState } from 'react'
import { Grid, LinearProgress } from '@mui/material'
import { useMemoRef, useStyles } from 'platform/react/hook'
import usePreviewableAttachment from 'platform/react/hook/usePreviewableAttachment'
import SimpleImage from 'ui/Element/Image/Simple'
import { PlatformEvent } from 'lib/util'
import Toolbar from 'platform/react/hoc/attachment/toolbar'
import usePlaceholder from 'platform/react/hook/usePlaceholder'

/**
 * Returns the FileImage element's current theme styles.
 *
 * @param {Object} theme      the application's current theme
 * @param {boolean} clickable whether or not the attachment is clickable
 * @returns {Object}          the FileImage element's styles
 */
const styles = (theme, { clickable }) => ({
  root: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    // width: '100%',
    height: '100%',
    cursor: clickable ? 'pointer' : 'auto',
  },
  progress: {
    left: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0.4,
    borderRadius: 8,
  },
  ...theme.custom.fileImage,
})

/**
 * Returns {@code src} if the {@code item} is an image or attempts to obtain a placeholder from
 * among the configured {@code types}.
 *
 * @param {File} item     a File object
 * @param {Object} types  an object defining the file types to be replaced by placeholders
 * @returns {*|string}    {@code src} or the url to a placeholder
 */
const getPlaceholder = (item, types) => {
  const extension = item.name.slice(item.name.lastIndexOf('.') + 1)
  const placeholder = Object.keys(types).find(id => types[id].includes(extension))

  return `placeholder/${placeholder || 'other'}.svg`
}

/**
 * FileImage element
 *
 * Used to display preview images of files.
 *
 * @param {Object} fileProps                                  props
 * @param {Object} fileProps.attachment                       the attachment to display
 * @param {Object} fileProps.types                            the allowed types for the attachment
 * @param {string} fileProps.group                            attachment group
 * @param {Object} fileProps.events                           events for this component
 * @param {Function} [fileProps.events.onState]               event that the state of the attachment
 *                                                            should be passed to if it changes
 * @param {Function} [fileProps.events.onClick]               event handling clicking on the
 *                                                            attachment
 * @param {Object} fileProps.props                            additional props
 * @param {string|null} [fileProps.props.ratio]               ratio of the picture ot use for
 *                                                            {@link SimpleImage}
 * @param {boolean} [fileProps.props.clickable]               whether the image should be clickable
 * @param {boolean} [fileProps.props.skipSkeleton]            whether to skip showing the skeleton
 *                                                            on loading
 * @param {boolean} [fileProps.props.skipPlaceholder]         whether to not show a placeholder when
 *                                                            appropriate
 * @param {boolean} [fileProps.props.showProgress]            whether to show a linear progress bar
 *                                                            on loading
 * @param {string[]|null} [fileProps.props.previewableTypes]  types to show directly
 * @param {number|null} [fileProps.props.maxPreviewSize]      maximum size to show attachments
 *                                                            directly
 * @returns {JSX.Element}
 * @constructor
 */
const FileImage = ({ attachment, types, group, events, ...props }) => {
  const {
    ratio = null,
    clickable = true,
    skipSkeleton = false,
    skipPlaceholder = false,
    showProgress = false,
    download = true,

    previewableTypes = null,
    maxPreviewSize = null,

    md = 12,
    lg = 12,
    xl = 12,
    sm = 12,
    xs = 12,
  } = props

  const currentAttachment = attachment?.value?.name
    ? attachment
    : { value: { name: '' } }
  const { value: item } = currentAttachment

  const classes = useStyles(styles, { clickable })()
  const isPreviewable = usePreviewableAttachment({
    attachment: currentAttachment,
    previewableTypes,
    maxPreviewSize,
  })()

  const [attachmentState, setAttachmentState] = useState({})
  const { state = null, fetch } = attachmentState
  const { progress = 0 } = state || {}
  const isLoading = progress !== 0 && progress !== 100

  const filename = usePlaceholder(types, item)
  const isImage = useMemo(() => filename?.includes?.('image'), [filename])

  const link = useRef({
    a: document.createElement('a'),
    fetched: false,
  })

  useEffect(() => {
    events?.onState?.(state)
    if (state?.src && state?.loading === false) {
      link.current.a.href = state.src
      link.current.a.download = item.name
      if (link.current.fetched) {
        const clickEvent = new PlatformEvent(new CustomEvent('click'), state)
        events?.onClick?.(clickEvent)
        link.current.a.click()
        link.current.fetched = false
      }
    }
  }, [state])

  // Cleaning up anchorElement after runtime
  useEffect(() => () => { link.current.a.remove() }, [])

  const handleClick = (e) => {
    const clickEvent = data => new PlatformEvent(e, data)

    if (!download) {
      !events?.onClick
        ? console.warn('Image has download prop set to false and no onClick handler. Clicking it has no effect.')
        : events?.onClick?.(clickEvent(currentAttachment))
    } else if (!state?.src) {
      link.current = { ...link.current, fetched: true }
      fetch()
    } else {
      link?.current?.a?.href && link.current.a.click()
      events?.onClick?.(clickEvent(state))
    }
  }

  return (
    <Grid
      item
      container
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      onClick={clickable ? handleClick : null}
      className={classes.root}
    >
      <SimpleImage
        ratio={ratio}
        group={group}
        stretch={true}
        skipSkeleton={skipSkeleton}
        attachment={currentAttachment}
        events={{ onState: setAttachmentState }}
        {
          ...(isPreviewable || skipPlaceholder) || (state?.src && isImage)
            ? {}
            : { filename }
        }
      />
      {isLoading && showProgress && (
        <LinearProgress
          color={'inherit'}
          className={classes.progress}
          variant={'determinate'}
          value={progress}
        />
      )}
    </Grid>
  )
}

export default useMemoRef(Toolbar(FileImage), props => [props.attachment, props.group])

/* eslint-disable max-len */
/* global G */
import { curry, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'

/**
 * Event Handler Search and Recreate
 *
 * Sets the event detail's value as current search term and recreates the module, so that it is
 * taken into account the next time the list is rendered.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Gaia.PlatformEvent} event      information about the event that triggered this handler
 * @param {Boolean} keepSorting           whether or not the sort criteria should be kept. If false,
 *                                        the criteria will be dropped if `event.detail.term` is
 *                                        non-nil
 * @return {Promise<void>}
 */
const searchTabsAndRecreate = async (module, component, event, keepSorting = true) => {
  try {
    const action = module[G.STATE][G.ACTION]
    const actionData = action[G.DATA]
    const actionComponent = action[G.COMPONENT]
    const state = component[G.STATE]
    const { term, transform, reload = true } = event.detail
    const sort = !term || keepSorting

    // setting search field's value
    state.value = term
    term // storing state in action data so that the field's values are restored when going back
      ? actionData.search = { _cache: { term, transform } }
      : delete actionData.search

    const { tabs } = find(actionComponent)

    /**
     * Gathers the root components of each child tab of the tabs component that:
     * - has a `key` of `list` (default case)
     * - or a property `list === true` and is not hidden
     * This makes searching work with multiple lists on the same level
     * (and therefore with non-unique keys) due to acl config
     */
    const lists = tabs ? tabs[G.CHILDREN].reduce((acc, tab) => {
      const tabLists = tab[G.CHILDREN].filter(list => list._name === 'list'
        || (!list[G.STATE].hidden && list[G.PROPS].list))
      return [...acc, ...tabLists]
    }, []) : []

    /**
     * Updates the state and props of all found lists with the relevant event's data.
     */
    lists.forEach((list) => {
      const listProps = list[G.PROPS]
      const listState = list[G.STATE]
      setKey(!sort, 'unsorted', listProps)
      setKey(!!term, 'fromSearch', listProps)
      setKey({ ...listState[G.META], term, transform }, G.META, listState)
      // clearing possible cached values so that the list attempts to obtain its data again
      setKey(null, 'data', listState)
      setKey(null, 'page', listState)
      setKey(null, 'unsegmented', listState)
      // instructing list to reload itself
      setKey(reload ? {} : false, 'reload', listState)
    })

    reload && module[G.ADAPTER][G.UI].update(module)
  } catch (e) {
    console.error(e)
  }
}

export default curry(searchTabsAndRecreate)

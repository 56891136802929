/* eslint-disable no-unused-vars */
/* global G */

import { getFirstItem, setKey } from 'lib/util'

/**
 * Show a confirmation dialog.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {Promise<unknown>}
 * @private
 */
const _confirmDialog = async app => await new Promise((resolve) => {
  const { [G.ADAPTER]: { [G.EVENTS]: eventBus, [G.INTL]: intl } } = app

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: intl.translate(
      'dialog.discard.title',
      {
        ns: 'cart',
        _key: 'dialog.discard.title',
        defaultValue: 'Discard cart',
      },
    ),
    text: intl.translate(
      'dialog.discard.text',
      {
        ns: 'cart',
        _key: 'dialog.discard.text',
        defaultValue: 'Are you sure you want to remove all articles?\nYour cart and all data will be removed.',
      },
    ),
    children: {
      cancel: {
        key: 'cancel',
        value: intl.translate(
          'button.cancel',
          {
            ns: 'common',
            _key: 'button.cancel',
            defaultValue: 'Cancel',
          },
        ),
      },
      ok: {
        key: 'ok',
        variant: 'contained',
        value: intl.translate(
          'button.delete',
          {
            ns: 'common',
            _key: 'button.delete',
            defaultValue: 'Delete',
          },
        ),
      },
    },
    okHandler: () => { resolve(true) },
    cancelHandler: () => { resolve(false) },
  })
})

/**
 * Deletes the users current cart, needing the user to confirm it first. Dispatches
 * {@code G.CART, G.DONE} and {@code G.NOTIFICATION, G.CREATE, 'cart'} events afterward.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => async ({ detail = {} }) => {
  const adapter = app[G.ADAPTER][G.CART]
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const httpAdapter = app[G.ADAPTER][G.HTTP]

  const { [G.API]: { type, version } = {} } = adapter[G.STATE] || {}

  const { key: incomingKey } = detail[G.DATA]
  const { key: activeKey } = adapter[G.DATA]

  const key = incomingKey || activeKey

  const confirmed = !key ? true : await _confirmDialog(app)

  try {
    if (key && confirmed) {
      const url = `/api/v${version}/${type}/${key}`
      const result = await httpAdapter[G.API].delete({ url })
      const response = getFirstItem(result)

      if (response.ok) {
        setKey(null, G.DATA, adapter)

        const activeCart = adapter[G.DATA]
        eventBus.dispatch(eventBus.type(G.CART, G.DONE), { [G.DATA]: activeCart })
        eventBus.dispatch(eventBus.type(G.NOTIFICATION, G.CREATE, 'cart'))
      }
    }
  } catch (e) {
    console.error(e)
  }
}

export default fn

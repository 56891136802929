import { Box, Typography, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import SvgIcon from 'ui/Element/Icon/Svg'
import { getThemeColor } from 'lib/util'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

const styles = theme => ({
  label: {
    maxWidth: 'calc(100% - 3.125rem)',
  },
  article: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
/**
 * Displays a machine a part is assembled in.
 *
 * @param {Object} classes  element's style classes
 * @param {Object} props    element's properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ListItemCellEquipmentInformationAssembledIn = (classes, props = {}) => {
  const theme = useTheme()
  const { name, pos, amount, group, amountLabel, positionLabel } = props

  return (
    <Box className={classes.article}>
      <Box className={classes.label}>
        <OverflowTooltip
          variant={'14/bold'}
          color={'common.black'}
          component={Typography}
          classes={{ label: classes.row }}
        >
          {name}
        </OverflowTooltip>
        <OverflowTooltip
          color={'gray.400'}
          variant={'12/medium'}
          component={Typography}
          classes={{ label: classes.row }}
        >
          {`${group} • ${amountLabel}: ${amount} • ${positionLabel}: ${pos}`}
        </OverflowTooltip>
      </Box>
      {/* TODO: We can't redirect to the machine the part was assembled in, as we don't have the
                necessary data from the server, for now, disable the chevron */}
      {/* <Box> */}
      {/*  <SvgIcon */}
      {/*    variant={'filled'} */}
      {/*    icon={'chevron_right'} */}
      {/*    color={getThemeColor(theme, 'text.secondary')} */}
      {/*  /> */}
      {/* </Box> */}
    </Box>
  )
}

export default CellHOC(ListItemCellEquipmentInformationAssembledIn, styles)

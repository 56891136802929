import { useMemoRef, useStyles } from '@platform/react/hook'
import useCart from '@platform/react/hook/useCart'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, useTheme } from '@mui/material'
import Simple from 'ui/Element/Button/Simple'
import { useState } from 'react'
import ErrorBoundary from 'ui/Error'

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    ...theme.typography['14/medium'],
  },
  descriptionBox: {},
  dialogButton: {
    textTransform: 'uppercase',
  },
})

/**
 * @typedef {import('@mui/material').ModalProps} ModalProps
 */

/**
 * Modal showing a dialog if the user actually wants to remove all items
 *
 * @type {React.FunctionComponent}
 *
 * @param {Object} removeBoxProps                         Props for the component
 * @param {boolean} removeBoxProps.open                   whether the modal is open
 * @param {ModalProps['onClose']} removeBoxProps.onClose  onClose handler
 * @param {Object} removeBoxProps.props                   additional props
 * @returns {JSX.Element}
 * @constructor
 */
const RemoveBox = ({ open, onClose, ...props }) => {
  const theme = useTheme()
  const { labels, classes, events } = props
  const { onRemove } = events

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.descriptionBox }}
    >
      <DialogTitle>
        {labels.title}
      </DialogTitle>
      <DialogContent>
        {labels.text}
      </DialogContent>
      <DialogActions>
        <Simple
          fullWidth={false}
          onClick={onClose}
          color={'gray'}
          variant={'contained'}
          value={labels.cancel}
          transform={'uppercase'}
        />
        <Simple
          fullWidth={false}
          onClick={(e) => {
            onRemove(e)
            onClose?.(e, 'backdropClick')
          }}
          color={'error'}
          variant={'contained'}
          value={labels.remove}
          transform={'uppercase'}
        />
      </DialogActions>
    </Dialog>
  )
}

/**
 * CartTitle Component
 *
 * @param {string} label        label to display
 * @param {string} buttonLabel  label of the button
 * @param {Object} events       events for the component
 * @param {Object} props        additional props
 * @returns {JSX.Element}
 * @constructor
 */
const CartTitle = ({ label, events, ...props }) => {
  const { buttonLabel, removeAllItems, cancelLabel } = props
  const { cart } = useCart()
  const theme = useTheme()
  const classes = useStyles(styles)()

  const [open, setOpen] = useState(false)

  const positionCount = cart?.value?.positions?.length || 0

  const { onClick = null } = events
  const { spacing = 0, space = 0, gap = 0 } = props

  return (
    <ErrorBoundary>
      <RemoveBox
        open={open}
        labels={{
          title: removeAllItems.title,
          text: removeAllItems.text,
          cancel: cancelLabel,
          remove: buttonLabel,
        }}
        events={{
          onRemove: onClick,
        }}
        classes={{
          dialogButton: classes.dialogButton,
          descriptionBox: classes.descriptionBox,
        }}
        onClose={() => setOpen(false)}
      />
      <Grid
        item
        className={classes.root}
        xs={props.xs}
        sm={props.sm}
        md={props.md}
        lg={props.lg}
        xl={props.xl}
        style={{
          padding: spacing
            ? theme.spacing(spacing)
            : theme.spacing(space, gap),
        }}
      >
        <Typography
          variant={'16/bold'}
          color={'textDark'}
        >
          {!cart ? null : `${label} (${positionCount})`}
        </Typography>
        <Simple
          size={'small'}
          color={'black'}
          variant={'text'}
          fullWidth={false}
          value={buttonLabel}
          className={classes.button}
          events={{ onClick: () => setOpen(true) }}
        />
      </Grid>
    </ErrorBoundary>
  )
}

export default useMemoRef(CartTitle, props => [props.cart])

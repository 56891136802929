/* global React */
import { useEffect, useState } from 'react'
import { Box, lighten, useTheme } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import { getThemeColor, isNum } from 'lib/util'

// eslint-disable-next-line no-unused-vars
const styles = (theme, { background, color, padding, rotate }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: (background && getThemeColor(theme, background)) || (color
      ? lighten(getThemeColor(theme, color), 0.9)
      : theme.palette.background.selected),
    borderRadius: '50%',
    padding: (padding && isNum(padding) && theme.spacing(padding))
      || padding
      || theme.spacing(1.5),
  },
  icon: {
    ...rotate && {
      transform: `rotate(${rotate}deg)`,
    },
    maskSize: 'contain',
    maskPosition: 'center',
    maskRepeat: 'no-repeat',
  },
  ...theme.custom.svgIcon,
})

const roundedStyles = (theme, { size, background, color }) => ({
  root: {
    height: size,
    width: size,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: background || (color
      ? lighten(color, 0.9)
      : theme.palette.background.selected),
    borderRadius: '50%',
  },
})

/**
 * SvgIcon Component
 *
 * Displays custom SVG icon.
 * https://caniuse.com/?search=mask
 *
 * @param {React.Ref} forwardedRef  ref for the element
 * @param {string} icon             name of the icon
 * @param {string|null} color       color of the icon
 * @param {string} variant          variant of the icon
 * @param {number} [size=24]        size of the icon
 * @param {number} [width=size]     width of the icon
 * @param {number} [height=size]    height of the icon
 * @param {boolean} rounded         whether the icon should have a rounded background
 * @param {string} background       background color for the icon
 * @param {number|null} iconPadding padding of the icon
 * @param {number|null} rotate      angle to rotate the icon
 * @param {string} className        external styling
 * @returns {null|JSX.Element}
 * @constructor
 */
const SvgIcon = ({
  forwardedRef,
  icon = 'generic',
  color = null,
  variant = 'outlined',
  size = 24,
  width = size,
  height = size,
  rounded,
  background,
  iconPadding = null,
  rotate = null,
  className,
}) => {
  const [src, setSrc] = useState(null)
  const classes = useStyles(styles, { background, color, padding: iconPadding, rotate })()
  const theme = useTheme()

  const iconClasses = [
    classes.icon,
    ...className ? [className] : [],
  ].join(' ')

  useEffect(() => {
    let active = true

    if (icon) {
      const url = `assets/icon/${variant ? `${variant}/` : ''}`

      active && setSrc(`${url}${icon || 'generic'}.svg`)
    }

    return () => { active = false }
  }, [icon, variant])

  return !src ? null : (
    <>
      {rounded ? (
        <Box className={classes.root}>
          <Box
            ref={forwardedRef}
            className={iconClasses}
            sx={{
              width,
              height,
              maskImage: `url(${src})`,
              backgroundColor: (color && getThemeColor(theme, color, true))
                || theme.palette.black.main,
            }}
          />
        </Box>
      ) : (
        <Box
          ref={forwardedRef}
          className={iconClasses}
          sx={{
            width,
            height,
            maskImage: `url(${src})`,
            backgroundColor: (color && getThemeColor(theme, color, true))
              || theme.palette.black.main,
          }}
        />
      )}
    </>
  )
}

/**
 * SVG Rounded Icon
 *
 * Wraps {@link SvgIcon} in a circular background. Similiar to a FAB icon.
 *
 * @type {React.FunctionComponent}
 *
 * @param {Object} iconProps
 * @param {number} [iconProps.size=40]    size of the circular background
 * @param {string} [iconProps.background] optional background color for the rounded icon.
 *                                        If missing, it will use a lightened version of
 *                                        {@param props.color}
 * @param {Object} iconProps.props        props for the icon
 * @returns {JSX.Element}
 * @constructor
 */
export const SvgRoundedIcon = ({ size = 40, background, ...props }) => {
  const classes = useStyles(roundedStyles, {
    size,
    background,
    color: props.color,
  })()

  return (
    <Box className={classes.root}>
      <SvgIcon
        {...props}
        rounded={true}
      />
    </Box>
  )
}

export const SvgFileIcon = ({ ...props }) => SvgIcon({ ...props, variant: 'file' })

export default useMemoRef(SvgIcon, props => [props.icon, props.color, props.variant, props.rotate])

/* global G */
import { curry } from 'lib/util'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'
import { change } from 'app/_shared/events/cart'

/**
 * Adds a comment to a position in the cart.
 *
 * @param {Function} callback                 callback to execute
 * @param {Gaia.AppModule.Spec} module        module composition
 * @param {Gaia.Component.Spec} component     action component
 * @param {Gaia.AppModule.EventHandler} event triggered event
 * @returns {Promise<void>}
 */
const add = async (callback, module, component, event) => {
  const { key: eventKey, name: eventName, comment: eventComment } = event?.detail || event || {}

  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { id: key = eventKey, name = eventName } = module[G.STATE][G.ACTION][G.STATE]

  const { comment } = sequenceComponentFindProxy(actionComponent)
  const commentValue = eventComment || get(comment) || null

  const data = {
    key,
    name,
    data: { comment: { value: commentValue } },
  }

  await change(module, component, data, callback, event)
}

export default curry(add)

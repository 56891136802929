/* eslint-disable no-param-reassign */
/* global G */
import { v4 as uuidV4 } from 'uuid'
import { isObj } from 'lib/util'
import sequenceModelSubmit from 'lib/sequence/model/api/submit'
import modelCreate from 'lib/sequence/model/api/create'
import modelTransform from 'lib/sequence/model/transformer'

const descriptor = Symbol('hook::model::submit').toString()

/**
 * Displays a notification with a `message`.
 *
 * @param {Gaia.AppModule.Spec} obj current module
 * @param {Object|string} message   the text to display
 */
const showNotification = (obj, message) => {
  const intl = obj[G.ADAPTER][G.INTL]
  const eventBus = obj[G.ADAPTER][G.EVENTS]
  eventBus.dispatch(eventBus.type(G.DATA, G.UPDATE), {
    duration: 5000,
    message: !isObj(message) ? message : intl._t(message._key, message),
  })
}

/**
 * Creates a new {@link Draft} object from `model`.
 *
 * @param {Gaia.Model.Spec} model
 * @return {Draft}}
 */
const createDraft = (model) => {
  const { api: type } = model[G.PROPS]
  const isRemote = !!model[G.CACHE]?._rev
  const key = model[G.STATE][G.REF] || uuidV4()

  modelCreate(model)
  modelTransform(model)

  const data = model[G.STATE][G.DATA]

  return {
    id: key,
    revision: '0',
    remoteRevision: '0',
    data: {
      ...data,
      key,
    },
    type,
    action: isRemote ? 'update' : 'create',
    save: true,
    submit: true,
    error: null,
  }
}

/**
 * Model Submit Hook.
 *
 * Executes model data remote or local submission, based on model cache and reference.
 *
 * @param {Gaia.AppModule.Spec} obj current module
 * @return {function(...[*]): Promise<*[]>}
 */
const modelSubmit = obj => async (...args) => {
  const model = obj[G.MODEL]
  const httpAdapter = model[G.ADAPTER][G.HTTP]
  const { drafts } = obj[G.ADAPTER][G.PERSISTENCE]
  const online = obj[G.ADAPTER][G.SESSION][G.STATE][G.ONLINE]
  const id = model[G.STATE][G.REF]
  const isRemote = !!model[G.CACHE]?._rev
  let error

  if (online) {
    try {
      const options = { middleware: ({ error: _, ...middleware }) => Object.values(middleware) }
      await httpAdapter.withContext(options, async () => {
        // if object is not yet remote, we have to set its _id parameter for it to keep the same id
        // we also remove it's [G.STATE][G.REF] value so that `sequenceModelSubmit` attempt to
        // create instead of updating it
        if (!isRemote) {
          model[G.STATE][G.REF] = null
          model[G.DATA]._id = id
        }
        await sequenceModelSubmit(model)
        showNotification(obj, {
          ns: 'optionManagement',
          _key: 'toast.submit',
          defaultValue: 'Password successfully submitted',
        })
      })
      // if there was a draft and the model was successfully submitted, we remove it
      id && await drafts.delete(id)
    } catch (e) {
      console.log(`${model._name} ${descriptor} - ${e.message}`)
      error = e
    } finally {
      model[G.STATE][G.REF] = id
    }
  }

  if (!online || error) {
    const draft = createDraft(model)
    try {
      await drafts.put(draft)
      showNotification(obj, {
        ns: 'optionManagement',
        _key: 'toast.save',
        defaultValue: 'Password successfully saved',
      })
    } catch (e) {
      throw Error(`${model._name} ${descriptor} - ${e.message}`)
    }
  }

  return args
}

export default modelSubmit

/* global G */
import { asyncPipeSpread } from 'lib/util'
import sequenceComponentFind from 'lib/sequence/component/children/find'
import { check, checked } from 'lib/sequence/component/state/checked'

/**
 * Initializes the `offlineMode` component with the current application's online state value.
 *
 * @param {Gaia.AppModule.Spec} module  current module
 * @return {function(*, ...[*]): *[]}
 */
const setOfflineModeField = module => (components, ...args) => {
  const online = module[G.ADAPTER][G.SESSION][G.STATE][G.ONLINE]
  const { offlineMode } = components

  !checked(offlineMode, true) && check(offlineMode, !online)

  return args
}

export default module => component => async (...args) => asyncPipeSpread(
  setOfflineModeField(module),
)(sequenceComponentFind(component), ...args)

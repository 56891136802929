/* eslint-disable no-unused-vars */
/* global G */
import { curry, setKey } from 'lib/util'

// TODO: should be moved to action/ folder

/**
 * Clean Util Event Handler
 *
 * Clears the dirty state of the current module's action.
 *
 * @param {Gaia.AppModule.Spec} module    the current module composition object
 * @param {Gaia.Component.Spec} component the current action's main component
 * @param {Event} event                   the event that triggered this handler
 * @return {Promise<object>}
 */
export default curry(async (module, component, event) => {
  try {
    const moduleState = module[G.STATE][G.ACTION][G.STATE]
    setKey(null, G.UNDO, moduleState)
  } catch (e) {
    console.error(e)
  }
})

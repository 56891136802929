/* eslint-disable guard-for-in,no-restricted-syntax */
/* global G */

/**
 * Plugin API
 *
 * Handles the management of plugins by storing them and calling their lifecycle methods.
 *
 * To initialize plugins, the init method must be called by passing to it names of plugins (to be
 * found in the plugins folder of this adapter). The adapter then proceeds to create them, adds them
 * to its G.CACHE namespace and calls their init method.
 *
 * The destroy method calls the destroy method of all initialized plugins and removes them from the
 * adapter's G.CACHE.
 *
 * The other methods in this api are lifecycle methods called at specific times in the application
 * flow. More lifecycle methods should be added as needed, or it could even be wrapped by a Proxy
 * object.
 *
 * @memberOf Gaia.Adapter.Plugin
 * @typedef {Object} PluginAPI
 * @property {function(string[]): Promise} init dynamically initializes all plugins by their name
 * @property {function(): Promise} login        calls plugins' onLogin lifecycle method
 * @property {function(): Promise} logout       calls plugins' onLogout method
 * @property {function(): Promise} destroy      calls plugins' destroy method and
 *                                              removes them from the adapter
 */

/**
 * A plugin item to be managed by this adapter.
 *
 * Used to adapt external libraries and functionalities, all its methods are optional lifecycle
 * methods called at concrete times of the application's execution.
 *
 * Its specification should be extended if more lifecycle methods are needed.
 *
 * @memberOf Gaia.Adapter.Plugin
 * @typedef {Object} Plugin
 * @property {function(): Promise} [init]      loads the plugin's script into the application
 * @property {function(): Promise} [onLogin]   actions done by the plugin when a user is logged in
 * @property {function(): Promise} [onLogout]  actions done by the plugin when a user is logged out
 * @property {function(): Promise} [destroy]   removes the plugin's script from the application
 */

/**
 * Calls {@param methodName} on all currently initialized plugins.
 *
 * @param {Gaia.Web.Application} obj  the web platform application
 * @param {string} methodName         the name of the method to call
 * @param {any} [args]                the method's arguments
 */
const dispatchLifecycle = async (obj, methodName, args) => {
  const plugins = obj[G.ADAPTER][G.PLUGIN][G.CACHE]
  for (const pluginName in plugins) {
    try {
      const plugin = plugins[pluginName]
      await plugin[methodName]?.(args)
    } catch (error) {
      console.error(error)
    }
  }
}

/**
 * Returns an implementation of the {@link PluginAPI}.
 *
 * @param {Gaia.Web.Application} obj  the web platform application
 * @returns {PluginAPI} api - plugin api
 */
const api = obj => Object.create({}, {
  init: {
    value: async (pluginNames) => {
      const plugins = obj[G.ADAPTER][G.PLUGIN][G.CACHE]

      for (let i = 0; i < pluginNames.length; i++) {
        const pluginName = pluginNames[i]
        if (!plugins[pluginName]) {
          try {
            const factory = await import(`@platform/adapter/plugin/plugins/${pluginName}`)
            const plugin = factory.default(obj)
            await plugin?.init()
            plugins[pluginName] = plugin
          } catch (error) {
            console.warn(`Plugin "${pluginName}" not initialized:`, error)
          }
        } else {
          console.warn(`Plugin "${pluginName}" already initialized`)
        }
      }
    },
    iterable: true,
    enumerable: true,
  },
  login: {
    value: async () => {
      await dispatchLifecycle(obj, 'onLogin')
    },
    iterable: true,
    enumerable: true,
  },
  logout: {
    value: async () => {
      await dispatchLifecycle(obj, 'onLogout')
    },
    iterable: true,
    enumerable: true,
  },
  destroy: {
    value: async () => {
      const plugins = obj[G.ADAPTER][G.PLUGIN][G.CACHE]
      for (const pluginName in plugins) {
        const plugin = plugins[pluginName]
        try {
          await plugin.destroy?.()
          delete plugins[pluginName]
        } catch (error) {
          console.warn(error)
        }
      }
    },
    iterable: true,
    enumerable: true,
  },
})

export default api

/* global G, React */
import { curry } from '@gaia/util'

/**
 *
 * @param rootGetter
 * @param {Gaia.Web} app - {@link Gaia.Web}
 * @param {Gaia.AppModule.Spec} obj - {@link Gaia.AppModule.Spec}
 * @return {Promise<void>} fire and forget
 */
const renderFn = async (rootGetter, app, obj) => {
  const webApp = app

  const props = {
    children: [],
    session: webApp[G.SESSION],
    eventBus: webApp[G.ADAPTER][G.EVENTS],
    navigation: webApp[G.CONFIGURATION].defaults.navigation, // app.json5 defaults
    menu: webApp[G.CONFIGURATION].defaults.menu,
    links: webApp[G.CONFIGURATION].defaults.links,
    router: webApp[G.ADAPTER][G.ROUTER][G.API],
    acl: webApp[G.ADAPTER][G.ACL][G.API],
    intl: webApp[G.ADAPTER][G.INTL],
    socket: webApp[G.ADAPTER][G.PUBSUB],
    maxPreviewSize: webApp[G.CONFIGURATION].defaults.maxPreviewSize,
    previewableTypes: webApp[G.CONFIGURATION].defaults.previewableTypes,
  }

  const view = React.forwardRef(obj[G.VIEW])
  view.displayName = 'Gaia'

  await rootGetter(
    view,
    props,
    (ref) => {
      // provide rendered context reference to app (ui), for further usage.
      webApp[G.REF] = ref.current
    },
  )
}

export default curry(renderFn)

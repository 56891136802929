/* eslint-disable no-unused-vars,no-param-reassign */
/* global G, PLATFORM */
import settings from '@tenant/settings'
import { asyncPipeSpread, asyncPipeSpreadIf, bulk, getFirstItem } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import map from 'lib/sequence/model/api/map'
import { get, set } from 'lib/sequence/component/state/value'
import { hide, show } from 'lib/sequence/component/state/hidden'
import { displayFavoriteButtonsFromAttribute } from 'app/_shared/action/partial/favorite/display'
import asObject from 'lib/sequence/component/children/asObject'

/**
 * Retrieves the article from the search and maps it to reflect the actual database structure.
 * Sets G.MODELs (and all sub-models) G.CACHE afterward.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const mapProperties = module => async (components, ...args) => {
  // model
  const model = module[G.MODEL][G.CHILDREN]?.article || {}

  // component
  const { title, descriptions, details, properties, additionalProperties } = components

  const bulkMapModel = bulk(map(model))
  bulkMapModel(title, descriptions, details, properties, additionalProperties)

  return [components, ...args]
}

/**
 * Sets the icon of the part.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setIcons = module => async (components, ...args) => {
  const { documentationTreeFilter = null, documentationTreeNodeIcons = null } = settings
  const { installedIn } = components
  const state = installedIn[G.STATE]
  const locations = state.value

  if (documentationTreeFilter && documentationTreeNodeIcons) {
    const icons = documentationTreeNodeIcons
      .filter(icon => icon.nodeType === 'product')
      .reduce((acc, icon) => {
        acc[icon.name] = icon.icon
        return acc
      }, {})

    locations.filter(location => icons[location.productType]).forEach((location) => {
      location.icon = icons[location.productType]
    })
  }

  return [components, ...args]
}

/**
 * Sets the image of the part.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setImage = module => async (components, ...args) => {
  const model = module[G.MODEL][G.CHILDREN]?.article || {}
  const imageCache = model?.[G.CACHE]?.image || {}
  const attachmentId = model?.[G.CACHE]?.value?.attachmentId || null
  const { image, mobileImage, mobileImageContainer } = components

  const targetImage = PLATFORM === 'web' ? image : mobileImage

  const partImage = getFirstItem(imageCache)

  if (attachmentId) {
    targetImage[G.PROPS].attachment = {
      key: attachmentId,
      value: partImage.value,
    }
  } else {
    PLATFORM === 'mobile' && hide(mobileImageContainer)
  }

  return [components, ...args]
}

/**
 * Joins the description array into a string.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayDescription = module => async (components, ...args) => {
  const { properties } = components
  const { description } = asObject(properties[G.CHILDREN])

  const descriptionValue = get(description)
  set(description, descriptionValue.join(', '))

  return [components, ...args]
}

/**
 * Hides the product property if it has no value
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayProduct = module => async (components, ...args) => {
  const { properties } = components
  const { product } = asObject(properties[G.CHILDREN])

  !get(product) && hide(product)

  return [components, ...args]
}

/**
 * Disables an changes the label of the 'add to cart' button if the part is not orderable
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const displayOrderButton = module => async (components, ...args) => {
  const model = module[G.MODEL][G.CHILDREN]?.article
  const orderable = model[G.CACHE]?.value?.orderable
  const { addToCart, notOrderableBtn } = components

  // Explicitly check for false here
  if (orderable === false) {
    hide(addToCart)
    show(notOrderableBtn)
  }

  return [components, ...args]
}

/**
 * EquipmentInformation Part Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => () => async (...args) => asyncPipeSpread(
  mapProperties(module),
  displayDescription(module),
  displayOrderButton(module),
  setImage(module),
  asyncPipeSpreadIf(() => PLATFORM === 'web')(
    setIcons(module),
    displayProduct(module),
  ),
  displayFavoriteButtonsFromAttribute('article')(module),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)

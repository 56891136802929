/* global G */
import { curry } from 'lib/util'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import routeComposition from 'trait/composition/route'

/**
 * Redirect To Comment Options Event Handler
 *
 * Will redirect to the route defined as `route.comment.options` in the components' configuration.
 * Will pass it the `id`, `name` and `comment` provided by the event.
 *
 * NOTE: Only used on mobile.
 *
 * @param {Gaia.AppModule.Spec} module        module composition
 * @param {Gaia.Component.Spec} component     action component
 * @param {Gaia.AppModule.EventHandler} event triggered event
 * @returns {Promise<void>}
 */
const redirectToCommentOptions = async (module, component, event) => {
  const { route: { comment: { options } = {} } = {} } = component[G.CONFIGURATION]
  const { key, name, comment } = event?.detail || event || {}

  const moduleState = module[G.STATE]
  moduleState[G.ROUTE] = routeComposition(options.module, options.action)

  await redirectSequence(module)({ key, name, comment })
}

export default curry(redirectToCommentOptions)

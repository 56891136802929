/* global G */

/**
 * Instructs the attachment adapter to clear all cached attachments.
 *
 * @param {Gaia.Web.Application} obj - native application
 * @returns {function(*): Promise<*>}
 */
export default obj => (args) => {
  const eventBus = obj[G.ADAPTER][G.EVENTS]
  eventBus.dispatch(eventBus.type(G.ATTACHMENT, G.CLEAN))

  return args
}

/* eslint-disable no-unused-vars */
import { Grid, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import SvgIcon from 'ui/Element/Icon/Svg'

/**
 * Intended to display a chevron for a cart.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellCartChevron = (classes, props = {}) => {
  const theme = useTheme()
  const { chevronIcon } = props

  return (
    <Grid
      item
      container
      direction={'row-reverse'}
    >
      <SvgIcon
        {...chevronIcon}
        color={theme.palette.text.secondary}
      />
    </Grid>
  )
}

export default CellHOC(ListItemCellCartChevron)

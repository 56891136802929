/* global G */
import { curry } from 'lib/util'
import redirectSequence from 'lib/sequence/module/adapter/router/redirect'
import routeComposition from 'trait/composition/route'

/**
 * Redirect To Comment Add Event Handler
 *
 * Redirects to the route defined as `route.comment.add` in the components' configuration.
 * Will pass it the `id`, `name` and `comment`, either coming from the incoming event or
 * from the action state.
 *
 * This handler is used both for adding a comment to a cart position by clicking the
 * `Add comment` button and editing an existing comment by clicking the `Edit comment`
 * button in the comment options bottom sheet.
 *
 * In the first case, it'll use the relevant information coming from the event. In the
 * second case, the information will already be stored in the action state and will be
 * taken from there.
 *
 * NOTE: Only used on mobile.
 *
 * @param {Gaia.AppModule.Spec} module        module composition
 * @param {Gaia.Component.Spec} component     action component
 * @param {Gaia.AppModule.EventHandler} event triggered event
 * @returns {Promise<void>}
 */
const redirectToCommentAdd = async (module, component, event) => {
  const { route: { comment: { add } = {} } = {} } = component[G.CONFIGURATION]
  const moduleState = module[G.STATE]
  const actionState = moduleState[G.ACTION][G.STATE]

  const { key: eventId, name: eventName, comment: eventComment } = event?.detail || event
  const { key: actionId, name: actionName, comment: actionComment } = actionState

  const key = eventId || actionId
  const name = eventName || actionName
  const comment = eventComment || actionComment

  moduleState[G.ROUTE] = routeComposition(add.module, add.action)

  await redirectSequence(module)({ key, name, comment })
}

export default curry(redirectToCommentAdd)

/* global G */
import { curry, getFirstItem, isArr, isObj, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { get } from 'lib/sequence/component/state/value'
import { checked } from 'lib/sequence/component/state/checked'
import search from 'app/_shared/events/search'
import { empty } from 'lib/util/object'
import showDialog, { showBlockingDialog } from 'app/_shared/events/dialog'
import { actionWithHooks } from 'lib/sequence/module/action'

/**
 * Checks whether `ref` of `source` is still to be  validated.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @returns {function(*, *): Promise<boolean>}
 */
const checkSubItems = module => async (source, ref) => {
  const model = module[G.MODEL]
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]

  const result = await search(null, module, actionComponent, {
    type: source,
    filter: { [ref]: model[G.STATE][G.REF] },
  })

  const hasInvalidatedItems = isArr(result)
    ? result
      .reduce((acc, key) => {
        const toBeValidated = key?.value?.toBeValidated
        return toBeValidated && isObj(toBeValidated) && !empty(toBeValidated)
          ? true
          : acc
      }, false)
    : false

  return !hasInvalidatedItems
}

/**
 * Displays a confirmation dialog if `parent` has changed. If not, attempts to save the
 * organisation and also shows a confirmation dialog if the user wants to publish the organisation
 * to the master data.
 *
 * @param {Gaia.AppModule.Spec} module  the current module composition object
 * @param {Gaia.Component.Spec} component  the current action's main component
 * @param {Event} event                 the event object that triggered this handler
 * @returns {Promise<void>}
 */
const submitIfConfirmed = async (module, component, event) => {
  // model
  const model = module[G.MODEL]
  const { parent: parentModel } = model[G.CHILDREN]
  const parentCache = getFirstItem(parentModel[G.CACHE])
  const currentParent = parentCache?.key || null

  // component
  const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
  const { parent } = find(actionComponent)
  const { publishToMasterData } = find(actionComponent[G.ACTIONS][0])
  const newParent = getFirstItem(get(parent))?.key || null
  const parentChanged = newParent !== currentParent

  // Show confirmation dialog if parent changed
  const confirmedParentChange = parentChanged ? await parent[G.EVENTS].dialog?.(event) : true

  // Reload UI if dialog has been cancelled
  if (!confirmedParentChange) {
    module[G.ADAPTER][G.UI].create(module)
    return
  }

  // Submit if publishToMasterData has not been checked
  if (!checked(publishToMasterData)) {
    await actionWithHooks(module[G.ACTIONS].submit)()
    return
  }

  // Determine whether we need to show a confirmation dialog
  const showConfirmationDialog = await checkSubItems(module)('organisation', 'parent')
    && await checkSubItems(module)('serviceitem', 'installedAt')
    && await checkSubItems(module)('person', 'organisation')

  // Show confirmation dialog if all subitems are ok
  const confirmedConfirmationDialog = showConfirmationDialog
    ? await showBlockingDialog(module, component, {
      title: {
        ns: 'organisation',
        key: 'dialog.publishToMasterConfirm.title',
        defaultValue: 'Confirm approval',
      },
      text: {
        ns: 'organisation',
        key: 'dialog.publishToMasterConfirm.text',
        defaultValue: 'Organisation with related data will be published to master data. Please note that you might still have to grant regular roles to associated users.',
      },
    })
    : false

  // show info dialog if subitems have toBeValidated
  !showConfirmationDialog && showDialog(module, component, {
    title: {
      ns: 'organisation',
      key: 'dialog.publishToMasterError.title',
      defaultValue: 'Error unverified data',
    },
    text: {
      ns: 'organisation',
      key: 'dialog.publishToMasterError.text',
      defaultValue: 'Approval requires the organization, all sublocations, machines, invited contacts and users to be verified. Please check all assiated data and verify.',
    },
  })

  confirmedConfirmationDialog
    && setKey(true, 'verified', module[G.STATE])
    && await actionWithHooks(module[G.ACTIONS].submit)()
}

export default curry(submitIfConfirmed)

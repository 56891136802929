/* global G */

const INIT_KEY = 'yzgl9rmyge'

/**
 * Abstracts the handling of the Userlane script and makes its usage completely optional.
 *
 * {@see https://docs.userlane.com/docs/api-quick-reference}
 * @returns {Gaia.Adapter.Plugin.Plugin} api - storage api
 */
const userlane = obj => Object.create({}, {
  init: {
    /**
     * Runs the Userlane initialization snippet.
     */
    value: async () => new Promise((resolve, reject) => {
      if (!window.UserlaneCommandObject) {
        window.UserlaneCommandObject = 'Userlane'

        window.Userlane = window.Userlane || ((...args) => {
          (window.Userlane.q = window.Userlane.q || []).push(...args)
        })

        const script = document.createElement('script')
        const documentScript = document.getElementsByTagName('script')[0]

        script.async = 1
        script.src = 'https://cdn.userlane.com/userlane.js'
        script.id = 'userlane-script'
        script.onload = resolve
        script.onerror = reject
        documentScript.parentNode.insertBefore(script, documentScript)
      }
      resolve()
    }),
    iterable: true,
    enumerable: true,
  },
  onLogin: {
    /**
     * Runs the Userlane identify and init commands.
     */
    value: () => {
      const session = obj[G.SESSION][G.STATE][G.META]
      const [user] = session.refs.user
      // Identify user
      window.Userlane('identify', user.value.name)
      // Initialize library
      window.Userlane('init', INIT_KEY)
    },
    iterable: true,
    enumerable: true,
  },
  onLogout: {
    /**
     * Runs the Userlane hide command.
     */
    value: () => {
      window.Userlane('hide')
    },
    iterable: true,
    enumerable: true,
  },
  destroy: {
    /**
     * Removes the objects created by the init method.
     */
    value: async () => {
      if (window.UserlaneCommandObject) {
        window.Userlane('hide')
        const script = document.getElementById('userlane-script')
        script.remove()

        delete window.UserlaneCommandObject
        delete window.Userlane
      }
    },
    iterable: true,
    enumerable: true,
  },
})

export default userlane

import { curry } from 'lib/util'
import { remove } from 'app/_shared/events/cart'

/**
 * Removes all positions from the cart.
 *
 * @param {Function} callback                 callback to execute
 * @param {Gaia.AppModule.Spec} module        module composition
 * @param {Gaia.Component.Spec} component     action component
 * @param {Gaia.AppModule.EventHandler} event triggered event
 * @returns {Promise<void>}
 */
const removeAll = async (callback, module, component, event) => {
  const data = { all: true }

  await remove(module, component, data, callback, event)
}

export default curry(removeAll)

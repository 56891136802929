/* eslint-disable no-param-reassign */
/* global React, G */
import { Children, cloneElement, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Badge, Box, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useStyles } from '@platform/react/hook'
import ErrorBoundary from 'ui/Error'
import SvgIcon from 'ui/Element/Icon/Svg'
import ApplicationContext from '@platform/react/context/application'
import Autocomplete from 'ui/Component/Field/Autocomplete'
import { isArr, PlatformEvent } from 'lib/util'
import { empty } from 'lib/util/object'

const styles = (theme, { space, totalHeight, headerHeight, totalWidth, widthState }) => ({
  root: {
    margin: 0,
    width: '100%',
    height: '100vh',
    background: theme.palette.background.dark,

    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      overflow: 'scroll',
    },
  },
  content: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      height: `calc(${totalHeight}px - ${theme.spacing(space * 1)})`,
    },
  },
  headerContainer: {
    padding: `${theme.spacing(space * 2)}!important`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& > :last-child': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '2rem', // 20px
    fontWeight: 700,
    color: theme.palette.common.black,
  },
  closeButton: {
    width: '2.5rem', // 40px
    height: '2.5rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  cartButton: {
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  cartBadge: {
    marginRight: '0.5rem',
  },
  searchField: {
    height: '2.5rem', // 40px
    width: '18.75rem', // 300px
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderRadius: '1.25rem', // 20px
    boxShadow: 'none',
    marginRight: theme.spacing(space),
  },
  searchIcon: {
    marginLeft: '0.625rem', // 10px
    marginRight: '0.625rem',
    color: theme.palette.common.black,
  },
  autocomplete: {
    padding: theme.spacing(1),
    paddingLeft: 0,
  },
  popper: {
    marginTop: '10px!important',
    [theme.breakpoints.up('md')]: {
      width: '21.875rem!important', // 350px
    },
  },
  tree: {
    padding: `${theme.spacing(space * 2)}!important`,
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('md')]: {
      height: `calc(${totalHeight}px - ${theme.spacing(space * 1)})!important`,
    },
  },
  header: {
    display: 'flex',
    height: headerHeight,
  },
  partsListHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  partsListContainer: {
    [theme.breakpoints.up('md')]: {
      height: `calc(${totalHeight}px - ${headerHeight}px - 2rem)`,
    },
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none', // prevents text selection when dragging
  },
  partsList: {
    ...widthState?.delta
      ? { width: `calc(${totalWidth}px ${widthState?.directionLeft} ${widthState.delta} - ${theme.spacing(space * 2)})!important` }
      : { width: '100%' },
    height: '100%',
    overflow: 'scroll',
    borderRadius: '0.5rem', // 8px
    backgroundColor: theme.palette.common.white,
  },
  explodedDiagramContainer: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      height: `calc(${totalHeight}px - ${headerHeight}px - 2rem)`,
    },
  },
  explodedDiagram: {
    ...widthState?.delta
      ? { width: `calc(${totalWidth}px ${widthState?.directionRight} ${widthState.delta} - ${theme.spacing(space * 2)})!important` }
      : { width: '100%' },
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: '0.5rem', // 8px
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  breadcrumbs: {},
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div:first-child': {
      marginRight: '0.75rem', // 12px
      '& > *': { // rotating arrow icon
        transform: 'rotate(180deg)',
        cursor: 'pointer',
      },
    },
  },
  title: {
    color: theme.palette.common.black,
  },
  ...theme.custom.hotspot,
})

/**
 *
 * @param {Object} node         the node to display the name of
 * @param {Object} props        additional props
 * @returns {null|JSX.Element}
 * @constructor
 */
const Name = ({ node, ...props }) => {
  const { classes } = props

  const title = node && node?.type === 'File' ? node?.parent?.name : node?.name

  return !title ? null : (
    <Typography
      className={classes.title}
      variant={'24/medium'}
    >
      {title}
    </Typography>
  )
}

/**
 * Component to display the title of a node
 *
 * @param {Object} node       the node to display the title of
 * @param {Object[]} children children of the node
 * @param {Object} events     events for the node
 * @param {Object} props      additional props
 * @returns {JSX.Element}
 * @constructor
 */
const Title = ({ node, children, events, ...props }) => {
  const { classes, spacing, space, gap } = props
  const { onClick } = events

  const handleClick = (event) => {
    const clickEvent = new PlatformEvent(event, { id: node.parent.id })
    onClick?.(clickEvent)
  }

  const theme = useTheme()

  return (
    <Grid
      item
      container
      xs={12}
      className={classes.root}
      style={{
        padding: spacing
          ? theme.spacing(spacing)
          : theme.spacing(space, gap),
      }}
    >
      <Grid
        item
        sm={6}
        xs={12}
        className={classes.partsListHeader}
      >
        <Box className={classes.titleContainer}>
          {node?.parent?.id && (
            <Box onClick={handleClick}>
              <SvgIcon
                rounded={true}
                width={'1.5rem'}
                height={'1.5rem'}
                iconPadding={'0.5rem'}
                variant={'filled'}
                icon={'arrow_right'}
                background={theme.palette.common.white}
                color={theme.palette.common.black}
              />
            </Box>
          )}
          <Name node={node} classes={classes} />
        </Box>
      </Grid>
    </Grid>
  )
}

/**
 * Hotspot component
 *
 * This is a wrapper component around {@link FileTree}, {@link PartsList} and {@link Exploded}
 * that holds and controls the shared state.
 *
 * @param {Number} spacing                spacing to use between child components
 * @param {Object} events                 provided events
 * @param {Function} events.onOpen        handler responsible for fetching the document tree
 * @param {Function} events.onNode        handler responsible for clicking on a node.
 *                                        Will be passed as {@code onClick} to child components
 * @param {Object} props                  additional props
 * @param {React.Ref} ref                 forwarded ref
 * @returns {JSX.Element}
 * @constructor
 */
const Hotspot = ({ forwardedRef, events, ...props }, ref) => {
  const { onNode, onNodeType, onTreeSearch, onNodeHierarchy, onCart } = events
  const [nodeTree, setNodeTree] = useState({})
  const [selectedNode, setSelectedNode] = useState(null)
  const [siblingNodes, setSiblingNodes] = useState([])
  const [expandedNodes, setExpandedNodes] = useState([])
  const [cartPositions, setCartPositions] = useState(0)
  const [fromSearch, setFromSearch] = useState(false)
  const [height, setHeight] = useState(null)

  const [dragWidth, setDragWidth] = useState(0)
  const [dragState, setDragState] = useState({})

  const dragRef = useRef()

  const {
    session: { [G.MODULE]: { [G.STATE]: { [G.ACTION]: action } } },
    eventBus,
  } = useContext(ApplicationContext)

  // Getting the modals onClose handler to display close button
  const onModalClose = action[G.COMPONENT][G.EVENTS]?.onClose

  // Dynamically calculating the height based on the parents height
  const resizeHandler = () => {
    const refEl = ref?.current

    const children = refEl?.childNodes
    const contentWidth = children?.[children.length - 1]?.clientWidth

    // Resetting everything regarding drag when we resize the window
    setDragWidth(contentWidth / 2 - 16)
    setDragState({ translateDistance: { x: 0 } })
    dragRef.current = { x: 0 }

    refEl?.firstChild && setHeight(refEl.clientHeight - refEl.firstChild.clientHeight)
  }

  // Recalculating height if we resize the window
  useEffect(() => {
    window.addEventListener('resize', resizeHandler)

    return () => { window.removeEventListener('resize', resizeHandler) }
  }, [])

  // Initially set the correct height once the DOM's ready
  useEffect(() => { resizeHandler() }, [ref?.current])

  // Getting the cart
  useEffect(() => {
    eventBus.add(eventBus.type(G.CART, G.DONE), ({ detail }) => {
      const { [G.DATA]: currentCart } = detail
      const totalPositions = currentCart?.value?.positions?.reduce((acc, key) => acc + key.amount, 0)
      setCartPositions(totalPositions)
    })

    eventBus.dispatch(eventBus.type(G.CART, G.READ))
  }, [])

  const {
    spacing = 0,
    gap = 0,
    space = 0,
    headerHeight = 70,

    optionLabels,
    partLabel,
    assemblyLabel,
    assembledInLabel,

    showCart = true,

    searchIcon,
    cartIcon,
    view,
  } = props

  const options = { spacing, gap, space }
  const labels = { assemblyLabel, partLabel, assembledInLabel }

  const autocompleteRef = useRef(null)
  const SearchField = useMemo(() => React.forwardRef(Autocomplete), [])

  const widthState = useMemo(() => ({
    delta: !dragState?.translateDistance?.x ? 0 : `${Math.abs(dragState?.translateDistance?.x)}px`,
    directionLeft: dragState?.translateDistance?.x < 0 ? '-' : '+',
    directionRight: dragState?.translateDistance?.x < 0 ? '+' : '-',
  }), [dragState])

  const classes = useStyles(styles, {
    totalWidth: dragWidth,
    widthState,
    space: props.space,
    totalHeight: height,
    headerHeight,
  })()

  const setSingleNode = (id) => {
    const node = onNode({ node: nodeTree, nodeId: id })
    const nodeType = onNodeType(node.type)
    selectedNode.id !== id && setSelectedNode({ ...node, type: nodeType })

    return node
  }

  // Handling clicking on a node
  const handleClick = (event) => {
    const { id: idOrIds = null } = event?.detail || {}
    if (isArr(idOrIds)) {
      setSiblingNodes(idOrIds.map((siblingNode) => {
        const foundSibling = onNode({ node: nodeTree, nodeId: siblingNode })
        return {
          ...foundSibling,
          type: onNodeType(foundSibling.type),
        }
      }))

      return setSingleNode(idOrIds[0])
    }
    setSiblingNodes([])
    return setSingleNode(idOrIds)
  }

  // Handling searching in the tree
  const handleFilter = async (e) => {
    if (!e?.detail?.term) return []

    const value = e?.detail?.term || ''
    const partslist = await onTreeSearch({ node: nodeTree, value })

    // Adding labels an key to each result
    return partslist.result.map((part, i) => ({ ...part, key: i }))
  }

  // Handling clicking on an option in the search autocomplete
  const handleSearchedNode = (e) => {
    const item = e.detail?.item || {}
    if (item.id) {
      setFromSearch(true)
      // setting the node
      const clickEvent = new PlatformEvent(e, { id: item.id })
      handleClick?.(clickEvent)

      // Getting the hierarchy (all parents that led to ths node) and expanding the tree
      const hierarchy = onNodeHierarchy({ node: item })
      setExpandedNodes(hierarchy)
    }
  }

  // Setting the tree
  useEffect(() => {
    (async () => {
      const { tree: newTree, node: initialNode } = props?.value && !empty(props.value)
        ? props.value
        : await events?.onOpen?.(null) || {}
      if (newTree) {
        const completeTree = events?.onTree?.({ node: newTree })
        setNodeTree(completeTree)

        // Set root node as selected node initially.
        setSelectedNode(initialNode || completeTree)
      }
    })()
  }, [])

  // Handling the start of dragging the center icon
  const handleDragStart = (e) => {
    // setting a dummy drag image so that we on't see a copy of the icon being dragged around
    // this image will get removed automatically when done dragging
    const dragImage = document.createElement('img')
    e.dataTransfer.setDragImage(dragImage, 0, 0)
    dragRef.current = { ...dragRef.current, x: e.clientX }
  }

  // Handling dragging the center icon
  const handleDrag = (e) => {
    if (e.clientX > 0 && e.clientY > 0) {
      const deltaX = e.clientX - dragRef.current.x + (dragRef?.current?.deltaX || 0)

      dragRef.current = {
        ...dragRef.current,
        offsetX: deltaX,
      }

      setDragState(prevState => ({
        ...prevState,
        translateDistance: { x: deltaX },
      }))
    }
  }

  // Handling the end of dragging the center icon
  const handleDragEnd = (e) => {
    dragRef.current = {
      deltaX: dragRef?.current?.offsetX,
      x: e.clientX,
    }
  }
  useEffect(() => {
    events?.onChange?.(new PlatformEvent('change', { value: { tree: nodeTree, node: selectedNode } }))
  }, [nodeTree, selectedNode])

  const theme = useTheme()
  const isSm = useMediaQuery(t => t.breakpoints.down('md'))

  return (
    <ErrorBoundary>
      <Grid
        item
        container
        ref={ref}
        className={classes.root}
        xs={props.xs}
        sm={props.sm}
        md={props.md}
        lg={props.lg}
        xl={props.xl}
      >
        <Grid
          xs={12}
          item
          container
          className={classes.headerContainer}
        >
          <Grid
            item
            sm={6}
            xs={12}
            className={classes.headerTitle}
          >
            {nodeTree.name}
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}>
            <Paper className={classes.searchField}>
              <Box className={classes.searchIcon}>
                <SvgIcon
                  icon={searchIcon.name}
                  variant={searchIcon.variant}
                />
              </Box>
              <SearchField
                optionLabels={optionLabels}
                classes={{
                  root: classes.autocomplete,
                  popper: classes.popper,
                }}
                view={view}
                inputVariant={'basic'}
                freeText={true}
                oneShot={false}
                fullWidth={true}
                noDataLabel={props.noDataLabel}
                ref={autocompleteRef}
                events={{
                  onOpen: handleFilter,
                  onChange: handleSearchedNode,
                }}
                {...labels}
              />
            </Paper>
            {showCart && (
              <Badge
                badgeContent={cartPositions || 0}
                color={'signal'}
                className={classes.cartBadge}
              >
                <Paper
                  variant={'outlined'}
                  aria-label={'cart'}
                  onClick={onCart}
                  className={classes.cartButton}
                >
                  <SvgIcon
                    icon={cartIcon.name}
                    variant={cartIcon.variant}
                  />
                </Paper>
              </Badge>
            )}
            {onModalClose && (
              <Paper
                variant={'outlined'}
                aria-label={'close'}
                onClick={onModalClose}
                className={classes.closeButton}
              >
                <SvgIcon
                  icon={'close'}
                  variant={'filled'}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
        {Children.map(props.children, child => (
          child.props.hidden || child.key !== 'tree' ? null : cloneElement(child, {
            nodes: nodeTree,
            selected: selectedNode,
            expanded: expandedNodes,
            setExpanded: setExpandedNodes,
            fromSearch,
            setFromSearch,
            defaultSelected: selectedNode,
            className: classes.tree,
            events: {
              onNodeType: events.onNodeType,
              onClick: handleClick,
            },
            ...options,
          })
        ))}
        <Grid
          item
          container
          xs
          className={classes.content}
          style={{
            padding: spacing
              ? theme.spacing(spacing)
              : theme.spacing(space, gap),
          }}
        >
          <Title
            node={selectedNode}
            children={props.childrenObject}
            classes={{
              root: classes.header,
              partsListHeader: classes.partsListHeader,
              breadcrumbs: classes.breadcrumbs,
              titleContainer: classes.titleContainer,
              title: classes.title,
            }}
            events={{
              onClick: handleClick,
            }}
            {...options}
          />
          {Children.map(props.children, child => (
            child.props.hidden || child.key !== 'partslist' ? null : cloneElement(child, {
              node: selectedNode,
              siblings: siblingNodes,
              widthDelta: dragState?.translateDistance?.x || 0,
              classes: {
                root: classes.partsListContainer,
                partsList: classes.partsList,
              },
              events: {
                ...child.props.events,
                onNodeType: events.onNodeType,
                onAddPart: events.onAddPart,
                onDocumentation: events.onDocumentation,
                onClick: handleClick,
              },
              ...options,
            })
          ))}
          <Box
            hidden={isSm}
            draggable={true}
            onDrag={handleDrag}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            sx={{
              position: 'absolute',
              top: '50%',
              ...dragState?.translateDistance?.x
                ? { left: dragWidth + dragState.translateDistance.x - 8 }
                : { left: dragWidth },
              zIndex: 100,
              '& > div': {
                padding: '0.5rem',
              },
            }}
          >
            <SvgIcon
              icon={'code_open'}
              size={'1rem'}
              variant={'filled'}
              color={'white'}
              background={'black'}
              rounded={true}
            />
          </Box>
          {Children.map(props.children, child => (
            child.props.hidden || child.key !== 'exploded' ? null : cloneElement(child, {
              node: selectedNode,
              widthDelta: dragState?.translateDistance?.x || 0,
              classes: {
                root: classes.explodedDiagramContainer,
                explodedDiagram: classes.explodedDiagram,
              },
              events: {
                onDrag: handleDrag,
                onDragStart: handleDragStart,
                onDragEnd: handleDragEnd,
                onNodeType: events.onNodeType,
                onClick: handleClick,
              },
              ...options,
            })
          ))}
        </Grid>
      </Grid>
    </ErrorBoundary>

  )
}

export default Hotspot

/* eslint-disable no-unused-vars */
/* global G */
import { asyncPipeSpread } from 'lib/util'
import find from 'lib/sequence/component/children/find'
import { setPasswordInfo } from 'app/optionManagement/action/create'
import { displayPasswordSection } from 'app/optionManagement/action/detail'

/**
 * OptionManagement edit action
 *
 * @param {Gaia.AppModule.Spec} module  current module
 */
export default module => component => async (...args) => asyncPipeSpread(
  setPasswordInfo(module),
  displayPasswordSection(module),
)(find(component), ...args)

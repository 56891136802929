import AvatarImage from 'ui/Element/Image/Avatar'
import { Badge, Box, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import settings from '@tenant/settings'

const styles = theme => ({
  root: {
    width: theme.typography.pxToRem(theme.typography.fontSize * 3),
    height: theme.typography.pxToRem(theme.typography.fontSize * 3),
    marginRight: theme.spacing(1),
    display: 'flex',
  },
  image: {
    maxWidth: '40px',
    width: '100%',
    height: '100%',
  },
})

/**
 * Helper function to get the icon for the current option from the tenant settings.
 *
 * @param {string} nodeType type of documentation
 * @param {string} name     name of the documentation
 * @returns {*|null}
 * @private
 */
const _getOptionIcon = ({ nodeType, name }) => {
  const availableIcons = settings?.documentationTreeNodeIcons
  if (!nodeType || !name || !availableIcons?.length) return null

  const targetIcon = availableIcons.find(icon => icon.name === name && icon.nodeType === nodeType)

  return targetIcon?.icon || null
}

/**
 * Displays the icon of the equipment ot part
 *
 * @param {Object} classes  styles for the component
 * @param {Object} props    props for the component
 * @return {JSX.Element}
 * @constructor
 */
const ListItemCellEquipmentInformationIcon = (classes, props = {}) => {
  const theme = useTheme()
  const {
    image,
    type,
    partIcon,
    equipmentIcon,
    docChunkIcon,
    documentationType,
    productType,
    more = 0,
  } = props

  const targetType = type
    ? ((type === 'docChunk' || type === 'documentation') && { name: documentationType, nodeType: 'documentation' })
      || (type === 'equipment' && { name: productType, nodeType: 'product' })
    : null
  const targetIcon = targetType ? _getOptionIcon(targetType) : null

  const currentAttachment = image?.attachment?.value?.name
    ? { ...image.attachment, key: image?.value?.attachmentId || image?.id || undefined, api: { ref: 'equipment' } }
    : { value: { name: '' } }

  const fallbackIcon = (type === 'article' && partIcon)
    || (type === 'equipment' && equipmentIcon)
    || (type === 'docChunk' && docChunkIcon)
    || (type === 'documentation' && docChunkIcon)

  return (
    <Box className={classes.root}>
      <Badge
        color={'info'}
        badgeContent={more > 0 ? `+${more}` : 0}
        className={classes.image}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <AvatarImage
          attachment={currentAttachment}
          fallback={{
            ...targetIcon
              ? {
                icon: targetIcon.name,
                variant: targetIcon.variant,
              }
              : {
                icon: fallbackIcon,
                variant: 'outlined',
              },
            color: theme.palette.text.secondary,
          }}
        />
      </Badge>
    </Box>
  )
}

export default CellHOC(ListItemCellEquipmentInformationIcon, styles)

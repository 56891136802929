/* eslint-disable no-param-reassign */
/* global G */
import { getFirstItem, isObj } from 'lib/util'

/**
 * Returns the timestamp as a localized date using `dateStyle`.
 *
 * @param {object|string} dateStyle style the date should be returned as or an object defining
*                                   the locale options
 * @param {string} sourceKey        the name of the attribute to obtain the data from
 * @param {string} targetKey        the name of the attribute to store the data to
 * @return {*}
 */
const fn = (dateStyle, sourceKey, targetKey = sourceKey) => (obj, component) => {
  const sourceAttribute = obj[G.CHILDREN][sourceKey]
  const targetAttribute = obj[G.CHILDREN][targetKey]
  const value = getFirstItem(sourceAttribute[G.CACHE]) || null
  const options = isObj(dateStyle) ? dateStyle : { dateStyle }

  targetAttribute[G.CACHE] = !value ? '-' : obj[G.ADAPTER][G.INTL].getLocaleDate(value, options)

  return component
}

export default fn

import persistChange from './change'
import persistCheck from './check'
import persistOptions from './options'
import persistOption from './option'
import persistItem from './item'
import persistScroll from './scroll'

const persistEvents = {
  persistChange,
  persistCheck,
  persistOptions,
  persistOption,
  persistItem,
  persistScroll,
}

export {
  persistEvents as default,
  persistChange,
  persistCheck,
  persistOptions,
  persistOption,
  persistItem,
  persistScroll,
}

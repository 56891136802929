/* eslint-disable no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { actionWithHooks } from 'lib/sequence/module/action'

/**
 * Opens the current models `key` as a PDF in a new tab.
 *
 * @param {boolean} newTab                    whether to open the pdf in a new tab or return the url
 * @param {Gaia.AppModule.Spec} module        module composition
 * @param {Gaia.Component.Spec} component     action component
 * @param {Gaia.AppModule.EventHandler} event triggered event
 * @returns {Promise<*|string>}
 * @private
 */
const _pdf = async (newTab, module, component, event) => {
  const model = module[G.MODEL]
  const { version, api } = model[G.PROPS]
  const key = model[G.STATE][G.REF]

  const url = `/api/v${version}/${api}/${key}/pdf`

  return newTab
    ? await module[G.ADAPTER][G.ROUTER].newTab(url)
    : url
}

export const pdfAsAttachment = curry(async (module, component, event) => {
  const model = module[G.MODEL]
  const key = model[G.STATE][G.REF]
  const url = await _pdf(false, module, component, event)

  return { url, value: { name: key, type: 'application/pdf' } }
})

/**
 * Opens the current models `key` as a PDF in a new tab.
 *
 * @type {(function(...[*]): (*))|*}
 */
export const pdfInNewTab = curry(
  async (module, component, event) => await _pdf(true, module, component, event),
)

/**
 * Returns the url of the models `key` pdf.
 */
export default curry(
  async (module, component, event) => await _pdf(false, module, component, event),
)

/* eslint-disable implicit-arrow-linebreak,indent,no-unused-expressions */
/* global PLATFORM */
import { arrayOf, asyncPipeSpread } from 'lib/util'

/**
 * Platform Hook
 *
 * Executes hooks assigned to the property of `platforms` that has the same name as the current
 * platform.
 *
 * @example
 * const _indexHooks = {
 *   after: [
 *    ui,
 *    platform({
 *      web: [
 *        recreate,
 *      ],
 *    }),
 *  ]
 * }
 *
 * @param {Object} platforms  an object consisting of properties with platform names each with a
 *                            reference to the hook or an array with references to the hooks that
 *                            should be executed on that platform.
 * @returns {function(*): function(...[*]): Promise<*|*[]>}
 */
export default platforms => obj => async (...args) => {
  const hooks = platforms[PLATFORM]
  return hooks ? await asyncPipeSpread(...arrayOf(hooks).map(hook => hook(obj)))(...args) : args
}

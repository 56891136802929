/* eslint-disable no-unused-vars */
/* global G, PLATFORM */
import { asyncPipeSpread, setKey } from 'lib/util'
import find from 'lib/sequence/component/children/find'

/**
 * Sets the attachment to display.
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*, ...[*]): Promise<*[]>}
 */
const setAttachment = module => async (components, ...args) => {
  const model = module[G.MODEL]

  const { [G.HTTP]: httpAdapter } = model[G.ADAPTER]
  const { [G.REF]: documentationId } = model[G.STATE] || {}

  const { file } = components

  const result = await httpAdapter.post({ url: '/api/v1/search',
    params: {
      query: { type: 'attachment' },
      filter: { parent: documentationId },
    } })

  const [attachment] = result.value

  setKey({ ...attachment, documentationId }, 'attachment', file[G.STATE])
  // If the above didn't work, we are trying to display a video, which
  // doesn't have an attachment document because it's just an url, in this
  // case we'd have to manually GET the document and grab the information
  // we need from there
  if (!attachment) {
    const parentDocument = await httpAdapter.get({ url: `/api/v1/documentation/${documentationId}` })
    const { title: name, url } = parentDocument.value

    setKey({ value: { documentationId, name, url } }, 'attachment', file[G.STATE])
  }

  return args
}

/**
 * EquipmentInformation Document Action
 *
 * @param {Gaia.AppModule.Spec} module current module composition
 * @returns {function(*): function(...[*]): Promise<*[]>}
 */
export default module => () => async (...args) => asyncPipeSpread(
  setAttachment(module),
)(find(module[G.STATE][G.ACTION][G.COMPONENT]), ...args)

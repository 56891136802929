/**
 * Cart Status Collection.
 *
 * Contains static list of available cart statuses.
 */
export default [
  {
    key: 0,
    value: 'Draft',
    color: 'gray.400',
    backgroundColor: 'background.mobile',
  },
  {
    key: 50,
    value: 'Active',
    color: 'pending.500',
    backgroundColor: 'pending.50',
  },
  {
    key: 80,
    value: 'Done',
    color: 'success.700',
    backgroundColor: 'success.50',
  },
]

/* eslint-disable no-param-reassign */
/* global G */
import { withDescriptor, withObjectFreeze } from 'trait/with'
import { asyncpipe } from 'lib/util'
import { withGetterSetterFn } from 'trait/with/GetterSetter'
import { hasNamespace } from 'trait/has'
import { usesGetterSetter, usesNamespace } from 'trait/uses'
import api from 'platform/adapter/notification/api/group/index'

const descriptor = 'adapter:Notification:Group'

export const defaults = {
  create: G.CREATE,
}

/**
 * Attaches all api methods whose name can be found within {@link defaults} to the event handler by
 * assigning them an event type in the form:
 *
 * @example
 * const groupName = 'default'
 * eventBus.type(G.NOTIFICATION, G.CREATE, groupName) === 'gaia:attachment:create:default'
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {string} groupName          the name of the group
 *
 * @return {(function(*): void)|*}
 */
const withEventHandlers = (app, groupName) => (obj) => {
  const eventBus = app[G.EVENTS]

  Object.keys(defaults).reduce((acc, key) => {
    const eventType = eventBus.type(G.NOTIFICATION, acc[key], groupName)
    const eventHandler = obj[G.API][key]
    eventBus.add(eventType, eventHandler)
    return acc
  }, defaults)

  return obj
}

/**
 * Adds a {@code destroy()} function to {@code obj} that removes all event handlers attached to
 * their methods through {@link withEventHandlers} and also makes it remove itself from the
 * adapter's list of groups.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {string} groupName          the name of the group
 *
 * @return {(function(*): void)|*}
 */
const withDestructor = (app, groupName) => (obj) => {
  const eventBus = app[G.EVENTS]

  Object.defineProperty(obj, 'destroy', {
    value: () => {
      Object.keys(defaults).reduce((acc, key) => {
        const eventType = eventBus.type(G.NOTIFICATION, acc[key], groupName)
        eventBus.remove(eventType, obj[G.API][key])
        return acc
      }, defaults)
      const adapter = app[G.ADAPTER][G.NOTIFICATION]
      delete adapter[G.GROUP][groupName]
    },
    enumerable: true,
  })

  return obj
}

/**
 * Native Platform Web Adapter Notification Group
 *
 * Stores and manages data respective to the notification group name (and therefore type).
 * Each group defines their own set of handlers responsible for fetching and setting data.
 *
 * @example
 * Take a look at {@code ./group/create}
 *
 * Initializing a group object also attaches its API methods to the eventBus, as described in
 * {@link withEventHandlers}. For them to be removed, it is necessary to call {@code destroy()}, as
 * explained in {@link withDestructor}.
 *
 * @memberOf Gaia.Adapter.Notification
 * @typedef NotificationGroup
 * @namespace Group
 *
 * @property {String} _name     module descriptor. see {@link withDescriptor}
 * @property {File[]} G.DATA    accumulator of (local) data
 */

/**
 * Notification Group
 *
 * @param {Gaia.Web.Application} obj  the Web Platform Application
 * @param {String} name               the group identifier
 * @param {Object} options            initial options for the group
 * @return {Gaia.Adapter.Notification.Group}  a new notification group composition
 */
export default (obj, name, options = {}) => asyncpipe(
  withDescriptor(`${descriptor}:${name}`),
  withGetterSetterFn(G.DATA),
  usesGetterSetter(G.DATA, {}),
  withGetterSetterFn(G.STATE),
  usesGetterSetter(G.STATE, options),
  hasNamespace(G.API),
  async (group) => {
    const groupApi = await api(obj, name, group)
    usesNamespace(G.API, groupApi, group)
    return group
  },
  withEventHandlers(obj, name),
  withDestructor(obj, name),
  withObjectFreeze,
)({})

/* global G */

import { debounce, def } from 'lib/util'

/**
 * Changes a property inside the current cart. This can either be a position, or a root property,
 * like {@code recipientData}.
 *
 * Dispatches {@code G.CART, G.SET} event afterward.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => async ({ detail = {} }) => {
  const { key, name, data } = detail[G.DATA]

  const adapter = app[G.ADAPTER][G.CART]
  const activeCart = adapter[G.DATA]
  const eventBus = app[G.ADAPTER][G.EVENTS]

  const parsedData = Object.keys(data).reduce(
    (acc, x) => ({ ...acc, [x]: def(data[x]?.value) ? data[x]?.value : data[x] }), {},
  )

  if (!key && !name) {
    activeCart.value = {
      ...activeCart.value,
      ...parsedData?.value ? parsedData.value : parsedData,
    }
  }

  if (key || name) {
    activeCart.value.positions = activeCart.value.positions.map(position => (
      (key && key === position?.key) || name === position?.name
        ? { ...position, ...parsedData }
        : position))
  }

  eventBus.dispatch(eventBus.type(G.CART, G.SET), { [G.DATA]: activeCart })
}

export default app => debounce(fn(app), 300, false)

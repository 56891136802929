/* eslint-disable implicit-arrow-linebreak,no-param-reassign */
/* global G */
import { def, isObj } from 'lib/util'

/**
 * Sets `model`'s own and children data from every property inside `data` the model also has.
 *
 * @param {Gaia.Model.Spec} model         the model whose data to set
 * @param {Object} data                   an object defining new values for the model attributes
 * @param {Object} [options]              mapping options
 * @param {boolean} [options.updateCache] whether to also set `data` to the model's cache
 * @returns {*}
 */
const setData = (model, data, { updateCache } = {}) => {
  const keys = Object.keys(data).filter(key =>
    def(model[G.CHILDREN][key]) || console.warn(`No property '${key}' found in model ${model._name}`))

  keys.forEach((key) => {
    const attribute = model[G.CHILDREN][key]
    const value = data[key]
    if (value !== null && def(value) && isObj(value) && attribute[G.CHILDREN]) {
      setData(attribute, value)
    }
    model[G.DATA][key] = value
    if (updateCache) {
      attribute[G.CACHE] = value
    }
  })

  return model
}

const api = {
  setData,
}

export {
  api as default,
  setData,
}

/* global G */
import { curry } from 'lib/util'

/**
 * Persists incoming `scrollPosition` detail in corresponding component's state.
 *
 * Since there is no need to refresh the ui, it is handled on component level only.
 *
 * @type Gaia.AppModule.EventHandler
 */
const persistScroll = (module, component, event) => {
  const state = component[G.STATE]
  state.scrollPosition = event.detail.scrollPosition
}

export default curry(persistScroll)

/* eslint-disable no-throw-literal,no-unused-vars */
/* global G */
import { curry } from 'lib/util'
import { setData } from 'lib/sequence/model/api/set'
import { showBlockingDialog } from 'app/_shared/events/dialog'
import { requestOptionPassword } from 'app/optionManagement/action/generate'
import action from 'app/_shared/events/action'
import modelSubmit from 'app/optionManagement/hook/submit'

/**
 * Displays an error dialog intended to inform the user about why a password cannot be generated.
 *
 * @param {Gaia.AppModule.Spec} module  current module
 * @param {Object} info                 translation object containing `messageKey` and `message`
 * @return {Promise<*>}
 */
const showErrorDialog = async (module, info) => await showBlockingDialog(module, null, {
  title: {
    ns: 'optionManagement',
    key: 'label.passwordNotGenerated',
    defaultValue: 'Password could not be generated',
  },
  text: {
    ns: 'optionManagement',
    key: info.messageKey,
    defaultValue: info.message,
    md: true,
  },
  ok: {
    ns: 'common',
    key: 'button.ok',
    defaultValue: 'Ok',
  },
  cancel: null,
})

/**
 * Attempts to obtain the password of the current OptionManagement model.
 *
 * @type Gaia.AppModule.EventHandler
 */
const getPassword = async (module, component, event) => {
  const model = module[G.MODEL]

  try {
    const result = await requestOptionPassword(module)
    const { response, password } = result
    setData(model, { response, password }, { updateCache: true })
  } catch (e) {
    setData(model, { response: e }, { updateCache: true })
    console.error('Unable to generate OptionManagement password:', e.message)
    await showErrorDialog(module, e)
  } finally {
    try {
      await modelSubmit(module)()
    } catch (e) {
      console.error('Could not submit model:', e)
    }
    model[G.CACHE] = null
    await action(module, component, event)
  }
}

export default curry(getPassword)

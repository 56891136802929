/* global G */
import ui from 'lib/hook/ui/update'
import validate from 'lib/hook/model/validate'
import map from 'lib/sequence/model/api/map'
import clear from 'app/_shared/hook/model/clear'
import reset from 'app/_shared/hook/component/reset'
import confirm from 'lib/hook/router/confirm'
import check from 'app/_shared/hook/check'
import recall from 'app/_shared/hook/component/recall'
import platform from 'app/_shared/hook/platform'
import quickActions from 'app/_shared/hook/appbar/quickActions'
import clean from 'app/_shared/hook/action/clean'
import back from 'app/_shared/hook/redirect/back'
import redirect from 'lib/hook/router/redirect'
import read from 'app/optionManagement/hook/read'
import submit from 'app/optionManagement/hook/submit'

const _baseHooks = {
  after: [
    ui,
  ],
}

const _newHooks = {
  before: [
    reset,
    obj => async (...args) => {
      const component = obj[G.STATE][G.ACTION][G.COMPONENT]
      const model = obj[G.MODEL]
      map(model)(component)
      return args
    },
  ],
  after: [
    ui,
    confirm,
  ],
}

const _generateHooks = {
  after: [
    redirect,
  ],
}

const _editHooks = {
  before: [
    clear,
    reset,
    read,
  ],
  after: [
    ui,
    confirm,
  ],
}

const _detailHooks = {
  before: [
    read,
  ],
  after: [
    platform({
      mobile: quickActions,
    }),
    ui,
  ],
}

const _listHooks = {
  after: [
    platform({
      mobile: quickActions,
    }),
    recall,
    ui,
  ],
}

const _submitHooks = {
  before: [
    validate,
  ],
  after: [
    ui,
    check,
    submit,
    clean,
    obj => async (...args) => {
      obj[G.STATE][G.ROUTE]
        ? await redirect(obj)(...args)
        : await back(obj)(...args)
      return args
    },
  ],
}

const hooks = {
  [G.ACTIONS]: {
    index: _listHooks,
    generate: _generateHooks,
    new: _newHooks,
    edit: _editHooks,
    detail: _detailHooks,
    submit: _submitHooks,
    password: _baseHooks,
    error: _baseHooks,
  },
}

export default hooks

import { Box, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import AvatarImage from 'ui/Element/Image/Avatar'

const styles = theme => ({
  root: {
    display: 'flex',
  },
  image: {
    width: '2.5rem',
    height: '2.5rem',
    marginRight: '1rem',
  },
})

/**
 * Displays an icon for the part
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
export const ListItemCellCartPartIcon = (classes, props = {}) => {
  const theme = useTheme()
  const { image, partIcon, group } = props || {}

  const currentAttachment = image?.attachment?.value?.name
    ? { ...image.attachment, key: image?.value?.attachmentId || image?.id || undefined, api: { ref: 'equipment' } }
    : { value: { name: '' } }

  return (
    <Box className={classes.image}>
      <AvatarImage
        group={group}
        attachment={currentAttachment}
        fallback={{
          icon: partIcon,
          color: theme.palette.text.secondary,
          variant: 'outlined',
        }}
      />
    </Box>
  )
}

export default CellHOC(ListItemCellCartPartIcon, styles)

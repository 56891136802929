/* global G */

import { setKey } from 'lib/util'

/**
 * No History Action Hook
 *
 * Removes the history flag from the current action's ui state, so that the action isn't added to
 * the history stack when leaving it.
 *
 * @param {Gaia.AppModule.Spec} obj  the current module composition object
 * @return {function(...[*]): Promise<*[]>}
 */
export default obj => async (...args) => {
  try {
    const moduleState = obj[G.STATE][G.ACTION][G.STATE]
    setKey(null, G.UNDO, moduleState)
  } catch (e) {
    console.error(e)
  }
  return args
}

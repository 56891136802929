/* global React, G */
import { useCallback, useContext, useEffect, useLayoutEffect, useState, forwardRef } from 'react'
import ApplicationContext from '@platform/react/context/application'

/**
 * Generic HOC for listening to {@code G.NOTIFICATION} events and
 * managing a state for its child.
 *
 * @example
 * {@code G.NOTIFICATION, G.INIT, type} - Trigger initial fetching of data
 * {@code G.NOTIFICATION, G.DESTROY, type} - Cleanup before unmounting
 * {@code G.NOTIFICATION, G.DONE, type} - Get new notification data and fill state
 *
 * @param {React.ReactNode} Component Component to use for rendering
 * @param {boolean} [hidden]          whether the component should be rendered
 * @param {Object} props              additional props
 * @returns {function(*)}
 * @constructor
 */
const NotificationHOC = Component => ({ hidden, ...props }, ref) => {
  const { options: { notification }, child: { route } } = props
  const { eventBus, session } = useContext(ApplicationContext)
  const [state, setState] = useState({})

  // Get initial data when the component first renders
  useLayoutEffect(() => {
    !hidden
    && notification
    // We can set notification.hidden to inhibit the init event from firing
    // in case we need to fire it from somewhere else (like app init)
    && !notification?.hidden
    && session[G.META]
    && eventBus.dispatch(eventBus.type(G.NOTIFICATION, G.INIT), { [G.DATA]: {
      [G.GROUP]: notification.type,
      [G.API]: notification.api,
      [G.ROUTE]: route,
      [G.MENU]: notification?.menu,
    } })

    // Not needed, but maybe there is a use for cleanup?
    return () => {
      !hidden
      && notification
      && eventBus.dispatch(eventBus.type(G.NOTIFICATION, G.DESTROY, notification.type), { id: null })
    }
  }, [session[G.META], hidden])

  const _msgEventName = eventBus.type(G.NOTIFICATION, G.DONE, notification?.type)
  const _msgEventHandler = useCallback(({ detail }) => {
    const { [G.DATA]: data } = detail
    setState(prevState => ({ ...prevState, ...data }))
  }, [])

  useEffect(() => {
    notification && eventBus.add(_msgEventName, _msgEventHandler)

    return () => {
      eventBus.remove(_msgEventName, _msgEventHandler)
    }
  }, [notification])

  return hidden ? null : (
    <Component
      {...props}
      ref={ref}
      group={notification?.type}
      notification={state}
    />
  )
}

export default Component => forwardRef(NotificationHOC(Component))

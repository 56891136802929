/* global G */
/* eslint-disable no-shadow,no-unused-vars */
import { back, detail, persistChange, persistOptions, redirect } from 'app/_shared/events'
import { acl } from 'app/_shared/events/acl'
import action from 'app/_shared/events/action'
import byKey from 'app/_shared/events/action/byKey'
import { jump as jumpBack, list as listBreadcrumbs } from 'app/_shared/events/breadcrumbs'
import listCountries from 'app/_shared/events/collection/listCountries'
import listUserCountries from 'app/_shared/events/collection/listUserCountries'
import persistChangeAndAction from 'app/_shared/events/combined/persistChangeAndAction'
import persistOptionsAndAction from 'app/_shared/events/combined/persistOptionsAndAction'
import reset from 'app/_shared/events/model/reset'
import remember from 'app/_shared/events/remember'
import infinite from 'app/_shared/events/search/infinite'
import { infiniteMixed } from 'app/_shared/events/search/mixed'
import searchWith from 'app/_shared/events/search/with'
import soon from 'app/_shared/events/soon'
import { chain, platform } from 'app/_shared/events/util'
import session from 'app/_shared/session'
import detailOrCart from 'app/cart/event/detailOrCart'
import discard from 'app/cart/event/discard'
import getCartPositions from 'app/cart/event/getCartPositions'
import getUserSalesOrganisations from 'app/cart/event/getUserSalesOrganisations'
import getViewedCartPositions from 'app/cart/event/getViewedCartPositions'
import initTransferAndAction from 'app/cart/event/initTransferAndAction'
import listCartStatus from 'app/cart/event/listCartStatus'
import nextStep from 'app/cart/event/next'
import searchPartAndRecreate from 'app/cart/event/searchPartAndRecreate'
import sendToWebshop from 'app/cart/event/sendToWebshop'
import previousStep from 'app/cart/event/previous'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'
import refresh from 'lib/sequence/model/api/refresh'
import { curry, getFirstItem } from 'lib/util'
import previousOrBack from 'app/cart/event/previousOrBack'
import redirectToCommentAdd from 'app/cart/event/redirectToCommentAdd'
import redirectToCommentOptions from 'app/cart/event/redirectToCommentOptions'
import searchAndRecreate from 'app/_shared/events/appbar/searchAndRecreate'
import confirmation from 'app/_shared/events/confirmation'
import addComment from 'app/cart/event/position/comment/add'
import changeAmount from 'app/cart/event/position/amount'
import removeComment from 'app/cart/event/position/comment/remove'
import removePosition from 'app/cart/event/position/remove'
import addPosition from 'app/cart/event/position/add'
import removeAll from 'app/cart/event/position/removeAll'
import activate from 'app/cart/event/activate'
import pdf, { pdfAsAttachment } from 'app/_shared/events/file/pdf'
import updatePositions from 'app/cart/event/updatePositions'

export default {
  acl: {
    acl,
  },
  onOpen: {
    infinite: infinite(null),
    infiniteWithFilterAndSort: curry(async (module, component, event) => {
      const additionalParameters = await searchWith(['filter'], module, component, event)
      const result = await infinite(null, module, component, additionalParameters)

      // Moving active cart element to the top of the list
      result.data.length && result.data.unshift(
        getFirstItem(result.data.splice(
          result.data.findIndex(item => item.value.status === 50), 1,
        )),
      )

      return result
    }),
    infiniteWithFilter: chain(
      searchWith(['filter']),
      infinite(null),
    ),
    infiniteMixedWithFilter: chain(
      searchWith(['filter']),
      infiniteMixed,
    ),
    getCartPositions,
    getViewedCartPositions,
    getUserSalesOrganisations,
    listCountries,
    listCartStatus,
    listBreadcrumbs,
    reset: curry(async (module, component, event) => {
      const actionComponent = module[G.STATE][G.ACTION][G.COMPONENT]
      const { footer } = sequenceComponentFindProxy(actionComponent)
      const { list } = sequenceComponentFindProxy(footer)

      delete list[G.STATE]?.reload
      delete list[G.STATE]?.[G.META]
      await module[G.ADAPTER][G.UI].update(module)
    }),
    pdf,
    pdfAsAttachment,
  },
  onClose: {
    submit: byKey('submit'), // saves the current cart and performs 'back' in after hooks
    back,
    resetAndBack: chain(
      reset(null),
      back,
    ),
  },
  onChange: {
    persistChange,
    persistChangeAndAction,
    persistOptions,
    persistOptionsAndAction,
  },
  onSearch: {
    searchPartAndRecreate,
    searchAndRecreate,
  },
  onBack: {
    previousStepOrBack: previousOrBack,
  },
  onClick: {
    previous: previousStep,
    next: nextStep,
    redirect,
    detail,
    detailOrCart,
    soon,
    jumpBack,
    resetAndBack: platform({
      mobile: back,
      web: chain(
        reset(null),
        back,
      ),
    }),
    goToWebshop: platform({
      mobile: back,
      web: chain(
        reset(null),
        back,
      ),
    }),
    sendToWebshop,
    initTransferAndAction,
    checkout: platform({
      web: byKey('checkout'),
      mobile: chain(
        // On mobile, the cart details view is a modal, if we close it we'll to back to
        // the cart wizard, but we're still at step 2 (checkout), so before we check out
        // let's go to the previous step (positions) so that when we later x out of the
        // details view we see an empty positions page
        previousStep,
        byKey('checkout'),
      ),
    }),
    discardAndRedirect: chain(
      discard(null),
      redirect,
    ),
    setCartActiveAndRedirect: curry(async (module, component, event) => {
      await activate(
        async newCart => await refresh(module[G.MODEL], newCart),
        module,
        component,
        event,
      )
      await redirect(module, component, event)
    }),
    redirectToCommentAdd,
    removeAllPositionsAndAction: chain(
      removeAll(null),
      platform({ web: updatePositions('list') }),
      action,
    ),
    removeComment: removeComment(null),
    removeCommentAndBack: chain(
      removeComment(null),
      back,
    ),
    addComment: addComment(null),
    addCommentAndBack: chain(
      addComment(null),
      back,
    ),
  },
  onDelete: {
    discardAndRecreate: chain(
      discard(null),
      curry(async (module, component, event) => { await module[G.ADAPTER][G.UI].create(module) }),
    ),
  },
  onPartClick: {
    addPositionAndAction: chain(
      addPosition(null),
      platform({ web: updatePositions('list') }),
      confirmation('partAdded', 'success', true, 'cart'),
      action,
    ),
  },
  onPage: {
    remember,
  },
  onRemove: {
    removePosition: removePosition(null),
    removePositionAndAction: chain(
      removePosition(null),
      platform({ web: updatePositions('list') }),
      action,
    ),
    removeComment: removeComment(null),
    removeCommentAndAction: chain(
      removeComment(null),
      action,
    ),
  },
  onCommentAdd: {
    addCommentAndAction: chain(
      addComment(null),
      action,
    ),
    redirectToCommentAdd,
  },
  onCommentOptions: {
    redirectToCommentOptions,
  },
  onAmount: {
    changeAmountAndAction: chain(
      changeAmount(null),
      action,
    ),
  },
  getSelection: {
    listUserCountries,
    getUserSalesOrganisations,
  },
  getStatus: {
    listCartStatus,
  },
  filter: {
    iAmSubmitter: curry((module, component, event) => ({
      submitter: session(module).user.key({ wrap: true })[0],
    })),
    isArticle: curry((module, component, event) => ({
      type: 'article',
    })),
  },
}
